import React, { useState, memo } from 'react';
import clsx from 'clsx';
import {
  FormControl, FormHelperText, InputAdornment, TextField, TextFieldProps,
} from '@material-ui/core';
import { CalendarToday as CalendarTodayIcon } from '@material-ui/icons';
import {
  DateRangePicker, DateRangeDelimiter, DateRange,
} from '@material-ui/pickers';
import { FormikValues, useField, useFormikContext } from 'formik';
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns';
import usLocale from 'date-fns/locale/en-US';

import styles from './BaseDateRangePicker.module.css';

interface BaseDateRangePickerProps {
  /**
   * Unique id.
   */
  id: string;
  /**
   * Text label
   */
  label: string;
  /**
   * Name attribute of the input element.
   */
  name: string;
  /**
   * Date format
   * @example: 12/21/2021.
   */
  format?: string;
  /**
   * Full width of its container.
   */
  fullWidth?: boolean;
  /**
   * Callback fired when the value is changed.
   */
  onChange?: (date: DateRange<Date>) => void;
}

const dateAdapter = new DateFnsAdapter({ locale: usLocale });

const BaseDateRangePickerComponent = ({
  label, name, format, fullWidth, ...props
}: BaseDateRangePickerProps): JSX.Element => {
  const { setFieldValue, values } = useFormikContext<FormikValues>();
  const [field, meta] = useField(name);
  const [value, setValue] = useState(values[name] || null);

  const handleChange = (date: DateRange<Date>) => {
    setValue(date);

    if (date) {
      setFieldValue(name, [date[0], date[1]]);
    }

    if (props.onChange) {
      props.onChange(date);
    }
  };

  const configDatepicker = {
    ...field,
    ...props,
    value,
    inputFormat: format,
    onChange: handleChange,
  };

  const configFormControl = {
    error: false,
    fullWidth,
  };

  let helperText: string | null = null;

  if (meta && meta.touched && meta.error) {
    configFormControl.error = true;
    helperText = meta.error;
  }

  const inputProps: TextFieldProps = {
    helperText: '',
    size: 'small',
    variant: 'standard',
    className: clsx(styles.input, { [styles.fullWidth]: fullWidth }),
    label,
    error: configFormControl.error,
  };

  return (
    <FormControl {...configFormControl} className={styles.host}>
      <DateRangePicker
        {...configDatepicker}
        // Cast to any because of no suitable type.
        dateAdapter={dateAdapter as any}
        className={clsx(styles.input, { [styles.fullWidth]: fullWidth })}
        disableMaskedInput
        renderInput={(startProps, endProps) => (
          <>
            <TextField
              {...startProps}
              {...inputProps}
            />
            <DateRangeDelimiter className={styles.delimiter}> to </DateRangeDelimiter>
            <TextField
              {...endProps}
              {...inputProps}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start"><CalendarTodayIcon className="inputIcon" /></InputAdornment>
                ),
              }}
            />
          </>
        )}
      />
      <FormHelperText error>{helperText}</FormHelperText>
    </FormControl>
  );
};

BaseDateRangePickerComponent.defaultProps = {
  format: 'MM/dd/yyyy',
  fullWidth: false,
  onChange: () => {},
};

export const BaseDateRangePicker = memo(BaseDateRangePickerComponent);
