import { DateRange } from '@material-ui/pickers';

export interface DonationsFilter {
  /**
   * Min amount.
   */
  readonly min: number | null;
  /**
   * Max amount.
   */
  readonly max: number | null;
  /**
   * Array of dates.
   */
  readonly date: DateRange<Date> | null;
  /**
   * Charity(Giving Category) name.
   */
  readonly charityId: number | string | null;
  /**
   * Type of donation.
   */
  readonly donationType: number | string | null;
}

export const initialFormState: DonationsFilter = {
  min: null,
  max: null,
  date: [null, null],
  charityId: 'null',
  donationType: 'null',
};
