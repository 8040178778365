import Register from 'models/Register';
import RegisterDto from 'api/dtos/registerDto';
import { mapOrganizationToDto } from 'api/mappers/organizationMapper';

/**
 * Map Register model to DTO.
 */
export const mapRegisterToDto = (payload: Register): RegisterDto => ({
  first_name: payload.firstName,
  last_name: payload.lastName,
  email: payload.email,
  password: payload.password,
  phone: payload.phone,
  organization: mapOrganizationToDto(payload.organization),
});
