import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getTransactions } from 'store/Charity/dispatchers';
import Filter from 'models/Filter';

import { state } from './state';

const charitySlice = createSlice({
  name: 'charity',
  initialState: state,
  reducers: {
    setFilter(state, { payload }: PayloadAction<Filter<any> | null>) {
      state.filter = payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getTransactions.pending, (state, { meta }) => {
      state.loading = true;
      state.error = null;
      state.currentRequestId = meta.requestId;
    });
    builder.addCase(getTransactions.fulfilled, (state, { meta, payload }) => {
      if (state.loading && state.currentRequestId === meta.requestId) {
        state.data = payload;
        state.loading = false;
        state.currentRequestId = null;
      }
    });
    builder.addCase(getTransactions.rejected, (state, { error, meta }) => {
      if (state.loading && state.currentRequestId === meta.requestId) {
        state.error = error.message ?? '';
        state.loading = false;
        state.currentRequestId = null;
      }
    });
  },
});

export const {
  setFilter,
} = charitySlice.actions;

export const charity = charitySlice.reducer;
