import { getSessionToken } from 'api/services/Session';
import ApiError from 'models/ApiError';

interface AuthState {
  loading: boolean;
  token: string | null;
  error: ApiError | null;
}

export const state: AuthState = {
  loading: false,
  token: getSessionToken(),
  error: null,
};
