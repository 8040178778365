import React, { memo, useEffect, useState } from 'react';
import { GridSortModel } from '@mui/x-data-grid';

import { BaseDataTable, KiosksToolbar } from 'components';
import { useAppDispatch, useAppSelector } from 'store/rootReducer';
import QueryParams from 'models/QueryParams';
import { getKiosks } from 'store/Kiosk';
import { getTableLabel } from 'utils/getTableLabel';
import { SortOrder } from 'models/enums';
import { isChangedOrder } from 'utils/isChangedOrder';

import { columns } from './tableSettings';

interface KiosksTableProps {
  /**
   * Merchant Id.
   */
  readonly merchantId?: number | null;
}

const KiosksTableComponent = ({ merchantId }: KiosksTableProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const { data, loading } = useAppSelector((state) => state.kiosk.kiosks);

  const [queryParams, setQueryParams] = useState<QueryParams | Record<string, unknown>>({
    search: '',
    perPage: 10,
    orderBy: 'serialNumber',
    sortOrder: SortOrder.Asc,
    page: 1,
    merchantId,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'serialNumber', sort: SortOrder.Asc },
  ]);

  useEffect(() => {
    dispatch(getKiosks({ params: queryParams }));
  }, [dispatch, queryParams]);

  const handleSortModelChange = (param: GridSortModel) => {
    setSortModel(param);

    if (!isChangedOrder(param, queryParams)) {
      return;
    }

    const { sort, field } = param[0];

    setQueryParams({
      ...queryParams,
      orderBy: field,
      sortOrder: sort as SortOrder,
    });
  };

  const label = getTableLabel(merchantId ? 'Kiosk Requests' : 'Kiosks', loading, data?.results.length);

  return (
    <BaseDataTable
      rows={data?.results || []}
      loading={loading}
      columns={columns}
      onSortModelChange={handleSortModelChange}
      components={{
        Toolbar: KiosksToolbar,
      }}
      componentsProps={{
        toolbar: {
          label,
          merchantId,
        },
      }}
      sortModel={sortModel}
      hideFooterPagination
      autoHeight
    />
  );
};

KiosksTableComponent.defaultProps = {
  merchantId: null,
};

export const KiosksTable = memo(KiosksTableComponent);
