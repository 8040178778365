import React from 'react';
import { create } from 'jss';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';

import RootRouter from 'routes/RootRouter';

const jss = create({
  ...jssPreset(),
  insertionPoint: document.querySelector('#jss-insertion-point') as HTMLElement,
});

const App = (): JSX.Element => (
  <StylesProvider jss={jss}>
    <div className="app-wrapper">
      <RootRouter />
    </div>
  </StylesProvider>
);

export default App;
