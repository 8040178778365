import React, { memo, MouseEvent } from 'react';
import { Button, ButtonProps, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';

import styles from './BaseButton.module.css';

interface BaseButtonProps extends ButtonProps {
  /**
   * Custom red button.
   */
  danger?: boolean;

  /**
   * State of submitting data.
   */
  loading?: boolean;

  /**
   * Button type.
   */
  type?: 'submit' | 'reset' | 'button';
}

const BaseButtonComponent = ({
  children, className, danger, loading, type, disabled, onClick, ...props
}: BaseButtonProps): JSX.Element => {
  const handleSubmit = (event: MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(event);
    }
  };

  const configButton = {
    ...props,
    type,
    disableElevation: true,
    disabled: loading || disabled,
    onClick: handleSubmit,
  };

  return (
    <Button
      {...configButton}
      className={clsx(styles.host, className, { [styles.danger]: danger })}
    >
      {children}
      { loading && <CircularProgress size={24} className={styles.progressbar} /> }
    </Button>
  );
};

BaseButtonComponent.defaultProps = {
  danger: false,
  loading: false,
  type: 'button',
};

export const BaseButton = memo(BaseButtonComponent);
