import React, { memo, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import {
  Drawer, Typography, Card, CardMedia, CardContent,
} from '@material-ui/core';

import smallLogo from 'assets/images/small-logo.svg';
import styles from './AppSidebar.module.css';

interface AppSidebarProps {
  /**
   * Children.
   */
  children: ReactNode;
  /**
   * Organization name or admin name.
   */
  name: string | undefined;
}

const SidebarComponent = ({ children, name }: AppSidebarProps): JSX.Element => (
  <Drawer
    variant="permanent"
    className={styles.host}
  >
    <Card variant="elevation" className={styles.card} square>
      <CardContent>
        <Link to="/">
          <CardMedia
            className={styles.logo}
            image={smallLogo}
            component="img"
            height="24"
            title="Generosity"
          />
        </Link>
        <Typography variant="body2" className="bold">Welcome</Typography>
        <Typography variant="body2" component="p">{name}</Typography>
      </CardContent>
    </Card>
    {children}
  </Drawer>
);

export const AppSidebar = memo(SidebarComponent);
