import { KeyboardEvent } from 'react';

/**
 * Prevent to input, not a numeric value.
 */
export const preventNonNumericValues = (event: KeyboardEvent<HTMLInputElement>) => {
  // Allow keyboard shortcuts
  if (
    event.getModifierState('Meta')
      || event.getModifierState('Control')
      || event.getModifierState('Alt')
  ) {
    return;
  }

  // Allow non-printable keys
  if (event.key.length !== 1 || event.key === '\x00') {
    return;
  }

  // Prevent any non-numeric keys, but allow . for decimals
  if ((event.key < '0' || event.key > '9') && event.key !== '.') {
    event.preventDefault();
  }
};
