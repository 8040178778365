import Payment from 'models/Payment';

interface SettingsState {
  payments: {
    loading: boolean;
    processing: boolean;
    data: Payment | null;
    error: string | null;
  };
}

export const state: SettingsState = {
  payments: {
    loading: false,
    processing: false,
    data: null,
    error: null,
  },
};
