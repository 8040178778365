import { CONFIG } from 'config';
import Payment from 'models/Payment';
import PaymentToken, { PaymentTokenResponse } from 'models/PaymentToken';
import PaymentDto from 'api/dtos/paymentDto';
import { mapDtoToPayment, mapPaymentToDto } from 'api/mappers/paymentMapper';
import { mapDtoToPaymentToken, mapPaymentTokenToDto } from 'api/mappers/paymentTokenMapper';

import { http } from '../index';

/**
 * Fetch payment settings.
 */
export const fetchPaymentSettings = async (): Promise<Payment> => {
  const { data } = await http.get<PaymentDto>('/settings/payments');

  return mapDtoToPayment(data);
};

/**
 * Fetch payment token.
 */
export const fetchPaymentToken = async (payload: PaymentToken): Promise<PaymentTokenResponse> => {
  const body = mapPaymentTokenToDto(payload);
  const url = CONFIG.cardPointUrl || '';

  const response = await fetch(url, {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  return mapDtoToPaymentToken(await response.json());
};

/**
 * Update payment settings.
 */
export const updatePaymentSettings = async (payload: Payment): Promise<Payment> => {
  const dto = mapPaymentToDto(payload);
  const { data } = await http.post<PaymentDto>('/settings/payments', dto);

  return mapDtoToPayment(data);
};
