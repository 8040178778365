import download from 'downloadjs';

import QueryParams from 'models/QueryParams';
import { mapQueryParamsToDto } from 'api/mappers/queryParamsMapper';
import { PlanningCenter } from 'models/Organization';
import { PlanningCenterDto } from 'api/dtos/organizationDto';
import { mapPlanningCenterToDto } from 'api/mappers/organizationMapper';
import { http } from '../index';

/**
 * Export to file.
 */
export const exportToFile = async (queryParams: QueryParams, endpointPath: string): Promise<null> => {
  const dtoParams = mapQueryParamsToDto(queryParams);
  const { data, headers } = await http.get(endpointPath, {
    params: { ...dtoParams, download: true },
    responseType: 'blob',
  });

  download(data, headers['x-suggested-filename'], data.type);

  return null;
};

/**
 * Check planning center status.
 */
export const testPlanningCenterConnection = async (params: PlanningCenter): Promise<unknown> => {
  const dtoParams = mapPlanningCenterToDto(params);
  const { data } = await http.get<PlanningCenterDto>('/planning-center/test', { params: dtoParams });

  return data;
};
