import { AxiosResponse } from 'axios';

import UserDto from 'api/dtos/userDto';
import { mapDtoToUser, mapUserToDto } from 'api/mappers/userMapper';
import { mapRegisterToDto } from 'api/mappers/registerMapper';
import User from 'models/User';
import Register from 'models/Register';
import { http } from '../index';

/**
 * Fetch user.
 */
export const fetchUser = async (): Promise<User> => {
  const { data } = await http.get<UserDto>('/users/me');

  return mapDtoToUser(data);
};

/**
 * Update user's info.
 */
export const updateUser = async (payload: User): Promise<User> => {
  const dto = mapUserToDto(payload);
  const { data } = await http.put<UserDto>('/users/me', dto);

  return mapDtoToUser(data);
};

/**
 * Reset password.
 */
export const createUser = (payload: Register): Promise<AxiosResponse> => {
  const dto = mapRegisterToDto(payload);

  return http.post<AxiosResponse>('/users', dto);
};
