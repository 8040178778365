import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  fetchKiosks, unregister, create, update,
} from 'api/services/Kiosk';
import QueryParams from 'models/QueryParams';
import Kiosk from 'models/Kiosk';

/**
 * Get paginated list of organization kiosks.
 */
export const getKiosks = createAsyncThunk(
  'kiosk/getKiosks',
  ({ params = {} }: { params?: QueryParams }) => fetchKiosks(params),
);

/**
 * Unregister a kiosk.
 */
export const unregisterKiosk = createAsyncThunk(
  'kiosk/unregisterKiosk',
  (kioskId: number) => unregister(kioskId),
);

/**
 * Create new kiosks.
 */
export const createKiosks = createAsyncThunk(
  'kiosk/createKiosks',
  async (count: number, { rejectWithValue }) => {
    try {
      return await create(count);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

/**
 * Update kiosk.
 */
export const updateKiosk = createAsyncThunk(
  'kiosk/updateKiosk',
  async ({ id, data }: { id: number, data: Kiosk }, { rejectWithValue }) => {
    try {
      return await update(id, data);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
