import { PaymentMethod } from 'models/enums';
import { getLast4 } from 'utils/getLast4';

/**
 *  Payment model class.
 */
export default class Payment {
  /**
   * Unique Id.
   */
  public readonly id?: number;

  /**
   * Payment token.
   */
  public readonly token?: string;

  /**
   * Card holder name.
   */
  public readonly cardName?: string;

  /**
   * Zip code.
   */
  public readonly zip?: string;

  /**
   * Payment method.
   */
  public readonly paymentMethod: PaymentMethod;

  /**
   * Last four numbers of card.
   */
  public readonly last4: string;

  constructor(data: PaymentConstructorArgs) {
    this.id = data.id;
    this.token = data.token;
    this.cardName = data.cardName;
    this.zip = data.zip;
    this.paymentMethod = data.paymentMethod;
    this.last4 = data.last4;
  }

  /**
   * Get payment details.
   */
  public get paymentDetails(): string {
    const last4 = this.last4 ? getLast4(this.last4 || '') : 'N/A';

    if (this.paymentMethod === PaymentMethod.Card) {
      return `Credit card ends in ${last4}.`;
    }

    return `Bank account ends in ${last4}`;
  }
}

interface PaymentConstructorArgs {
  id?: number;
  token?: string;
  cardName?: string;
  zip?: string;
  paymentMethod: PaymentMethod;
  last4: string;
}
