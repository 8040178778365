import React, { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import withSuspense from 'hoc/withSuspense';

const DonatorsPage = withSuspense(lazy(() => import('./pages/DonatorsPage/DonatorsPage')));
const DonatorsDetailsPage = withSuspense(lazy(() => import('./pages/DonatorsDetailsPage/DonatorsDetailsPage')));

export const donatorsRoutes: RouteObject[] = [
  {
    path: 'donators',
    element: <DonatorsPage />,
  },
  {
    path: 'donators',
    children: [
      {
        path: ':id',
        element: <DonatorsDetailsPage />,
      },
    ],
  },
];
