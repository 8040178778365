import DonatorTotalAmountTransaction from 'models/DonatorTotalAmountTransaction';
import DonatorTotalAmountTransactionDto from 'api/dtos/donatorTotalAmountTransactionDto';

/**
 * Map DTO to Organization model.
 */
export const mapDtoToDonatorTotalAmountTransaction = (
  dto: DonatorTotalAmountTransactionDto,
): DonatorTotalAmountTransaction => new DonatorTotalAmountTransaction({
  totalAmount: dto.total_amount,
});
