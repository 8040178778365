import {
  format, parseISO, isValid, set,
} from 'date-fns';

export const DATE_PICKER_FORMAT = 'MMM d, yyyy';
export const SQL_DATE_FORMAT = 'yyyy-MM-dd';
export const SQL_DATE_TIME_FORMAT = 'yyyy-MM-dd hh:mma';

/**
 * Convert date. If the date is invalid will return an empty string.
 * @example Default format: July 12, 2021
 */
export const getFormattedDate = (
  date: Date | string | null | undefined,
  dateFormat: string = DATE_PICKER_FORMAT,
): string => {
  if (typeof date === 'string') {
    return format(parseISO(date), dateFormat);
  }

  if (!isValid(date) || !date) {
    return '';
  }

  return format(date, dateFormat);
};

/**
 * Convert date and add time depends on postfix param.
 * @return 2022-12-13 21:30:00
 */
export const getDateWithTimePostfix = (
  date: Date | string | null | undefined,
  postfix: 'midnight' | 'before',
): string => {
  if (typeof date === 'string') {
    return format(parseISO(date), SQL_DATE_TIME_FORMAT);
  }

  if (!isValid(date) || !date) {
    return '';
  }

  const time = postfix === 'midnight'
    ? { hours: 0, minutes: 0, seconds: 0 }
    : { hours: 23, minutes: 59, seconds: 59 };
  const dateTime = set(date, time);

  return format(dateTime, SQL_DATE_TIME_FORMAT);
};

/**
 * Get default end date for merchant's dashboard.
 * @example Today is 12/09/2022, in this case end_date will be 12/08/2022 09:30PM
 */
export const getPreviousEndDate = (): string => {
  const today = new Date();
  const yesterday = today.setDate(today.getDate() - 1);
  const yesterday2130 = set(yesterday, { hours: 21, minutes: 30, seconds: 0 });

  return format(yesterday2130, SQL_DATE_TIME_FORMAT);
};
