import BrandingColor from 'models/BrandingColor';
import BrandingColorDto from 'api/dtos/brandingColorDto';
import PagedList from 'models/PagedList';
import ResponseDto from 'api/dtos/responseDto';
import { mapDtoToPagination } from 'api/mappers/paginationMapper';

/**
 * Map DTO to BrandingColor model.
 */
export const mapDtoToBrandingColor = (dto: BrandingColorDto): BrandingColor => new BrandingColor({
  id: dto.id,
  name: dto.name,
  color: dto.color,
});

/**
 * Map DTO to BrandingColors paged list.
 */
export function mapDtoToBrandingColors(dtos: ResponseDto<BrandingColorDto>): PagedList<BrandingColor> {
  return new PagedList<BrandingColor>({
    pagination: mapDtoToPagination(dtos.pagination),
    results: dtos.results?.map(mapDtoToBrandingColor),
  });
}
