import React, { memo } from 'react';
import { GridOverlay } from '@mui/x-data-grid';

import styles from './BaseDataTable.module.css';

const BaseDataTableNoDataComponent = (): JSX.Element => (
  <GridOverlay><span className={styles.noDataText}>- No data to display yet - </span></GridOverlay>
);

export const BaseDataTableNoData = memo(BaseDataTableNoDataComponent);
