import AllTransaction from 'models/AllTransaction';
import AllTransactionDto from 'api/dtos/allTransactionDto';
import PagedList from 'models/PagedList';
import ResponseDto from 'api/dtos/responseDto';
import { mapDtoToPagination } from 'api/mappers/paginationMapper';

/**
 * Map DTO to All Transaction model.
 */
export const mapDtoToAllTransaction = (dto: AllTransactionDto): AllTransaction => new AllTransaction({
  id: dto.id,
  amount: dto.amount,
  fee: dto.fee,
  firstName: dto.first_name,
  lastName: dto.last_name,
  donator: dto.donator,
  receipt: dto.receipt,
  paymentMethod: dto.payment_method,
  donationType: dto.donation_type,
  createdAt: new Date(dto.created_at),
  last4: dto.last_4,
  name: dto.name,
  merchantId: dto.merchant_id,
  organizationName: dto.organizations_name,
  reoccurringPaymentId: dto.reoccurring_payment_id,
  transactionId: dto.transaction_id,
});

/**
 * Map DTO to All Transactions paged list.
 */
export function mapDtoToAllTransactions(dtos: ResponseDto<AllTransactionDto>): PagedList<AllTransaction> {
  return new PagedList<AllTransaction>({
    pagination: mapDtoToPagination(dtos.pagination),
    results: dtos.results?.map(mapDtoToAllTransaction),
  });
}
