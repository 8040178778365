import React from 'react';
import { GridRenderCellParams, GridColumns } from '@mui/x-data-grid';

import { getFormattedCurrency } from 'utils/currency';

const columns: GridColumns = [
  {
    field: 'name',
    headerName: 'Category',
    flex: 1,
    renderCell: ({ value }: GridRenderCellParams) => <strong>{value}</strong>,
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 130,
  },
  {
    field: 'donations',
    headerName: '# of Donations',
    minWidth: 160,
  },
  {
    field: 'amount',
    headerName: 'Total Donation',
    flex: 1,
    minWidth: 140,
    align: 'right',
    headerAlign: 'right',
    renderCell: ({ value }: GridRenderCellParams) => {
      if (value) {
        return (
          <strong>{getFormattedCurrency(Number(value))}</strong>
        );
      }

      return value;
    },
  },
];

export { columns };
