import PagedList from 'models/PagedList';
import Donator from 'models/Donator';
import Filter from 'models/Filter';
import DonatorTransaction from 'models/DonatorTransaction';

interface DonatorState {
  loading: boolean;
  currentRequestId: string | null;
  data: PagedList<Donator> | null;
  details: Donator | null;
  filter: Filter<Record<string, unknown>> | null;
  error: string | null;
  transactions: {
    loading: boolean;
    data: PagedList<DonatorTransaction> | null;
    filter: Filter<Record<string, unknown>> | null;
    error: string | null;
    currentRequestId: string | null;
  };
  totalAmount: {
    loading: boolean;
    data: number | null;
    error: string | null;
  };
}

export const state: DonatorState = {
  loading: false,
  currentRequestId: null,
  data: null,
  details: null,
  filter: null,
  error: null,
  transactions: {
    loading: false,
    data: null,
    filter: null,
    error: null,
    currentRequestId: null,
  },
  totalAmount: {
    loading: false,
    data: null,
    error: null,
  },
};
