import { PaymentMethod, PaymentStatus } from 'models/enums';
import DonationType from 'models/DonationType';
import Category from 'models/Category';

/**
 *  Organization transaction model class.
 */
export default class OrganizationTransaction {
  /**
   * Unique Id.
   */
  public readonly id: number;

  /**
   * Amount.
   */
  public readonly amount: number;

  /**
   * Fee.
   */
  public readonly fee: number;

  /**
   * First name.
   */
  public readonly firstName: string;

  /**
   * Last name.
   */
  public readonly lastName: string;

  /**
   * Charity(Giving Category) name.
   */
  public readonly name: string;

  /**
   * Donator name.
   */
  public readonly donator: string;

  /**
   * Receipt.
   */
  public readonly receipt: string;

  /**
   * Payment method.
   */
  public readonly paymentMethod: PaymentMethod;

  /**
   * Donation type.
   */
  public readonly donationType: DonationType;

  /**
   * Date of create.
   */
  public readonly createdAt: Date;

  /**
   * Name on card.
   */
  public readonly nameOnCard: string;

  /**
   * ACH name.
   */
  public readonly achName: string;

  /**
   * Last 4 numbers of a card number(account).
   */
  public readonly last4: string;

  /**
   * Transaction id.
   */
  public readonly transactionId: string;

  /**
   * Recurring payment id.
   */
  public readonly reoccurringPaymentId: number | null;

  /**
   * Status.
   */
  public readonly status: PaymentStatus;

  /**
   * Category.
   */
  public readonly category: Category;

  /**
   * Donate as.
   */
  public readonly donateAs: string;

  /**
   * User id.
   */
  public readonly userId: number | null;

  /**
   * Comment.
   */
  public readonly comment: string | null;

  /**
   * Comment title.
   */
  public readonly commentTitle: string | null;

  constructor(data: OrganizationTransactionConstructorArgs) {
    this.id = data.id;
    this.amount = data.amount;
    this.fee = data.fee;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.name = data.name;
    this.donator = data.donator;
    this.receipt = data.receipt;
    this.paymentMethod = data.paymentMethod;
    this.donationType = data.donationType;
    this.createdAt = data.createdAt;
    this.nameOnCard = data.nameOnCard;
    this.achName = data.achName;
    this.last4 = data.last4;
    this.transactionId = data.transactionId;
    this.reoccurringPaymentId = data.reoccurringPaymentId;
    this.status = data.status;
    this.category = data.category;
    this.donateAs = data.donateAs;
    this.userId = data.userId;
    this.comment = data.comment;
    this.commentTitle = data.commentTitle;
  }

  /**
   * User full name.
   */
  public get fullName(): string {
    return `${this.firstName || ''} ${this.lastName || ''}`.trim();
  }
}

interface OrganizationTransactionConstructorArgs {
  id: number;
  amount: number;
  fee: number;
  firstName: string;
  lastName: string;
  name: string;
  donator: string;
  receipt: string;
  paymentMethod: PaymentMethod;
  donationType: DonationType;
  createdAt: Date;
  nameOnCard: string;
  achName: string;
  last4: string;
  transactionId: string;
  reoccurringPaymentId: number | null;
  status: PaymentStatus;
  category: Category;
  donateAs: string;
  userId: number | null;
  comment: string | null;
  commentTitle: string | null;
}
