import React, { ChangeEvent, memo, useState } from 'react';
import {
  Toolbar, TextField, InputAdornment, Typography,
} from '@material-ui/core';
import { GridRowsProp } from '@mui/x-data-grid';
import { FilterList as FilterListIcon, Search as SearchIcon } from '@material-ui/icons';

import {
  BaseButton, BaseButtonLink, ExportButton, DonationsFilterDialog,
} from 'components';
import { FilterForm } from 'models/FilterForm';
import { debouncedSearch } from 'utils/debouncedSearch';
import QueryParams from 'models/QueryParams';

import styles from './DonationsToolbar.module.css';

interface DonationsToolbarProps {
  /**
   * On search callback.
   */
  onSearch: (text: string) => void;

  /**
   * On filter callback.
   */
  onFilter: (values: FilterForm) => void;

  /**
   * Label.
   */
  label: string;

  /**
   * Endpoint path.
   */
  endpointPath: string;

  /**
   * Query params.
   */
  queryParams: QueryParams;

  /**
   * Filter params.
   */
  filterParams: Record<string, unknown>;

  /**
   * Show button 'View Donations'.
   */
  showViewDonations?: boolean;

  /**
   * Data rows.
   */
  rows: GridRowsProp;
}

const DonationsToolbarComponent = ({
  label, endpointPath, showViewDonations, rows,
  queryParams, filterParams, onSearch, onFilter,
}: DonationsToolbarProps): JSX.Element => {
  const [isOpen, setOpen] = useState(false);

  const updateFilterModalState = (isOpen: boolean) => () => {
    setOpen(isOpen);
  };

  return (
    <>
      <Toolbar className={styles.host}>
        <div className={styles.leftSide}>
          <Typography variant="h6" className={styles.label}>{label}</Typography>
          { showViewDonations && (
          <BaseButtonLink
            variant="outlined"
            className={styles.button}
            to="/donations"
          >
            View Donations
          </BaseButtonLink>
          ) }
        </div>
        <div className={styles.buttons}>
          <TextField
            placeholder="Search"
            margin="normal"
            className={styles.search}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"><SearchIcon className="inputIcon" /></InputAdornment>
              ),
            }}
            type="search"
            onInput={(event: ChangeEvent<HTMLInputElement>) => debouncedSearch(event, onSearch)}
          />
          <BaseButton
            variant="outlined"
            className={styles.button}
            startIcon={<FilterListIcon />}
            onClick={updateFilterModalState(true)}
          >
            Filter
          </BaseButton>
          <ExportButton
            className={styles.button}
            endpointPath={endpointPath}
            queryParams={queryParams}
            disabled={rows.length === 0}
          />
        </div>
      </Toolbar>
      <DonationsFilterDialog
        open={isOpen}
        onClose={updateFilterModalState(false)}
        onFilter={onFilter}
        queryParams={filterParams}
      />
    </>
  );
};

DonationsToolbarComponent.defaultProps = {
  showViewDonations: false,
};

export const DonationsToolbar = memo(DonationsToolbarComponent);
