import S3File from 'models/S3File';

interface UploadState {
  processing: boolean;
  data: S3File | null;
  error: string | null;
}

export const state: UploadState = {
  processing: false,
  data: null,
  error: null,
};
