import { createAsyncThunk, Dispatch } from '@reduxjs/toolkit';

import {
  fetchCharities,
  fetchAllCharities,
  createCharity as addCharity,
  updateCharity as editCharity,
  deleteCharity as removeCharity,
  restoreCharity as unarchiveCharity,
  fetchOrganizationImages,
  deleteOrganizationImages as deleteBanners,
  fetchTransactions,
  fetchReoccurringTransactions,
  fetchTransaction,
  fetchCharityStats,
  voidTransaction as voidTransactionById,
  refundTransaction as refundTransactionById,
  deleteTransaction as deleteTransactionById,
  addCashTransaction as createCashTransaction,
  addCheckTransaction as createCheckTransaction,
  fetchQR,
} from 'api/services/Organization';
import QueryParams from 'models/QueryParams';
import ManualTransaction from 'models/ManualTransaction';
import Charity from 'models/Charity';

import { AppThunk } from '../index';
import {
  setArchivedCharityStart,
  setArchivedCharitySuccess,
  setArchivedCharityFailed,
  setRestoredCharityStart,
  setRestoredCharitySuccess,
  setRestoredCharityFailed,
} from './organizationSlice';

/**
 * Get charities list.
 */
export const getCharities = createAsyncThunk(
  'organization/getCharities',
  (
    { showArchived, params = {} } : { showArchived: boolean, params?: QueryParams },
  ) => fetchCharities(showArchived, params),
);

/**
 * Get all charities list.
 */
export const getAllCharities = createAsyncThunk(
  'organization/getAllCharities',
  (
    { showArchived, params = {} } : { showArchived: boolean, params?: QueryParams },
  ) => fetchAllCharities(showArchived, params),
);

/**
 * Create a new charity.
 */
export const createCharity = createAsyncThunk(
  'organization/createCharity',
  async (data : Partial<Charity>, { rejectWithValue }) => {
    try {
      return await addCharity(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

/**
 * Update a charity.
 */
export const updateCharity = createAsyncThunk(
  'organization/updateCharity',
  async ({ id, data } : {id: number, data: Partial<Charity>}, { rejectWithValue }) => {
    try {
      return await editCharity(id, data);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

/**
 * Delete a charity.
 */
export const deleteCharity = (id: number): AppThunk => async (dispatch: Dispatch) => {
  try {
    dispatch(setArchivedCharityStart());

    await removeCharity(id);

    dispatch(setArchivedCharitySuccess());
  } catch (error) {
    dispatch(setArchivedCharityFailed(error as Error));

    throw error;
  }
};

/**
 * Restore a charity.
 */
export const restoreCharity = (id: number): AppThunk => async (dispatch: Dispatch) => {
  try {
    dispatch(setRestoredCharityStart());

    const charity = await unarchiveCharity(id);

    dispatch(setRestoredCharitySuccess(charity));
  } catch (error) {
    dispatch(setRestoredCharityFailed(error as Error));

    throw error;
  }
};

/**
 * Get organization images list.
 */
export const getOrganizationImages = createAsyncThunk(
  'organization/getOrganizationImages',
  () => fetchOrganizationImages(),
);

/**
 * Delete organization images.
 */
export const deleteOrganizationImages = createAsyncThunk(
  'organization/deleteOrganizationImages',
  async (data: number[], { rejectWithValue }) => {
    try {
      return await deleteBanners(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

/**
 * Get or search paginated list of organization transactions.
 */
export const getTransactions = createAsyncThunk(
  'organization/getTransactions',
  ({ params = {} }: { params?: QueryParams }) => fetchTransactions(params),
);

/**
 * Get transaction by id.
 */
export const getTransaction = createAsyncThunk(
  'organization/getTransaction',
  (id: number) => fetchTransaction(id),
);

/**
 * Get or search paginated list of charity stats.
 */
export const getCharityStats = createAsyncThunk(
  'organization/getCharityStats',
  ({ params = {} }: { params?: QueryParams }) => fetchCharityStats(params),
);

/**
 * Get list of recurring transactions.
 */
export const getReoccurringTransactions = createAsyncThunk(
  'organization/getReoccurringTransactions',
  ({ params = {} }: { params?: QueryParams }) => fetchReoccurringTransactions(params),
);

/**
 * Void transaction.
 */
export const voidTransaction = createAsyncThunk(
  'organization/voidTransaction',
  (id: number) => voidTransactionById(id),
);

/**
 * Refund transaction.
 */
export const refundTransaction = createAsyncThunk(
  'organization/refundTransaction',
  (id: number) => refundTransactionById(id),
);

/**
 * Delete transaction.
 */
export const deleteTransaction = createAsyncThunk(
  'organization/deleteTransaction',
  (id: number) => deleteTransactionById(id),
);

/**
 * Add cash transaction.
 */
export const addCashTransaction = createAsyncThunk(
  'organization/addCashTransaction',
  async (data: ManualTransaction, { rejectWithValue }) => {
    try {
      return await createCashTransaction(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

/**
 * Add check transaction.
 */
export const addCheckTransaction = createAsyncThunk(
  'organization/addCheckTransaction',
  async (data: ManualTransaction, { rejectWithValue }) => {
    try {
      return await createCheckTransaction(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

/**
 * Get QR by slug.
 */
export const getQR = createAsyncThunk(
  'organization/getQR',
  (slug: string) => fetchQR(slug),
);
