import React from 'react';
import { Link } from 'react-router-dom';
import { GridRenderCellParams, GridColumns } from '@mui/x-data-grid';

import { getFormattedCurrency } from 'utils/currency';
import { getFormattedDate } from 'utils/date';
import { getPaymentMethodText } from 'utils/getPaymentMethodText';
import { getLast4 } from 'utils/getLast4';
import { getInternationalFormat } from 'utils/phone';
import { PaymentMethod } from 'models/enums';

const columns: GridColumns = [
  {
    field: 'transactionId',
    headerName: 'Transaction Id',
    minWidth: 200,
    renderCell: ({ id, value }: GridRenderCellParams) => (
      <Link className="noUnderlineLink text-uppercase" to={`/donations/${id}`}>{value}</Link>
    ),
  },
  {
    field: 'receipt',
    headerName: 'Donators',
    minWidth: 200,
    flex: 1,
    renderCell: ({ id, value }: GridRenderCellParams) => {
      const displayValue = value?.toString().includes('@') ? value : getInternationalFormat(value?.toString());

      return <Link className="noUnderlineLink" to={`/donations/${id}`}>{displayValue}</Link>;
    },
  },
  {
    field: 'amount',
    headerName: 'Donation Amount',
    minWidth: 140,
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    renderCell: ({ value }: GridRenderCellParams) => {
      if (value) {
        return (
          <strong>{getFormattedCurrency(Number(value))}</strong>
        );
      }

      return value;
    },
  },
  {
    field: 'createdAt',
    headerName: 'Charge Date',
    minWidth: 140,
    renderCell: ({ value }: GridRenderCellParams) => {
      if (value) {
        return getFormattedDate(value as Date);
      }

      return value?.toString();
    },
  },
  {
    field: 'paymentMethod',
    headerName: 'Payment Type',
    minWidth: 170,
    renderCell: ({ value }: GridRenderCellParams) => getPaymentMethodText(value as string),
  },
  {
    field: 'last4',
    headerName: 'Ends In',
    renderCell: ({ value, row }: GridRenderCellParams) => {
      if (row.paymentMethod === PaymentMethod.Card) {
        return getLast4(value as string);
      }

      return <>&ndash;</>;
    },
  },
];

export { columns };
