import { createAsyncThunk, Dispatch } from '@reduxjs/toolkit';

import { fetchUser, updateUser, createUser } from 'api/services/User';
import User from 'models/User';
import Register from 'models/Register';

import { AppThunk } from '../index';
import {
  setUserStart,
  setUserSuccess,
  setUserFailed,
  setRegisterUserStart,
  setRegisterUserSuccess,
  setRegisterUserFailed,
} from './userSlice';
/**
 * Get user.
 */
export const getUser = (): AppThunk => async (dispatch: Dispatch) => {
  try {
    dispatch(setUserStart());
    const user = await fetchUser();

    dispatch(setUserSuccess(user));
  } catch (error) {
    dispatch(setUserFailed(error as Error));

    throw error;
  }
};

/**
 * Update user's info.
 */
export const update = createAsyncThunk(
  'user/update',
  (data: User) => updateUser(data),
);

/**
 * Register a new user.
 */
export const create = (data: Register): AppThunk => async (dispatch: Dispatch) => {
  try {
    dispatch(setRegisterUserStart());

    await createUser(data);

    dispatch(setRegisterUserSuccess());
  } catch (error) {
    dispatch(setRegisterUserFailed(error as Error));

    throw error;
  }
};
