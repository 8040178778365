import React, {
  ChangeEvent, memo, useState, useEffect,
} from 'react';
import { FormikValues, useField, useFormikContext } from 'formik';
import {
  FormControl, FormLabel, RadioGroup, Radio, RadioProps, FormHelperText, Typography, FormControlLabel, Box,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Close as CloseIcon } from '@material-ui/icons';

import Color from 'models/Color';

import styles from './BaseColorRadio.module.css';

interface BaseColorRadioProps extends RadioProps {
 /**
  * List of colors.
  */
  colors: Color[] | null;
  /**
   * Field name.
   */
  name: string;
  /**
   * Text label.
   */
  label: string;
  /**
   * State of loading data.
   */
  loading?: boolean;
  /**
   * Callback on select color.
   */
  onPreview: (color: string) => void;
}

const BLUE_COLOR = 1;

const ColorRadio = ({ background, value, ...props }: RadioProps & { background: string }) => (
  <Radio
    {...props}
    value={value}
    checkedIcon={(
      <>
        {value === BLUE_COLOR && <CloseIcon className={styles.noColor} />}
        <span
          className={styles.icon}
          style={{
            backgroundColor: background,
            boxShadow: 'rgb(32 131 165) 0 0 8px 2px, rgb(0 0 0 / 50%) 0 0 0 1px inset',
          }}
        />
      </>

    )}
    icon={(
      <>
        {value === BLUE_COLOR && <CloseIcon className={styles.noColor} />}
        <span
          className={styles.icon}
          style={{
            backgroundColor: background,
            boxShadow: '0 0 0 1px rgba(0,0,0,0.5) inset',
          }}
        />
      </>
    )}
  />
);

const CustomSkeleton = () => (
  <div className={styles.skeletonHost}>
    <Box>
      <Skeleton variant="circle" width={32} height={32} className={styles.skeleton} />
    </Box>
    <Skeleton width="100%">
      <Typography>.</Typography>
    </Skeleton>
  </div>
);

const BaseColorRadioComponent = ({
  className, label, colors, name, loading, onPreview, ...props
}: BaseColorRadioProps): JSX.Element => {
  const { setFieldValue } = useFormikContext<FormikValues>();
  const [field, meta] = useField(name);

  const [value, setValue] = useState<number | null>(field.value);

  const initialColor = colors?.find((option) => option.id === value);

  useEffect(() => {
    setValue(Number(field.value));
    setFieldValue(name, Number(field.value));
    onPreview(initialColor?.color || '');
  }, [field.value, setFieldValue, name, onPreview, initialColor?.color]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>, color: string) => {
    const { value } = event.target;

    setValue(Number(value));
    setFieldValue(name, Number(value));
    onPreview(color);
  };

  const configRadio = {
    ...field,
    ...props,
  };

  const configFormControl = {
    error: false,
    component: 'fieldset',
    className: styles.host,
  };

  let helperText: string | null = null;

  if (meta && meta.touched && meta.error) {
    configFormControl.error = true;
    helperText = meta.error;
  }

  if (!loading && colors?.length === 0) {
    return <Typography variant="body2">No Data</Typography>;
  }

  return (
    <FormControl {...configFormControl}>
      <FormLabel component="legend">{label}</FormLabel>
      {loading && (
        <div className={styles.skeletons}>
          <CustomSkeleton />
          <CustomSkeleton />
          <CustomSkeleton />
        </div>
      )}

      {!loading && (
      <RadioGroup
        defaultValue={field.value || null}
        value={value}
        aria-label="Select color"
        name={name}
        className={styles.radioGroup}
      >
        {colors?.map((radio) => (
          <div key={radio.id}>
            <FormControlLabel
              className={styles.label}
              value={radio.id}
              control={(
                <ColorRadio
                  {...configRadio}
                  onChange={(event) => handleChange(event, radio.color)}
                  background={radio.color}
                  value={radio.id}
                  id={`${radio.name}-${radio.id}`}
                />
              )}
              label={radio.name}
            />
          </div>
        ))}
      </RadioGroup>
      )}

      <FormHelperText error>{helperText}</FormHelperText>
    </FormControl>
  );
};

BaseColorRadioComponent.defaultProps = {
  loading: false,
};

export const BaseColorRadio = memo(BaseColorRadioComponent);
