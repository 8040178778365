import Kiosk from 'models/Kiosk';
import PagedList from 'models/PagedList';
import KioskDto from 'api/dtos/kioskDto';
import ResponseDto from 'api/dtos/responseDto';
import { mapDtoToPagination } from 'api/mappers/paginationMapper';

/**
 * Map DTO to Kiosk model.
 */
export const mapDtoToKiosk = (dto: KioskDto): Kiosk => new Kiosk({
  id: dto.id,
  serialNumber: dto.serial_number,
  status: dto.status,
});

/**
 * Map DTO to Kiosk paged list.
 */
export function mapDtoToKiosks(dtos: ResponseDto<KioskDto>): PagedList<Kiosk> {
  return new PagedList<Kiosk>({
    pagination: mapDtoToPagination(dtos.pagination),
    results: dtos.results?.map(mapDtoToKiosk),
  });
}

/**
 * Map Kiosk model to DTO.
 */
export const mapKioskToDto = (payload: Kiosk): KioskDto => ({
  id: payload.id,
  serial_number: payload.serialNumber,
  status: payload.status,
});
