import { createAsyncThunk } from '@reduxjs/toolkit';

import { uploadToS3 } from 'api/services/Upload';

/**
 * Upload a file to S3.
 */
export const uploadFile = createAsyncThunk(
  'upload/uploadFile',
  (data: File) => uploadToS3(data),
);
