import React from 'react';
import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import clsx from 'clsx';

import { getKioskStatus } from 'utils/getKioskStatus';

import { ActionCell } from './ActionCell';
import styles from './KiosksTable.module.css';

const columns: GridColumns = [
  {
    field: 'serialNumber',
    headerName: 'Kiosk Serial Number',
    minWidth: 150,
    flex: 1,
    renderCell: ({ value }: GridRenderCellParams) => {
      if (value) {
        return (
          <strong>{value}</strong>
        );
      }

      return <>&ndash;</>;
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 180,
    renderCell: ({ value, row }: GridRenderCellParams) => (
      <div className={styles.statusWrap}>
        <div className={clsx(styles.dot, row.status)} />
        <span>{getKioskStatus(value as string)}</span>
      </div>
    ),
  },
  {
    field: 'action',
    headerName: ' ',
    width: 200,
    sortable: false,
    align: 'right',
    cellClassName: styles.visibleOnHover,
    renderCell: (params: GridRenderCellParams) => <ActionCell {...params} />,
  },
];

export { columns };
