import Charity from 'models/Charity';
import OrganizationImage from 'models/OrganizationImage';
import PagedList from 'models/PagedList';
import OrganizationTransaction from 'models/OrganizationTransaction';
import Filter from 'models/Filter';
import CharityStat from 'models/CharityStat';

interface OrganizationState {
  charities: {
    loading: boolean;
    processing: boolean;
    updating: boolean;
    data: PagedList<Charity> | null;
    error: string | null;
  };
  images: {
    loading: boolean;
    processing: boolean;
    deleting: boolean;
    data: PagedList<OrganizationImage> | null;
    error: string | null;
  };
  transactions: {
    loading: boolean;
    processing: boolean;
    data: PagedList<OrganizationTransaction> | null;
    details: OrganizationTransaction | null;
    filter: Filter<Record<string, unknown>> | null;
    error: string | null;
    currentRequestId: string | null;
  };
  reoccurringTransactions: {
    loading: boolean;
    data: PagedList<OrganizationTransaction> | null;
    error: string | null;
    currentRequestId: string | null;
  };
  charityStats: {
    loading: boolean;
    processing: boolean;
    data: PagedList<CharityStat> | null;
    filter: Filter<Record<string, unknown>> | null;
    error: string | null;
    currentRequestId: string | null;
  };
  qr: {
    loading: boolean;
    data: string | null;
    error: string | null;
  };
}

export const state: OrganizationState = {
  charities: {
    loading: false,
    processing: false,
    updating: false,
    data: null,
    error: null,
  },
  images: {
    loading: false,
    processing: false,
    deleting: false,
    data: null,
    error: null,
  },
  transactions: {
    loading: false,
    processing: false,
    data: null,
    details: null,
    filter: null,
    error: null,
    currentRequestId: null,
  },
  reoccurringTransactions: {
    loading: false,
    data: null,
    error: null,
    currentRequestId: null,
  },
  charityStats: {
    loading: false,
    processing: false,
    data: null,
    filter: null,
    error: null,
    currentRequestId: null,
  },
  qr: {
    loading: false,
    data: null,
    error: null,
  },
};
