import React, {
  memo, useEffect, useRef, MouseEvent,
} from 'react';
import { Formik } from 'formik';
import {
  DialogProps, Dialog, DialogContent, Grid, DialogTitle, FormLabel,
} from '@material-ui/core';

import {
  BaseButton, BaseInput, BaseDateRangePicker, BaseSelect,
} from 'components';
import { useAppSelector, useAppDispatch } from 'store/rootReducer';
import { getDonationTypes } from 'store/Site';
import { setFilter } from 'store/Organization/organizationSlice';
import { FilterForm } from 'models/FilterForm';
import { getFormattedDate, SQL_DATE_FORMAT, DATE_PICKER_FORMAT } from 'utils/date';
import DonationType from 'models/DonationType';
import Charity from 'models/Charity';
import { getCharities } from 'store/Organization';
import { CharityStatus } from 'models/enums';
import QueryParams from 'models/QueryParams';

import { initialFormState, DonationsFilter } from './formSettings';
import styles from './DonationsFilterDialog.module.css';

interface FilterDialogProps extends DialogProps {
  /**
   * Query params.
   */
  queryParams?: QueryParams;

  /**
   * On filter callback.
   */
  onFilter: (values: FilterForm) => void;
}

type Options = {
  readonly id: number | string | null;
  readonly name: string;
  readonly disabled?: boolean;
}

const DonationsFilterDialogComponent = ({
  queryParams, open, onClose, onFilter, ...props
}: FilterDialogProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const prevQueryParamsRef = useRef<QueryParams>();

  const { data: donationTypes, loading: loadingDonationTypes } = useAppSelector((state) => state.site.donationTypes);
  const { filter } = useAppSelector((state) => state.organization.transactions);
  const { data: charities, loading: loadingCharities } = useAppSelector((state) => state.organization.charities);

  const prevQueryParams = prevQueryParamsRef.current;

  // Need to check previous params and update charities dropdown list.
  useEffect(() => {
    prevQueryParamsRef.current = queryParams;
  }, [queryParams]);

  useEffect(() => {
    if (!donationTypes) {
      dispatch(getDonationTypes());
    }
  }, [dispatch, donationTypes]);

  useEffect(() => {
    if (JSON.stringify(prevQueryParams) !== JSON.stringify(queryParams)) {
      dispatch(getCharities({ showArchived: false, params: queryParams }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  useEffect(() => () => {
    // Reset filter state on unmount.
    dispatch(setFilter({ ...initialFormState }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = (event: MouseEvent<HTMLButtonElement>) => {
    // onClose is optional props. Need to pass event and reason 'escapeKeyDown' for closing by Esc.
    if (onClose) {
      onClose(event, 'escapeKeyDown');
    }
  };

  const handleSubmit = (values: DonationsFilter, event: MouseEvent<HTMLButtonElement>) => {
    const data = {
      minAmount: Number(values.min) || null,
      maxAmount: Number(values.max) || null,
      startDate: values.date ? getFormattedDate(values.date[0], SQL_DATE_FORMAT) : null,
      endDate: values.date ? getFormattedDate(values.date[1], SQL_DATE_FORMAT) : null,
      charityId: (values.charityId === 'null' ? null : values.charityId) || null,
      donationType: (values.donationType === 'null' ? null : values.donationType) || null,
    };

    onFilter(data);
    dispatch(setFilter(values));
    handleClose(event);
  };

  const handleReset = (event: MouseEvent<HTMLButtonElement>) => {
    const data = {
      minAmount: null,
      maxAmount: null,
      startDate: null,
      endDate: null,
      charityId: null,
      donationType: null,
    };

    onFilter(data);
    dispatch(setFilter({ ...initialFormState }));
    handleClose(event);
  };

  const donationTypeOptions: Options[] = [
    new DonationType({ id: 'null', name: 'All types' }),
  ];

  if (donationTypes && donationTypes?.results.length > 0) {
    donationTypeOptions.push(...donationTypes.results);
  }

  const charitiesOptions: Options[] = [
    new Charity({
      id: 'null',
      name: 'All Categories',
      deletedAt: null,
      status: CharityStatus.Active,
      sequence: 0,
      fixPriceValue: null,
      commentTitle: null,
      defaultForTackon: false,
      isFixPrice: false,
      isCommented: false,
    }),
  ];

  if (charities && charities?.results.length > 0) {
    charitiesOptions.push(...charities.results);
  }

  const initialValues = filter ? { ...filter } : { ...initialFormState };

  return (
    <Dialog
      {...props}
      open={open}
      aria-labelledby="alert-dialog-title"
      onClose={handleClose}
      disableEnforceFocus
    >
      <DialogTitle id="alert-dialog-title">
        Filter
      </DialogTitle>
      <DialogContent>
        <Formik
          onSubmit={() => {}}
          initialValues={initialValues}
        >
          {({ values }) => (
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <BaseInput
                  label="Min Donation Amount"
                  id="min"
                  name="min"
                  type="number"
                  placeholder="Min"
                  onlyPositiveNumbers
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <BaseInput
                  label="Max Donation Amount"
                  id="max"
                  name="max"
                  type="number"
                  placeholder="Max"
                  onlyPositiveNumbers
                />
              </Grid>
              <Grid item xs={12}>
                <FormLabel className={styles.timestamp}>Time</FormLabel>
                <BaseDateRangePicker
                  id="date"
                  label=""
                  name="date"
                  fullWidth
                  format={DATE_PICKER_FORMAT}
                />
              </Grid>
              <Grid item xs={12}>
                <BaseSelect
                  label="Giving Categories"
                  id="charityId"
                  name="charityId"
                  itemKey="id"
                  itemValue="name"
                  options={charitiesOptions}
                  loading={loadingCharities}
                />
              </Grid>
              <Grid item xs={12}>
                <BaseSelect
                  label="Donation Type"
                  id="donationType"
                  name="donationType"
                  itemKey="id"
                  itemValue="name"
                  options={donationTypeOptions}
                  loading={loadingDonationTypes}
                />
              </Grid>
              <Grid item xs={12}>
                <div className={styles.buttons}>
                  <div className={styles.leftPart}>
                    <BaseButton
                      className={styles.button}
                      onClick={handleReset}
                    >
                      Reset Filter
                    </BaseButton>
                  </div>
                  <div>
                    <BaseButton className={styles.button} onClick={handleClose}>Cancel</BaseButton>
                    <BaseButton
                      className={styles.button}
                      color="primary"
                      onClick={(event) => handleSubmit(values as DonationsFilter, event)}
                      variant="contained"
                    >
                      Apply
                    </BaseButton>
                  </div>
                </div>
              </Grid>
            </Grid>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

DonationsFilterDialogComponent.defaultProps = {
  queryParams: {},
};

export const DonationsFilterDialog = memo(DonationsFilterDialogComponent);
