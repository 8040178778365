// eslint-disable-next-line max-classes-per-file
import { PaymentMethod } from 'models/enums';

/**
 *  Payment token model class.
 */
export default class PaymentToken {
  /**
   * Account number.
   */
  public readonly account?: string;

  /**
   * CVV.
   */
  public readonly cvv?: string;

  /**
   * Expiry date(example: '1122').
   */
  public readonly expiry?: string | null;

  /**
   * Payment method.
   */
  public readonly paymentMethod: PaymentMethod;

  /**
   * Routing number.
   */
  public readonly routingNumber?: string;

  /**
   * Account number.
   */
  public readonly accountNumber?: string;

  constructor(data: PaymentToken) {
    this.account = data.account;
    this.cvv = data.cvv;
    this.expiry = data.expiry;
    this.paymentMethod = data.paymentMethod;
    this.routingNumber = data.routingNumber;
    this.accountNumber = data.accountNumber;
  }
}

/**
 *  Token model class.
 */
export class PaymentTokenResponse {
  public readonly token: string;

  constructor(data: PaymentTokenResponse) {
    this.token = data.token;
  }
}
