// eslint-disable-next-line max-classes-per-file
import { AxiosResponse } from 'axios';

import { ApiErrorDetails } from 'api/dtos/apiErrorDto';

/**
 * Available error types.
 */
enum ErrorType {
  AccessDenied = 'AccessDenied',
  ServerValidationError = 'ServerValidationError',
  ServerUnavailable = 'ServerUnavailable',
  InternalServerError = 'InternalServerError',
  GatewayTimeout = 'GatewayTimeout',
  ServerError = 'ServerError',
  NetworkError = 'NetworkError',
  FailedDependency = 'FailedDependency',
}

/**
 * HTTP 403 - does not have access rights to the content.
 */
export class AccessDenied extends Error {
  constructor(response: AxiosResponse) {
    const message = response.data?.message || response.statusText;

    super(message);
    this.name = ErrorType.AccessDenied;
  }
}

/**
 * HTTP 400 - Returns information about incorrect fields
 * Map: field => array of related error messages
 */
export class ServerValidationError extends Error {
  public readonly errors: ApiErrorDetails;

  constructor({ message, errors }: { message: string; errors: ApiErrorDetails }) {
    super(message);
    this.name = ErrorType.ServerValidationError;
    this.errors = errors || [];
  }
}

/**
 * HTTP 503 - Typically, when application is updated, restarted, etc.
 */
export class ServerUnavailable extends Error {
  constructor() {
    super('Server is temporary unavailable, try in a few minutes');
    this.name = ErrorType.ServerUnavailable;
  }
}

/**
 * HTTP 500 - Most probably, unhandled error in server code
 */
export class InternalServerError extends Error {
  constructor(response: AxiosResponse) {
    const message = response.data?.message || response.statusText;

    super(message);
    this.name = ErrorType.InternalServerError;
  }
}

/**
 * Server code was executing too long, and Nginx returned error without waiting for result.
 */
export class GatewayTimeout extends Error {
  constructor() {
    super('Server operation timed out');
    this.name = ErrorType.GatewayTimeout;
  }
}

/** For all other cases, when server replies with error */
export class ServerError extends InternalServerError {
  private data: AxiosResponse;

  constructor(response: AxiosResponse) {
    super(response);
    this.name = ErrorType.ServerError;
    this.data = response.data;
  }
}

/**
 * HTTP Server reply was not received.
 * Probably no network connection or CORS error, AdBlock, etc.
 */
export class NetworkError extends Error {
  constructor(message: string) {
    super(message || 'Check your network connection');
    this.name = ErrorType.NetworkError;
  }
}

/**
 * HTTP 424 - Request has valid content, but requires another action to be done previously.
 * In PearPop it is used, when user must 3D Secure authorization to complete payment.
 */
export class FailedDependency extends InternalServerError {
  private data: AxiosResponse;

  constructor(response: AxiosResponse) {
    super(response);
    this.name = ErrorType.FailedDependency;
    this.data = response.data;
  }
}
