import axios, { Canceler } from 'axios';

import CharityTransactionDto from 'api/dtos/charityTransactionDto';
import { mapDtoToCharityTransactions } from 'api/mappers/charityTransactionMapper';
import ResponseDto from 'api/dtos/responseDto';
import QueryParams from 'models/QueryParams';
import PagedList from 'models/PagedList';
import CharityTransaction from 'models/CharityTransaction';
import { mapQueryParamsToDto } from 'api/mappers/queryParamsMapper';

import { http } from '../index';

const { CancelToken } = axios;
let cancelRequest: Canceler;

/**
 * Fetch charity transactions.
 */
export const fetchTransactions = async (
  id: number,
  params: QueryParams,
): Promise<PagedList<CharityTransaction>> => {
  if (cancelRequest) {
    cancelRequest();
  }

  const dtoParams = mapQueryParamsToDto(params);
  const { data } = await http.get<ResponseDto<CharityTransactionDto>>(
    `/charity/${id}/transactions`,
    {
      params: dtoParams,
      cancelToken: new CancelToken((cancel) => {
        cancelRequest = cancel;
      }),
    },
  );

  return mapDtoToCharityTransactions(data);
};
