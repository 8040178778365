import OrganizationTransaction from 'models/OrganizationTransaction';
import OrganizationTransactionDto from 'api/dtos/organizationTransactionDto';
import PagedList from 'models/PagedList';
import ResponseDto from 'api/dtos/responseDto';
import { mapDtoToPagination } from 'api/mappers/paginationMapper';

/**
 * Map DTO to Organization Transaction model.
 */
export const mapDtoToOrganizationTransaction = (
  dto: OrganizationTransactionDto,
): OrganizationTransaction => new OrganizationTransaction({
  id: dto.id,
  amount: dto.amount,
  fee: dto.fee,
  firstName: dto.first_name,
  lastName: dto.last_name,
  name: dto.name,
  donator: dto.donator,
  receipt: dto.receipt,
  paymentMethod: dto.payment_method,
  donationType: dto.donation_type,
  createdAt: new Date(dto.created_at),
  nameOnCard: dto.name_on_card,
  achName: dto.ach_name,
  last4: dto.last_4,
  reoccurringPaymentId: dto.reoccurring_payment_id,
  transactionId: dto.transaction_id,
  status: dto.status,
  category: dto.category,
  donateAs: dto.donate_as,
  userId: dto.user_id,
  comment: dto.comment,
  commentTitle: dto.comment_title,
});

/**
 * Map DTO to Organization Transactions paged list.
 */
export function mapDtoToOrganizationTransactions(
  dtos: ResponseDto<OrganizationTransactionDto>,
): PagedList<OrganizationTransaction> {
  return new PagedList<OrganizationTransaction>({
    pagination: mapDtoToPagination(dtos.pagination),
    results: dtos.results?.map(mapDtoToOrganizationTransaction),
  });
}
