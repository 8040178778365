import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import Kiosk from 'models/Kiosk';
import {
  getKiosks, createKiosks, unregisterKiosk, updateKiosk,
} from 'store/Kiosk/dispatchers';

import { state } from './state';

const kioskSlice = createSlice({
  name: 'kiosk',
  initialState: state,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getKiosks.pending, (state, { meta }) => {
      state.kiosks.loading = true;
      state.kiosks.error = null;
      state.kiosks.currentRequestId = meta.requestId;
    });
    builder.addCase(getKiosks.fulfilled, (state, { payload, meta }) => {
      if (state.kiosks.loading && state.kiosks.currentRequestId === meta.requestId) {
        state.kiosks.data = payload;
        state.kiosks.loading = false;
        state.kiosks.currentRequestId = null;
      }
    });
    builder.addCase(getKiosks.rejected, (state, { error, meta }) => {
      if (state.kiosks.loading && state.kiosks.currentRequestId === meta.requestId) {
        state.kiosks.error = error.message ?? '';
        state.kiosks.loading = false;
        state.kiosks.currentRequestId = null;
      }
    });
    builder.addCase(unregisterKiosk.pending, (state) => {
      state.kiosks.processing = true;
      state.kiosks.error = null;
    });
    builder.addCase(unregisterKiosk.fulfilled, (state, { payload }: PayloadAction<Kiosk>) => {
      const data = state.kiosks.data?.results.map((kiosk) => {
        if (kiosk.id === payload.id) {
          return payload;
        }

        return kiosk;
      });

      state.kiosks.data!.results = data as Kiosk[] || state.kiosks.data!.results;
      state.kiosks.processing = false;
    });
    builder.addCase(unregisterKiosk.rejected, (state, { error }) => {
      state.kiosks.error = error.message ?? '';
      state.kiosks.processing = false;
    });
    builder.addCase(createKiosks.pending, (state) => {
      state.kiosks.processing = true;
      state.kiosks.error = null;
    });
    builder.addCase(createKiosks.fulfilled, (state) => {
      state.kiosks.processing = false;
    });
    builder.addCase(createKiosks.rejected, (state, { error }) => {
      state.kiosks.error = error.message ?? '';
      state.kiosks.processing = false;
    });
    builder.addCase(updateKiosk.pending, (state) => {
      state.kiosks.processing = true;
      state.kiosks.error = null;
    });
    builder.addCase(updateKiosk.fulfilled, (state, { payload }: PayloadAction<Kiosk>) => {
      const data = state.kiosks.data?.results.map((kiosk) => {
        if (kiosk.id === payload.id) {
          return payload;
        }

        return kiosk;
      });

      state.kiosks.data!.results = data as Kiosk[] || state.kiosks.data!.results;
      state.kiosks.processing = false;
    });
    builder.addCase(updateKiosk.rejected, (state, { error }) => {
      state.kiosks.error = error.message ?? '';
      state.kiosks.processing = false;
    });
  },
});

export const kiosk = kioskSlice.reducer;
