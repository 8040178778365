import Dashboard from 'models/Dashboard';
import DashboardDto from 'api/dtos/dashboardDto';

/**
 * Map DTO to Dashboard model.
 */
export const mapDtoToDashboard = (dto: DashboardDto): Dashboard => new Dashboard({
  totalAmount: dto.total_amount,
  totalFee: dto.total_fee,
  numberOfDonations: dto.number_of_donations,
  totalDonors: dto.total_donors,
  newDonors: dto.new_donors,
  reoccurringDonors: dto.reoccurring_donors,
  donationByType: dto.donation_by_type,
});
