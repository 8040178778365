import axios, { AxiosResponse, Canceler } from 'axios';

import Charity from 'models/Charity';
import CharityStat from 'models/CharityStat';
import PagedList from 'models/PagedList';
import QueryParams from 'models/QueryParams';
import OrganizationTransaction from 'models/OrganizationTransaction';
import OrganizationImage from 'models/OrganizationImage';
import ManualTransaction from 'models/ManualTransaction';
import OrganizationImagesDto from 'api/dtos/organizationImagesDto';
import ResponseDto from 'api/dtos/responseDto';
import CharityStatDto from 'api/dtos/charityStatDto';
import CharityDto from 'api/dtos/charityDto';
import OrganizationTransactionDto from 'api/dtos/organizationTransactionDto';
import { mapManualTransactionToDto } from 'api/mappers/manualTransactionMapper';
import { mapCharityToDto, mapDtoToCharities, mapDtoToCharity } from 'api/mappers/charityMapper';
import { mapDtoToOrganizationImages } from 'api/mappers/organizationImagesMapper';
import { mapQueryParamsToDto } from 'api/mappers/queryParamsMapper';
import { mapDtoToCharityStats } from 'api/mappers/charityStatMapper';
import {
  mapDtoToOrganizationTransaction,
  mapDtoToOrganizationTransactions,
} from 'api/mappers/organizationTransactionMapper';

import { http } from '../index';

/**
 * Fetch charities with/without archived status.
 */
export const fetchCharities = async (showArchived: boolean, params: QueryParams): Promise<PagedList<Charity>> => {
  const dtoParams = mapQueryParamsToDto(params);
  const { data } = await http.get<ResponseDto<CharityDto>>(`/organizations/charity?show_archived=${showArchived}`, {
    params: dtoParams,
  });

  return mapDtoToCharities(data);
};

/**
 * Fetch all charities with/without archived status.
 */
export const fetchAllCharities = async (showArchived: boolean, params: QueryParams): Promise<PagedList<Charity>> => {
  const dtoParams = mapQueryParamsToDto(params);
  const { data } = await http.get<ResponseDto<CharityDto>>(
    `/organizations/all-charities?show_archived=${showArchived}`,
    { params: dtoParams },
  );

  return mapDtoToCharities(data);
};

/**
 * Create a charity.
 */
export const createCharity = async (payload: Partial<Charity>): Promise<Charity> => {
  const dto = mapCharityToDto(payload as Charity);
  const { data } = await http.post<CharityDto>('/organizations/charity', dto);

  return mapDtoToCharity(data);
};

/**
 * Update a charity.
 */
export const updateCharity = async (id: number, payload: Partial<Charity>): Promise<Charity> => {
  const dto = mapCharityToDto(payload as Charity);

  const { data } = await http.put<CharityDto>(`/organizations/charity/${id}`, dto);

  return mapDtoToCharity(data);
};

/**
 * Delete a charity.
 */
export const deleteCharity = (id: number): Promise<unknown> => http.delete(`/organizations/charity/${id}`);

/**
 * Restore a charity.
 */
export const restoreCharity = async (id: number): Promise<Charity> => {
  const { data } = await http.put<CharityDto>(`/organizations/charity/${id}/restore`);

  return mapDtoToCharity(data);
};

/**
 * Organization images.
 */
export const fetchOrganizationImages = async (): Promise<PagedList<OrganizationImage>> => {
  const { data } = await http.get<ResponseDto<OrganizationImagesDto>>('/organizations/images');

  return mapDtoToOrganizationImages(data);
};

/**
 * Delete organization images.
 */
export const deleteOrganizationImages = (payload: number[]): Promise<AxiosResponse> => {
  const dto = {
    ids: payload,
  };

  return http.post<AxiosResponse>('/organizations/images', dto);
};

const { CancelToken } = axios;
let cancelTransactionsRequest: Canceler;
let cancelReoccurringTransactionsRequest: Canceler;
let cancelCharityStatsRequest: Canceler;

/**
 * Fetch organization transactions.
 */
export const fetchTransactions = async (params: QueryParams): Promise<PagedList<OrganizationTransaction>> => {
  if (cancelTransactionsRequest) {
    cancelTransactionsRequest();
  }

  const dtoParams = mapQueryParamsToDto(params);
  const { data } = await http.get<ResponseDto<OrganizationTransactionDto>>(
    '/organizations/transactions',
    {
      params: dtoParams,
      cancelToken: new CancelToken((cancel) => {
        cancelTransactionsRequest = cancel;
      }),
    },
  );

  return mapDtoToOrganizationTransactions(data);
};

/**
 * Fetch recurring organization transactions.
 */
export const fetchReoccurringTransactions = async (
  params: QueryParams,
): Promise<PagedList<OrganizationTransaction>> => {
  if (cancelReoccurringTransactionsRequest) {
    cancelReoccurringTransactionsRequest();
  }

  const dtoParams = mapQueryParamsToDto(params);
  const { data } = await http.get<ResponseDto<OrganizationTransactionDto>>(
    '/organizations/transactions',
    {
      params: dtoParams,
      cancelToken: new CancelToken((cancel) => {
        cancelReoccurringTransactionsRequest = cancel;
      }),
    },
  );

  return mapDtoToOrganizationTransactions(data);
};

/**
 * Fetch charity stats.
 */
export const fetchCharityStats = async (params: QueryParams): Promise<PagedList<CharityStat>> => {
  if (cancelCharityStatsRequest) {
    cancelCharityStatsRequest();
  }

  const dtoParams = mapQueryParamsToDto(params);
  const { data } = await http.get<ResponseDto<CharityStatDto>>(
    '/organizations/charity/stats',
    {
      params: dtoParams,
      cancelToken: new CancelToken((cancel) => {
        cancelCharityStatsRequest = cancel;
      }),
    },
  );

  return mapDtoToCharityStats(data);
};

/**
 * Fetch organization transaction by id.
 */
export const fetchTransaction = async (id: number): Promise<OrganizationTransaction> => {
  const { data } = await http.get<OrganizationTransactionDto>(`/organizations/transactions/${id}`);

  return mapDtoToOrganizationTransaction(data);
};

/**
 * Void transaction.
 */
export const voidTransaction = async (id: number): Promise<OrganizationTransaction> => {
  const { data } = await http.put<OrganizationTransactionDto>(`/organizations/transactions/${id}/void`);

  return mapDtoToOrganizationTransaction(data);
};

/**
 * Refund transaction.
 */
export const refundTransaction = async (id: number): Promise<OrganizationTransaction> => {
  const { data } = await http.put<OrganizationTransactionDto>(`/organizations/transactions/${id}/refund`);

  return mapDtoToOrganizationTransaction(data);
};

/**
 * Delete transaction.
 */
export const deleteTransaction = async (id: number): Promise<OrganizationTransaction> => {
  const { data } = await http.put<OrganizationTransactionDto>(`/organizations/transactions/${id}/delete`);

  return mapDtoToOrganizationTransaction(data);
};

/**
 * Add cash transaction.
 */
export const addCashTransaction = (payload: ManualTransaction): Promise<AxiosResponse> => {
  const dto = mapManualTransactionToDto(payload);

  return http.post<AxiosResponse>('/organizations/transactions/cash', dto);
};

/**
 * Add check transaction.
 */
export const addCheckTransaction = (payload: ManualTransaction): Promise<AxiosResponse> => {
  const dto = mapManualTransactionToDto(payload);

  return http.post<AxiosResponse>('/organizations/transactions/check', dto);
};

/**
 * Fetch QR by slug.
 */
export const fetchQR = async (slug: string): Promise<string> => {
  const { data } = await http.get<string>(`/organizations/${slug}/qr-code`);

  return data;
};
