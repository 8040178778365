import { addRequestInterceptor, addResponseInterceptor } from 'api';
import { addTokenBeforeRequest, afterFailedRequest } from 'api/interceptors';

const KEY = '-=SESSION_TOKEN_ADMIN=-';
const SAVED_EMAIL = '-=SAVED_EMAIL_ADMIN=-';

let sessionToken: string | null = localStorage.getItem(KEY) || null;
let savedEmail: string = localStorage.getItem(SAVED_EMAIL) || '';

/**
 * Save user's email into local storage.
 */
export const setUserEmail = (email: string | null, rememberMe: boolean = false) => {
  if (!email || !rememberMe) {
    localStorage.removeItem(SAVED_EMAIL);
  } else {
    localStorage.setItem(SAVED_EMAIL, email);
  }

  savedEmail = email || '';
};

/**
 * Set session token.
 */
export const setSessionToken = (token?: string) => {
  if (token) {
    localStorage.setItem(KEY, token);
  } else {
    localStorage.removeItem(KEY);
  }

  sessionToken = token || null;
};

/**
 * Remove session.
 */
export const removeSession = () => {
  localStorage.removeItem(KEY);
};

/**
 * Remove email.
 */
export const removeEmail = () => {
  if (!isRememberMe) {
    setUserEmail(null, false);
  }
};

/**
 * Check if a token is saved for the next session.
 */
export const isRememberMe = (): boolean => Boolean(localStorage.getItem(KEY));

/**
 * Get session token.
 */
export const getSessionToken = () => sessionToken;

/**
 * Get saved email.
 */
export const getSavedEmail = () => savedEmail;

addRequestInterceptor(addTokenBeforeRequest);

addResponseInterceptor((v) => v, afterFailedRequest);
