import { CharityStatus } from 'models/enums';

/**
 *  Charity model class.
 */
export default class Charity {
  /**
   * Unique Id.
   */
  public readonly id: number | string;

  /**
   * Name.
   */
  public readonly name: string;

  /**
   * Date of delete(iso).
   */
  public readonly deletedAt: Date | null;

  /**
   * Status.
   */
  public readonly status: CharityStatus;

  /**
   * Index of sort order.
   */
  public readonly sequence: number | null;

  /**
   * Comment title.
   */
  public readonly commentTitle: string | null;

  /**
   * Default for Tackon.
   */
  public readonly defaultForTackon: boolean;

  /**
   * Fix price value.
   */
  public readonly fixPriceValue: string | null;

  /**
   * Is fix price.
   */
  public readonly isFixPrice: boolean;

  /**
   * Is commented.
   */
  public readonly isCommented: boolean;

  constructor(data: Charity) {
    this.id = data.id;
    this.name = data.name;
    this.deletedAt = data.deletedAt;
    this.status = data.status;
    this.sequence = data.sequence;
    this.commentTitle = data.commentTitle;
    this.defaultForTackon = data.defaultForTackon;
    this.fixPriceValue = data.fixPriceValue;
    this.isFixPrice = data.isFixPrice;
    this.isCommented = data.isCommented;
  }
}
