import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonProps } from '@material-ui/core';
import clsx from 'clsx';
import { To } from 'history';

import styles from './BaseButtonLink.module.css';

interface BaseButtonLinkProps extends ButtonProps {
  /**
   * URL path.
   */
  to: To;
}

const BaseButtonLinkComponent = ({
  to, children, className, ...props
}: BaseButtonLinkProps): JSX.Element => (
  // @ts-ignore: see https://github.com/mui-org/material-ui/issues/7877
  <Button
    {...props}
    className={clsx(styles.host, className)}
    component={Link}
    to={to}
    disableElevation
  >
    {children}
  </Button>
);

export const BaseButtonLink = memo(BaseButtonLinkComponent);
