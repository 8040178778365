import React, {
  useState, memo, ChangeEvent, MouseEvent,
} from 'react';
import { useField, useFormikContext } from 'formik';
import {
  IconButton, InputAdornment, TextField, StandardTextFieldProps,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import styles from './BaseInputPassword.module.css';

interface BaseInputPasswordProps extends StandardTextFieldProps {
  /**
   * Field name.
   */
  name: string;
}

interface State {
  password: string;
  showPassword: boolean;
}

const BaseInputPasswordComponent = ({
  name, ...props
}: BaseInputPasswordProps): JSX.Element => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  const configTextField = {
    ...field,
    ...props,
    fullWidth: true,
  };

  if (meta && meta.touched && meta.error) {
    configTextField.error = true;
    configTextField.helperText = meta.error;
  }

  const [values, setValues] = useState<State>({
    password: '',
    showPassword: false,
  });

  const handleChange = (prop: keyof State) => (event: ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
    setFieldValue(name, event.target.value);
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <TextField
      {...configTextField}
      className={styles.input}
      type={values.showPassword ? 'text' : 'password'}
      value={values.password}
      onChange={handleChange('password')}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              className="inputIcon"
            >
              {values.showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export const BaseInputPassword = memo(BaseInputPasswordComponent);
