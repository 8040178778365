import React, { useState, useRef, memo } from 'react';
import {
  FormControl, FormHelperText, TextField, TextFieldProps,
} from '@material-ui/core';
import { DatePicker, BaseDatePickerProps as DatePickerProps } from '@material-ui/pickers';
import isValid from 'date-fns/isValid';
import usLocale from 'date-fns/locale/en-US';
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns';

import { FormikValues, useField, useFormikContext } from 'formik';
import styles from './BaseDatepicker.module.css';

interface BaseDatepickerProps extends DatePickerProps<Date> {
  /**
   * Unique id.
   */
  id: string;
  /**
   * Text label.
   */
  label: string;
  /**
   * Field name.
   */
  name: string;
  /**
   * Optional date format.
   * @default MM/dd/yyyy
   * @example 22/02/2021
   */
  format?: string;
  /**
   * Optional text placeholder.
   */
  placeholder?: string;
}

const dateAdapter = new DateFnsAdapter({ locale: usLocale });

const BaseDatepickerComponent = ({
  label, name, format, placeholder, ...props
}: BaseDatepickerProps): JSX.Element => {
  const refDatePicker = useRef<HTMLInputElement>(null);

  const { setFieldValue, values } = useFormikContext<FormikValues>();
  const [field, meta] = useField(name);
  const [value, setValue] = useState<Date | null>(values[name] || null);
  const [isOpen, setOpen] = useState(false);

  const handleDatePickerOpen = () => {
    setOpen(true);
  };

  const handleDatePickerClose = () => {
    setOpen(false);
    setTimeout(() => { refDatePicker?.current?.blur(); }, 0);
  };

  const handleChange = (date: Date | null) => {
    setValue(date);

    if (date && isValid(date)) {
      setFieldValue(name, date.toISOString());
    }

    handleDatePickerClose();
  };

  const configDatepicker = {
    ...field,
    ...props,
    value,
    placeholder,
    ref: refDatePicker,
    onChange: handleChange,
  };

  const configFormControl = {
    error: false,
    fullWidth: true,
  };

  let helperText: string | null = null;

  if (meta && meta.touched && meta.error) {
    configFormControl.error = true;
    helperText = meta.error;
  }

  return (
    <FormControl {...configFormControl}>
      <DatePicker
        {...configDatepicker}
        className={styles.input}
        dateAdapter={dateAdapter}
        open={isOpen}
        onClose={handleDatePickerClose}
        InputProps={{
          onClick: handleDatePickerOpen,
        }}
        renderInput={(props: TextFieldProps) => (
          <TextField
            {...props}
            helperText=""
            label={label}
            className={styles.input}
            error={configFormControl.error}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
      />
      <FormHelperText error>{helperText}</FormHelperText>
    </FormControl>
  );
};

BaseDatepickerComponent.defaultProps = {
  format: 'MM/dd/yyyy',
  placeholder: '',
};

export const BaseDatepicker = memo(BaseDatepickerComponent);
