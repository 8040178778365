import State from 'models/State';
import StateDto from 'api/dtos/stateDto';
import PagedList from 'models/PagedList';
import ResponseDto from 'api/dtos/responseDto';
import { mapDtoToPagination } from 'api/mappers/paginationMapper';

/**
 * Map DTO to State model.
 */
export const mapDtoToState = (dto: StateDto): State => new State({
  id: dto.id,
  code: dto.code,
  name: dto.name,
});

/**
 * Map DTO to States paged list.
 */
export function mapDtoToStates(dtos: ResponseDto<StateDto>): PagedList<State> {
  return new PagedList<State>({
    pagination: mapDtoToPagination(dtos.pagination),
    results: dtos.results?.map(mapDtoToState),
  });
}
