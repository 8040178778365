import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetchBrandingColors, fetchStates, fetchDonationTypes } from 'api/services/Site';

/**
 * Get branding colors list.
 */
export const getBrandingColors = createAsyncThunk(
  'site/getBrandingColors',
  () => fetchBrandingColors(),
);

/**
 * Get states list.
 */
export const getStates = createAsyncThunk(
  'site/getStates',
  () => fetchStates(),
);

/**
 * Get donation types list.
 */
export const getDonationTypes = createAsyncThunk(
  'site/getDonationTypes',
  () => fetchDonationTypes(),
);
