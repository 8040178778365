import DonationType from 'models/DonationType';
import DonationTypeDto from 'api/dtos/donationTypeDto';
import PagedList from 'models/PagedList';
import ResponseDto from 'api/dtos/responseDto';
import { mapDtoToPagination } from 'api/mappers/paginationMapper';

/**
 * Map DTO to DonationType model.
 */
export const mapDtoToDonationType = (dto: DonationTypeDto): DonationType => new DonationType({
  id: dto.id,
  name: dto.name,
});

/**
 * Map DTO to DonationType paged list.
 */
export function mapDtoToDonationTypes(dtos: ResponseDto<DonationTypeDto>): PagedList<DonationType> {
  return new PagedList<DonationType>({
    pagination: mapDtoToPagination(dtos.pagination),
    results: dtos.results?.map(mapDtoToDonationType),
  });
}
