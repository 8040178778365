import React, { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import withSuspense from 'hoc/withSuspense';

const DashboardPage = withSuspense(lazy(() => import('./pages/DashboardPage/DashboardPage')));

export const dashboardRoutes: RouteObject[] = [
  {
    path: '',
    element: <Navigate to="/dashboard" replace />,
  },
  {
    path: 'dashboard',
    element: <DashboardPage />,
  },
];
