import React, { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import withSuspense from 'hoc/withSuspense';

const PaymentSettingsPage = withSuspense(lazy(() => import('./pages/PaymentSettingsPage/PaymentSettingsPage')));
const EditPaymentMethodPage = withSuspense(lazy(
  () => import('./pages/EditPaymentMethodPage/EditPaymentMethodPage'),
));

export const paymentRoutes: RouteObject[] = [
  {
    path: 'payment-settings',
    element: <PaymentSettingsPage />,
  },
  {
    path: 'payment-settings',
    children: [
      {
        path: 'payment-method',
        element: <EditPaymentMethodPage />,
      },
    ],
  },
];
