import React, { lazy, useEffect } from 'react';
import { Navigate, useRoutes, RouteObject } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'store/rootReducer';
import { getUser } from 'store/User';
import { selectIsAuthenticated } from 'store/Auth';
import withSuspense from 'hoc/withSuspense';
import MainLayout from 'layout/MainLayout/MainLayout';
import { authRoutes } from 'features/auth/routes';
import { charitiesRoutes } from 'features/charities/routes';
import { dashboardRoutes } from 'features/dashboard/routes';
import { donationsMerchantRoutes, donationsAdminRoutes } from 'features/donations/routes';
import { donatorsRoutes } from 'features/donators/routes';
import { kiosksRoutes } from 'features/kiosk/routes';
import { merchantRoutes, merchantAdminRoutes } from 'features/merchant/routes';
import { paymentRoutes } from 'features/payment/routes';
import { siteRoutes } from 'features/site/routes';
import { userRoutes } from 'features/user/routes';
import { Loading } from 'components';
import { UserRole } from 'models/enums';

const RegisterPage = withSuspense(lazy(() => import('features/user/pages/RegisterPage/RegisterPage')));
const PageNotFound = withSuspense(lazy(() => import('components/PageNotFound/PageNotFound')));
const QRCode = withSuspense(lazy(() => import('components/QRCode/QRCode')));

const routerNotAuthenticated: RouteObject[] = [
  { path: '/', element: <Navigate to="/login" replace /> },
  ...authRoutes,
  { path: 'register', element: <RegisterPage /> },
  { path: 'qr/:slug', element: <QRCode /> },
  { path: '*', element: <Navigate to="/login" replace /> },
];

const routerMerchant: RouteObject[] = [
  { path: '/', element: <Navigate to="/dashboard" replace /> },
  { path: '/login', element: <Navigate to="/dashboard" replace /> },
  { path: '/register', element: <Navigate to="/dashboard" replace /> },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      ...charitiesRoutes,
      ...dashboardRoutes,
      ...donationsMerchantRoutes,
      ...donatorsRoutes,
      ...kiosksRoutes,
      ...paymentRoutes,
      ...siteRoutes,
      ...userRoutes,
    ],
  },
  ...merchantRoutes,
  { path: 'qr/:slug', element: <QRCode /> },
  { path: '*', element: <PageNotFound /> },
];

const routerAdmin: RouteObject[] = [
  { path: '/', element: <Navigate to="/donations" replace /> },
  { path: '/login', element: <Navigate to="/donations" replace /> },
  { path: '/register', element: <Navigate to="/donations" replace /> },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      ...donationsAdminRoutes,
      ...merchantAdminRoutes,
    ],
  },
  { path: 'qr/:slug', element: <QRCode /> },
  { path: '*', element: <PageNotFound /> },
];

const RootRouter = () => {
  const routesNotAuthenticated = useRoutes(routerNotAuthenticated);
  const routesMerchant = useRoutes(routerMerchant);
  const routesAdmin = useRoutes(routerAdmin);

  const dispatch = useAppDispatch();
  const isAuth = useAppSelector(selectIsAuthenticated);
  const { user } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (isAuth) {
      dispatch(getUser());
    }
  }, [dispatch, isAuth]);

  if (isAuth) {
    // Get merchant routes.
    if (user?.role === UserRole.Merchant) {
      return routesMerchant;
    }

    // Get admin routes.
    if (user?.role === UserRole.Admin) {
      return routesAdmin;
    }

    return <Loading />;
  }

  return routesNotAuthenticated;
};

export default RootRouter;
