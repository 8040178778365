import React, { memo } from 'react';
import clsx from 'clsx';
import { Launch as LaunchIcon } from '@material-ui/icons';

import styles from './CharityLink.module.css';

interface CharityLinkProps {
  /**
   * Charity URL.
   */
  url: string;
  /**
   * Charity URL name.
   */
  name: string;
  /**
   * Element class.
   */
  className?: string;
}

const CharityLinkComponent = ({ url, name, className }: CharityLinkProps): JSX.Element => (
  <a
    href={url}
    target="_blank"
    rel="noreferrer noopener"
    className={clsx(styles.host, className)}
  >
    <span className={styles.text}>{name}</span>
    <LaunchIcon className={styles.icon} />
  </a>
);

CharityLinkComponent.defaultProps = {
  className: '',
};

export const CharityLink = memo(CharityLinkComponent);
