import { PaymentMethod } from 'models/enums';
import DonationType from 'models/DonationType';

/**
 *  All transaction model class.
 */
export default class AllTransaction {
  /**
   * Unique Id.
   */
  public readonly id: number;

  /**
   * Amount.
   */
  public readonly amount: number;

  /**
   * Fee.
   */
  public readonly fee: number;

  /**
   * First name.
   */
  public readonly firstName: string;

  /**
   * Last name.
   */
  public readonly lastName: string;

  /**
   * Donator name.
   */
  public readonly donator: string;

  /**
   * Receipt.
   */
  public readonly receipt: string;

  /**
   * Payment method.
   */
  public readonly paymentMethod: PaymentMethod;

  /**
   * Donation type.
   */
  public readonly donationType: DonationType;

  /**
   * Date of create.
   */
  public readonly createdAt: Date;

  /**
   * Last 4 numbers of a card number(account).
   */
  public readonly last4: string;

  /**
   * Merchant Id.
   */
  public readonly merchantId: number;

  /**
   * Name.
   */
  public readonly name: string;

  /**
   * Organization name.
   */
  public readonly organizationName: string;

  /**
   * Transaction id.
   */
  public readonly transactionId: string;

  /**
   * Recurring payment id.
   */
  public readonly reoccurringPaymentId: number | null;

  constructor(data: AllTransaction) {
    this.id = data.id;
    this.amount = data.amount;
    this.fee = data.fee;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.donator = data.donator;
    this.receipt = data.receipt;
    this.paymentMethod = data.paymentMethod;
    this.donationType = data.donationType;
    this.createdAt = data.createdAt;
    this.last4 = data.last4;
    this.name = data.name;
    this.merchantId = data.merchantId;
    this.organizationName = data.organizationName;
    this.transactionId = data.transactionId;
    this.reoccurringPaymentId = data.reoccurringPaymentId;
  }
}
