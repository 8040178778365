import Organization, { PlanningCenter } from 'models/Organization';
import { PlanningCenterDto, OrganizationDto } from 'api/dtos/organizationDto';
import { mapDtoToOrganizationImage } from 'api/mappers/organizationImagesMapper';
import OrganizationImagesDto from 'api/dtos/organizationImagesDto';
import { mapDtoToBrandingColor } from 'api/mappers/brandingColorMapper';
import BrandingColorDto from 'api/dtos/brandingColorDto';

/**
 * Map DTO to Organization model.
 */
export const mapDtoToOrganization = (dto: OrganizationDto): Organization => new Organization({
  id: dto?.id,
  name: dto.name,
  address: dto.address,
  city: dto.city,
  state: dto.state,
  zip: dto.zip,
  fee: dto.fee,
  banner: dto?.banner ? mapDtoToOrganizationImage(dto?.banner as OrganizationImagesDto) : null,
  brandingColor: dto?.branding_color ? mapDtoToBrandingColor(dto?.branding_color as BrandingColorDto) : null,
  logoUrl: dto.logo_url,
  slug: dto.slug,
  showLogo: dto.show_logo,
  showDonation: dto.show_donation,
  showBanner: dto.show_banner,
  showFee: dto.show_fee,
  showSite: dto.show_site,
  isDisabled: dto.is_disabled,
  cardpointeMerchantId: dto.cardpointe_merchant_id,
  cloverMerchantId: dto.clover_merchant_id,
  donationEmailSignature: dto.donation_email_signature,
  achMerchantId: dto.ach_merchant_id,
  kioskFee: dto.kiosk_fee,
  tackonPhone: dto.tackon_phone,
  tackonBearerToken: dto.tackon_bearer_token,
  planningApplicationId: dto.planning_application_id,
  planningSecret: dto.planning_secret,
  planningOn: dto.planning_on,
});

/**
 * Map Organization info model to DTO.
 */
export const mapOrganizationToDto = (payload: Organization): OrganizationDto => ({
  id: payload.id,
  name: payload.name,
  address: payload.address,
  city: payload.city,
  state: payload.state,
  zip: payload.zip,
  fee: payload.fee,
  banner: payload.banner as number,
  branding_color: payload?.brandingColor,
  logo_url: payload.logoUrl,
  slug: payload.slug,
  show_logo: payload.showLogo,
  show_donation: payload.showDonation,
  show_fee: payload.showFee,
  show_site: payload.showSite,
  show_banner: payload.showBanner,
  is_disabled: payload.isDisabled,
  cardpointe_merchant_id: payload.cardpointeMerchantId,
  clover_merchant_id: payload.cloverMerchantId,
  donation_email_signature: payload.donationEmailSignature,
  ach_merchant_id: payload.achMerchantId,
  kiosk_fee: payload.kioskFee,
  tackon_phone: payload?.tackonPhone,
  tackon_bearer_token: payload?.tackonBearerToken,
  planning_application_id: payload.planningApplicationId,
  planning_secret: payload.planningSecret,
  planning_on: payload.planningOn,
});

/**
 * Map Planning center model to DTO.
 */
export const mapPlanningCenterToDto = (payload: PlanningCenter): PlanningCenterDto => ({
  planning_application_id: payload.planningApplicationId,
  planning_secret: payload.planningSecret,
});
