import React, { memo } from 'react';
import { List, ListItem, ListItemText } from '@material-ui/core';

import { MenuLink } from './MenuLink';
import styles from './AppMenu.module.css';

const AppAdminMenuComponent = (): JSX.Element => (
  <List disablePadding className={styles.menu}>
    <ListItem button component={MenuLink} className={styles.navItem} to="/donations">
      <ListItemText primary="Donations" className={styles.link} />
    </ListItem>
    <ListItem button component={MenuLink} className={styles.navItem} to="/applications">
      <ListItemText primary="Applications" className={styles.link} />
    </ListItem>
    <ListItem button component={MenuLink} className={styles.navItem} to="/merchants">
      <ListItemText primary="Merchants" className={styles.link} />
    </ListItem>
  </List>
);
export const AppAdminMenu = memo(AppAdminMenuComponent);
