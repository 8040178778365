import Auth, { ConfirmResetPassword, Token } from 'models/Auth';
import { AuthDto, LoginDto, ConfirmResetPasswordDto } from 'api/dtos/authDto';

/**
 * Map Auth model to DTO.
 */
export const mapAuthToDto = (payload: Auth): AuthDto => ({
  email: payload.email,
  password: payload.password,
});

/**
 * Map Auth model.
 */
export const mapDtoToAuth = (payload: Token): LoginDto => ({
  token: payload.token,
});

/**
 * Map Confirm Reset Password model.
 */
export const mapConfirmResetPasswordToDto = (payload: ConfirmResetPassword): ConfirmResetPasswordDto => ({
  token: payload.token,
  password: payload.password,
  password_confirmation: payload.passwordConfirmation,
  email: payload.email,
});
