import User from 'models/User';

interface UserState {
  loading: boolean;
  processing: boolean;
  user: User | null;
  error: string | null;
}

export const state: UserState = {
  loading: false,
  processing: false,
  user: null,
  error: null,
};
