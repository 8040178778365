import React, { memo, MouseEvent } from 'react';
import {
  Box,
  DialogProps,
  Dialog,
  DialogContent,
  Typography,
} from '@material-ui/core';

import { BaseButton } from 'components';
import styles from './SiteNotPublishedModal.module.css';

const SiteNotPublishedModalComponent = ({ open, onClose, ...props }: DialogProps): JSX.Element => {
  const handleClosePrevent = (event: object, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (onClose) {
      if (reason !== 'backdropClick') {
        onClose(event, reason);
      }
    }
  };

  const handleClose = (event: MouseEvent<HTMLButtonElement>) => {
    // onClose is optional props. Need to pass event and reason 'escapeKeyDown' for closing by Esc.
    if (onClose) {
      onClose(event, 'escapeKeyDown');
    }
  };

  return (
    <Dialog
      {...props}
      open={open}
      aria-labelledby="alert-dialog-title"
      onClose={(event, reason) => handleClosePrevent(event, reason)}
      disableEscapeKeyDown
    >
      <DialogContent>
        <Box textAlign="center" padding="48px 24px">
          <Typography variant="h4">Site cannot be published live yet</Typography>
          <Typography variant="body2" className={styles.text}>
            Your merchant account application has to be approved in order to go live.
            Please contact the administrator for details.
          </Typography>
          <BaseButton
            color="primary"
            variant="contained"
            className={styles.button}
            onClick={handleClose}
          >
            Ok
          </BaseButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export const SiteNotPublishedModal = memo(SiteNotPublishedModalComponent);
