import ManualTransaction from 'models/ManualTransaction';
import ManualTransactionDto from 'api/dtos/manualTransactionDto';

/**
 * Map Manual transaction model to DTO.
 */
export const mapManualTransactionToDto = (payload: ManualTransaction): ManualTransactionDto => ({
  last_name: payload.lastName,
  first_name: payload.firstName,
  email: payload.email,
  phone: payload.phone,
  donate_as: payload.donateAs,
  amount: payload.amount,
  created_at: payload.createdAt.toString(),
  charity_id: payload.charityId,
  user_id: payload.userId,
  comment: payload.comment,
});
