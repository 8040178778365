import { KioskStatus } from 'models/enums';

/**
 * Get kiosk status by code.
 */
export const getKioskStatus = (status: KioskStatus | string): string => {
  switch (status) {
    case KioskStatus.Active: {
      return 'Active';
    }

    case KioskStatus.Unregistered: {
      return 'Unregistered';
    }

    case KioskStatus.UnregisteredPending: {
      return 'Unregistered Pending';
    }

    case KioskStatus.RequestPending: {
      return 'Request Pending';
    }

    default: {
      return status;
    }
  }
};
