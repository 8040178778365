import React, { ChangeEvent, memo, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { FormLabel } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import {
  BaseButton, BaseSwitch, QRCodeIcon, CopyIcon, CharityLink,
} from 'components';
import { update } from 'store/User';
import User from 'models/User';
import { ERROR, SUCCESS } from 'components/AppSnackbar';
import transformAPIErrors from 'utils/transformAPIErrors';
import { useAppDispatch, useAppSelector } from 'store/rootReducer';

import { SiteNotPublishedModal } from '../SiteNotPublishedModal/SiteNotPublishedModal';
import styles from './AppStatusBar.module.css';

export interface SiteForm {
  readonly showSite: boolean;
}

const AppStatusBarComponent = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const snackbar = useSnackbar();

  const { user, processing } = useAppSelector((state) => state.user);
  const charities = useAppSelector((state) => state.organization.charities.data);
  const [status, setStatus] = useState(user?.organization?.showSite);
  const [isOpen, setOpen] = useState(false);
  const label = status ? 'Live' : 'Not live';

  const updateSiteNotPublishedModalState = (isOpen: boolean) => () => {
    setOpen(isOpen);
  };

  const initialFormState: SiteForm = {
    showSite: user?.organization?.showSite || false,
  };

  const isDisabledOrganization = user?.organization?.isDisabled;

  const handleChangeStatus = async (event: ChangeEvent<HTMLInputElement>) => {
    const isDisabledStatus = (!user?.organization?.cardpointeMerchantId || charities?.results?.length === 0);

    if (!status && isDisabledStatus) {
      setStatus(false);
      updateSiteNotPublishedModalState(true)();

      return;
    }

    const { name, checked } = event.target;
    try {
      const data = {
        organization: {
          ...formik.values,
          [name]: checked,
        },
      };

      await dispatch(update(data as User));

      const siteStatus = checked ? 'published' : 'unpublished';

      setStatus(checked);
      snackbar.enqueueSnackbar(`Site has been ${siteStatus} successfully`, SUCCESS);
    } catch (error) {
      const serverErrors = transformAPIErrors(error as Error);

      snackbar.enqueueSnackbar(serverErrors.message, ERROR);
    }
  };

  const formik = useFormik({
    initialValues: initialFormState,
    onSubmit: () => {},
    enableReinitialize: true,
  });

  const handleCopyToClipboard = async (): Promise<void> => {
    try {
      if (user?.organization?.charityExternalUrl) {
        await navigator.clipboard.writeText(user?.organization?.charityExternalUrl);

        snackbar.enqueueSnackbar('The link has been copied successfully', SUCCESS);
      }
    } catch (error) {
      snackbar.enqueueSnackbar('Something went wrong', ERROR);
    }
  };

  const handleOpenQr = () => {
    const baseUrl = `${window.location.protocol}//${window.location.host}`;

    window.open(`${baseUrl}/qr/${user?.organization?.slug}`, '_blank');
  };

  return (
    <div className={styles.host}>
      <FormikProvider value={formik}>
        <div className={styles.formStatus}>
          <FormLabel className={styles.label}>Site Status</FormLabel>
          <BaseSwitch
            id="showSite"
            name="showSite"
            label={label}
            disabled={processing || isDisabledOrganization}
            checked={status}
            onChange={handleChangeStatus}
          />
        </div>
      </FormikProvider>
      <CharityLink
        url={user?.organization?.charityExternalUrl || ''}
        name={user?.organization?.charityExternalUrlText || ''}
        className={styles.externalLinkMui}
      />
      <div className={styles.buttons}>
        <BaseButton
          variant="outlined"
          size="small"
          className={styles.button}
          startIcon={<QRCodeIcon className={styles.icon} />}
          onClick={handleOpenQr}
        >
          QR Code
        </BaseButton>
        <BaseButton
          variant="outlined"
          size="small"
          className={styles.button}
          startIcon={<CopyIcon className={styles.icon} />}
          onClick={handleCopyToClipboard}
        >
          Copy Link
        </BaseButton>
      </div>
      <SiteNotPublishedModal open={isOpen} onClose={updateSiteNotPublishedModalState(false)} />
    </div>
  );
};

export const AppStatusBar = memo(AppStatusBarComponent);
