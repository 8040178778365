import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

interface PersonIconProps extends SvgIconProps {
  /**
   * Svg icon.
   */
  fill?: string;
}

/* eslint-disable max-len */
export const PersonIcon = ({ fill, ...props }: PersonIconProps): JSX.Element => (
  <SvgIcon {...props} viewBox="0 0 56 56">
    <g id="Element-Sheet" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-280.000000, -528.000000)" id="Icons/Stats/PersonIcon">
        <g transform="translate(280.000000, 528.000000)">
          <circle id="Oval" fill={fill} cx="28" cy="28" r="28" />
          <path d="M28.0001606,28.7354992 C35.4531571,28.7354992 41.6254051,34.7418398 41.9982009,42.336861 C42.0186729,42.7701271 41.8637147,43.1914933 41.5698613,43.5056534 C41.2774901,43.8198218 40.8710447,43.9984927 40.4456243,43.9999908 L40.4456243,43.9999908 L15.5674965,43.9999908 C15.1391569,44.0014681 14.7298147,43.8242738 14.4345018,43.510113 C14.1377442,43.1959606 13.9813151,42.7716136 14.0017834,42.336861 C14.3730484,34.7506065 20.5322311,28.7489008 27.9751226,28.7354992 L27.9751226,28.7354992 Z M27.9998237,12 C31.9456379,12 35.1736229,15.2917095 35.1736229,19.3148676 C35.1736229,23.3395122 31.9456379,26.6419323 27.9998237,26.6419323 C24.0524377,26.6419323 20.8361295,23.3380257 20.8361295,19.3148676 C20.8361295,15.2919763 24.0540096,12 27.9998237,12 Z" id="Icons/Stats/PersonIcon-Shape" fill="#FFFFFF" />
        </g>
      </g>
    </g>
  </SvgIcon>
);

PersonIcon.defaultProps = {
  fill: '#0BB7B7',
};
