interface UtilsState {
  upload: {
    error: string | null;
  };
  planningCenter: {
    processing: boolean;
    data: unknown | null;
    error: string | null;
  };
}

export const state: UtilsState = {
  upload: {
    error: null,
  },
  planningCenter: {
    processing: false,
    data: null,
    error: null,
  },
};
