import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import Charity from 'models/Charity';
import ApiError from 'models/ApiError';
import OrganizationImage from 'models/OrganizationImage';
import {
  getOrganizationImages, getCharities, getAllCharities, createCharity, updateCharity, getTransactions, getTransaction,
  voidTransaction, refundTransaction, deleteTransaction, getCharityStats, getReoccurringTransactions,
  addCheckTransaction, addCashTransaction, getQR, deleteOrganizationImages,
} from 'store/Organization';
import PagedList from 'models/PagedList';
import Filter from 'models/Filter';
import OrganizationTransaction from 'models/OrganizationTransaction';

import { state } from './state';

const organizationSlice = createSlice({
  name: 'organization',
  initialState: state,
  reducers: {
    setFilter(state, { payload }: PayloadAction<Filter<any> | null>) {
      state.transactions.filter = payload;
    },
    setArchivedCharityStart(state) {
      state.charities.updating = true;
      state.charities.error = null;
    },
    setArchivedCharitySuccess(state) {
      state.charities.updating = false;
    },
    setArchivedCharityFailed(state, { payload }: PayloadAction<ApiError>) {
      state.charities.error = payload.message;
      state.charities.updating = false;
    },
    setRestoredCharityStart(state) {
      state.charities.updating = true;
      state.charities.error = null;
    },
    setRestoredCharitySuccess(state, { payload }: PayloadAction<Charity>) {
      const data = state.charities.data?.results.map((charity) => {
        if (charity.id === payload.id) {
          return payload;
        }

        return charity;
      });

      state.charities!.data!.results = data || state.charities.data!.results;
      state.charities.updating = false;
    },
    setRestoredCharityFailed(state, { payload }: PayloadAction<ApiError>) {
      state.charities.error = payload.message;
      state.charities.updating = false;
    },
  },
  extraReducers(builder) {
    builder.addCase(createCharity.pending, (state) => {
      state.charities.processing = true;
      state.charities.error = null;
    });
    builder.addCase(createCharity.fulfilled, (state) => {
      state.charities.processing = false;
    });
    builder.addCase(createCharity.rejected, (state, { error }) => {
      state.charities.error = error.message ?? '';
      state.charities.processing = false;
    });
    builder.addCase(updateCharity.pending, (state) => {
      state.charities.processing = true;
      state.charities.error = null;
    });
    builder.addCase(updateCharity.fulfilled, (state) => {
      state.charities.processing = false;
    });
    builder.addCase(updateCharity.rejected, (state, { error }) => {
      state.charities.error = error.message ?? '';
      state.charities.processing = false;
    });
    builder.addCase(getCharities.pending, (state) => {
      state.charities.loading = true;
      state.charities.error = null;
    });
    builder.addCase(getCharities.fulfilled, (state, { payload }: PayloadAction<PagedList<Charity>>) => {
      state.charities.data = payload;
      state.charities.loading = false;
    });
    builder.addCase(getCharities.rejected, (state, { error }) => {
      state.charities.error = error.message ?? '';
      state.charities.loading = false;
    });
    builder.addCase(getAllCharities.pending, (state) => {
      state.charities.loading = true;
      state.charities.error = null;
    });
    builder.addCase(getAllCharities.fulfilled, (state, { payload }: PayloadAction<PagedList<Charity>>) => {
      state.charities.data = payload;
      state.charities.loading = false;
    });
    builder.addCase(getAllCharities.rejected, (state, { error }) => {
      state.charities.error = error.message ?? '';
      state.charities.loading = false;
    });
    builder.addCase(getOrganizationImages.pending, (state) => {
      state.images.loading = true;
      state.images.error = null;
    });
    builder.addCase(getOrganizationImages.fulfilled, (state, { payload }:
        PayloadAction<PagedList<OrganizationImage>>) => {
      state.images.data = payload;
      state.images.loading = false;
    });
    builder.addCase(getOrganizationImages.rejected, (state, { error }) => {
      state.images.error = error.message ?? '';
      state.images.loading = false;
    });
    builder.addCase(deleteOrganizationImages.pending, (state) => {
      state.images.processing = true;
      state.images.error = null;
    });
    builder.addCase(deleteOrganizationImages.fulfilled, (state) => {
      state.images.processing = false;
    });
    builder.addCase(deleteOrganizationImages.rejected, (state, { error }) => {
      state.images.error = error.message ?? '';
      state.images.processing = false;
    });
    builder.addCase(getTransactions.pending, (state, { meta }) => {
      state.transactions.loading = true;
      state.transactions.error = null;
      state.transactions.currentRequestId = meta.requestId;
    });
    builder.addCase(getTransactions.fulfilled, (state, { payload, meta }) => {
      if (state.transactions.loading && state.transactions.currentRequestId === meta.requestId) {
        state.transactions.data = payload;
        state.transactions.loading = false;
        state.transactions.currentRequestId = null;
      }
    });
    builder.addCase(getTransactions.rejected, (state, { error, meta }) => {
      if (state.transactions.loading && state.transactions.currentRequestId === meta.requestId) {
        state.transactions.error = error.message ?? '';
        state.transactions.loading = false;
        state.transactions.currentRequestId = null;
      }
    });
    builder.addCase(getTransaction.pending, (state) => {
      state.transactions.loading = true;
      state.transactions.error = null;
    });
    builder.addCase(getTransaction.fulfilled, (state, { payload }: PayloadAction<OrganizationTransaction>) => {
      state.transactions.details = payload;
      state.transactions.loading = false;
    });
    builder.addCase(getTransaction.rejected, (state, { error }) => {
      state.transactions.error = error.message ?? '';
      state.transactions.loading = false;
    });
    builder.addCase(getReoccurringTransactions.pending, (state, { meta }) => {
      state.reoccurringTransactions.loading = true;
      state.reoccurringTransactions.error = null;
      state.reoccurringTransactions.currentRequestId = meta.requestId;
    });
    builder.addCase(getReoccurringTransactions.fulfilled, (state, { payload, meta }) => {
      if (state.reoccurringTransactions.loading && state.reoccurringTransactions.currentRequestId === meta.requestId) {
        state.reoccurringTransactions.data = payload;
        state.reoccurringTransactions.loading = false;
        state.reoccurringTransactions.currentRequestId = null;
      }
    });
    builder.addCase(getReoccurringTransactions.rejected, (state, { error, meta }) => {
      if (state.reoccurringTransactions.loading && state.reoccurringTransactions.currentRequestId === meta.requestId) {
        state.reoccurringTransactions.error = error.message ?? '';
        state.reoccurringTransactions.loading = false;
        state.reoccurringTransactions.currentRequestId = null;
      }
    });
    builder.addCase(getCharityStats.pending, (state, { meta }) => {
      state.charityStats.loading = true;
      state.charityStats.error = null;
      state.charityStats.currentRequestId = meta.requestId;
    });
    builder.addCase(getCharityStats.fulfilled, (state, { payload, meta }) => {
      if (state.charityStats.loading && state.charityStats.currentRequestId === meta.requestId) {
        state.charityStats.data = payload;
        state.charityStats.loading = false;
        state.charityStats.currentRequestId = null;
      }
    });
    builder.addCase(getCharityStats.rejected, (state, { error, meta }) => {
      if (state.charityStats.loading && state.charityStats.currentRequestId === meta.requestId) {
        state.charityStats.error = error.message ?? '';
        state.charityStats.loading = false;
        state.charityStats.currentRequestId = null;
      }
    });
    builder.addCase(voidTransaction.pending, (state) => {
      state.transactions.processing = true;
      state.transactions.error = null;
    });
    builder.addCase(voidTransaction.fulfilled, (state, { payload }) => {
      state.transactions.details = payload;
      state.transactions.processing = false;
    });
    builder.addCase(voidTransaction.rejected, (state, { error }) => {
      state.transactions.error = error.message ?? '';
      state.transactions.processing = false;
    });
    builder.addCase(refundTransaction.pending, (state) => {
      state.transactions.processing = true;
      state.transactions.error = null;
    });
    builder.addCase(refundTransaction.fulfilled, (state, { payload }) => {
      state.transactions.details = payload;
      state.transactions.processing = false;
    });
    builder.addCase(refundTransaction.rejected, (state, { error }) => {
      state.transactions.error = error.message ?? '';
      state.transactions.processing = false;
    });
    builder.addCase(deleteTransaction.pending, (state) => {
      state.transactions.processing = true;
      state.transactions.error = null;
    });
    builder.addCase(deleteTransaction.fulfilled, (state, { payload }) => {
      state.transactions.details = payload;
      state.transactions.processing = false;
    });
    builder.addCase(deleteTransaction.rejected, (state, { error }) => {
      state.transactions.error = error.message ?? '';
      state.transactions.processing = false;
    });
    builder.addCase(addCashTransaction.pending, (state) => {
      state.transactions.processing = true;
      state.transactions.error = null;
    });
    builder.addCase(addCashTransaction.fulfilled, (state) => {
      state.transactions.processing = false;
    });
    builder.addCase(addCashTransaction.rejected, (state, { error }) => {
      state.transactions.error = error.message ?? '';
      state.transactions.processing = false;
    });
    builder.addCase(addCheckTransaction.pending, (state) => {
      state.transactions.processing = true;
      state.transactions.error = null;
    });
    builder.addCase(addCheckTransaction.fulfilled, (state) => {
      state.transactions.processing = false;
    });
    builder.addCase(addCheckTransaction.rejected, (state, { error }) => {
      state.transactions.error = error.message ?? '';
      state.transactions.processing = false;
    });
    builder.addCase(getQR.pending, (state) => {
      state.qr.loading = true;
      state.qr.error = null;
    });
    builder.addCase(getQR.fulfilled, (state, { payload }: PayloadAction<string>) => {
      state.qr.data = payload;
      state.qr.loading = false;
    });
    builder.addCase(getQR.rejected, (state, { error }) => {
      state.qr.error = error.message ?? '';
      state.qr.loading = false;
    });
  },
});

export const {
  setArchivedCharityStart,
  setArchivedCharitySuccess,
  setArchivedCharityFailed,
  setRestoredCharityStart,
  setRestoredCharitySuccess,
  setRestoredCharityFailed,
  setFilter,
} = organizationSlice.actions;

export const organization = organizationSlice.reducer;
