import CharityTransaction from 'models/CharityTransaction';
import PagedList from 'models/PagedList';
import Filter from 'models/Filter';

interface CharityState {
  loading: boolean;
  data: PagedList<CharityTransaction> | null;
  filter: Filter<Record<string, unknown>> | null;
  error: string | null;
  currentRequestId: string | null;
}

export const state: CharityState = {
  loading: false,
  data: null,
  filter: null,
  error: null,
  currentRequestId: null,
};
