import React, { memo } from 'react';
import { List, ListItem, ListItemText } from '@material-ui/core';

import { MenuLink } from './MenuLink';
import styles from './AppMenu.module.css';

const AppMerchantMenuComponent = (): JSX.Element => (
  <List disablePadding className={styles.menu}>
    <ListItem button component={MenuLink} className={styles.navItem} to="/dashboard">
      <ListItemText primary="Dashboard" className={styles.link} />
    </ListItem>
    <ListItem button component={MenuLink} className={styles.navItem} to="/donations">
      <ListItemText primary="Donations" className={styles.link} />
    </ListItem>
    <ListItem button component={MenuLink} className={styles.navItem} to="/charities">
      <ListItemText primary="Giving Categories" className={styles.link} />
    </ListItem>
    <ListItem button component={MenuLink} className={styles.navItem} to="/donators">
      <ListItemText primary="Donators" className={styles.link} />
    </ListItem>
    <ListItem button component={MenuLink} className={styles.navItem} to="/site-editor">
      <ListItemText primary="Site Editor" className={styles.link} />
    </ListItem>
    {/* TODO: temporary removed Manage Kiosks */}
    {/* <ListItem button component={MenuLink} className={styles.navItem} to="/manage-kiosks"> */}
    {/*  <ListItemText primary="Manage Kiosks" className={styles.link} /> */}
    {/* </ListItem> */}
    <ListItem button component={MenuLink} className={styles.navItem} to="/payment-settings">
      <ListItemText primary="Payment Settings" className={styles.link} />
    </ListItem>
    <ListItem button component={MenuLink} className={styles.navItem} to="/account-settings">
      <ListItemText primary="Account Settings" className={styles.link} />
    </ListItem>
  </List>
);

export const AppMerchantMenu = memo(AppMerchantMenuComponent);
