import { ChangeEvent } from 'react';
import debounce from 'lodash/debounce';

/**
 * Delays search.
 */
export const debouncedSearch = debounce((event: ChangeEvent<HTMLInputElement>, onSearch: (text: string) => void) => {
  const { value } = event.target;

  onSearch(value);
}, 300);
