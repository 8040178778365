import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

/* eslint-disable max-len */
export const NumberDonationsIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props} viewBox="0 0 56 56">
    <g id="Element-Sheet" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-120.000000, -528.000000)" id="Icons/Stats/NumberDonations">
        <g transform="translate(120.000000, 528.000000)">
          <circle id="Oval" fill="#2083a5" cx="28" cy="28" r="28" />
          <path d="M29.1429031,31.4615662 C29.7743484,31.4615662 30.2857657,31.9778972 30.2857657,32.6154095 C30.2857657,34.5264693 28.7500509,36.0769393 26.8571778,36.0769393 L26.8571778,36.0769393 L33.1544424,36.0769393 L40.5729924,31.7687654 C41.6658617,31.1327301 43.064433,31.5134799 43.6958783,32.6182894 C44.3243613,33.7230621 43.947235,35.1336309 42.855792,35.7697031 L42.855792,35.7697031 L32.8143265,41.6009501 C31.6299551,42.5038289 30.1699435,43 28.6813695,43 L28.6813695,43 L18.8571393,43 L18.8571393,31.4615662 Z M16.5714506,31.4614923 L16.5714506,42.999925 L12,42.999925 L12,31.4614923 L16.5714506,31.4614923 Z M32.1143826,13 C34.9301035,13 37.1429782,15.234136 37.1429782,18.0769104 C37.1429782,21.5657632 32.7086711,24.8341858 28.000077,29.1538058 C23.2914829,24.8426781 18.8571759,21.5658371 18.8571759,18.0769104 C18.8571759,15.234136 21.0700505,13 23.8857715,13 C25.4772215,13 27.0029157,13.7471366 28.000077,14.9298167 C28.9972384,13.7470997 30.5229326,13 32.1143826,13 Z" id="Icons/Stats/NumberDonations-Shape" fill="#FFFFFF" />
        </g>
      </g>
    </g>
  </SvgIcon>
);
