import { decamelize } from 'humps';

import QueryParams from 'models/QueryParams';
import QueryParamsDto from 'api/dtos/queryParamsDto';

/**
 * Map User Query params to DTO.
 */
export const mapQueryParamsToDto = (payload: QueryParams | Record<string, any>): QueryParamsDto => {
  const {
    perPage, orderBy, sortOrder, ...rest
  } = payload;

  const filterParams = Object.keys(rest).reduce((acc, key: string) => ({
    ...acc,
    // TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type.
    // @ts-ignore
    [decamelize(key)]: rest[key],
  }), {});

  return {
    search: payload.search,
    page: payload.page,
    per_page: perPage,
    order_by: orderBy ? decamelize(orderBy) : orderBy,
    sort_order: sortOrder,
    ...filterParams,
  };
};
