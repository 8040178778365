import React, { memo } from 'react';
import { Typography, Breadcrumbs, BreadcrumbsProps } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';

import styles from './AppBreadcrumbs.module.css';

interface AppBreadcrumbsProps extends BreadcrumbsProps{
  /**
   * Object with key - path, value - text.
   *
   * @example { '/charities': 'Charities', '/charities/add': 'Add New Category'  }
   */
  pathMap: Record<string, string>;
}

const AppBreadcrumbsComponent = ({ className, pathMap }: AppBreadcrumbsProps): JSX.Element => {
  const location = useLocation();
  const pathsList = location.pathname.split('/').filter((path) => Boolean(path));

  return (
    <Breadcrumbs aria-label="breadcrumb" className={clsx(styles.host, className)}>
      {pathsList.map((value, index) => {
        const last = index === pathsList.length - 1;
        const to = `/${pathsList.slice(0, index + 1).join('/')}`;

        return last
          ? <Typography color="textPrimary" key={to} className={styles.text}>{pathMap[to]}</Typography>
          : <Link to={to} key={to} className={styles.link}>{pathMap[to]}</Link>;
      })}
    </Breadcrumbs>
  );
};

const AppBreadcrumbsPlaceholder = (): JSX.Element => (
  <div className={styles.placeholder} />
);

export { AppBreadcrumbsPlaceholder };

export const AppBreadcrumbs = memo(AppBreadcrumbsComponent);
