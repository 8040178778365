import React, { memo, useState } from 'react';
import { ButtonProps } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { BaseButton } from 'components';
import QueryParams from 'models/QueryParams';
import { ERROR } from 'components/AppSnackbar';
import { useAppDispatch } from 'store/rootReducer';
import { exportToFile } from 'store/Utils';

interface CharitiesToolbarProps extends ButtonProps {
  /**
   * Endpoint path.
   */
  endpointPath: string;

   /**
   * Query params.
   */
  queryParams: QueryParams | Record<string, unknown>;
}

const ExportButtonComponent = ({ endpointPath, queryParams, ...rest }: CharitiesToolbarProps): JSX.Element => {
  const snackbar = useSnackbar();
  const dispatch = useAppDispatch();

  const [processing, setProcessing] = useState(false);

  const handleExport = async () => {
    try {
      setProcessing(true);
      await dispatch(exportToFile({ queryParams, endpointPath })).unwrap();
    } catch (error) {
      snackbar.enqueueSnackbar('Something went wrong', ERROR);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <BaseButton
      {...rest}
      color="primary"
      variant="contained"
      type="button"
      onClick={handleExport}
      loading={processing}
    >
      Export
    </BaseButton>
  );
};

export const ExportButton = memo(ExportButtonComponent);
