/**
 *  Dashboard model class.
 */
export default class Dashboard {
  /**
   * Total amount.
   */
  public readonly totalAmount: number;

  /**
   * Total fee.
   */
  public readonly totalFee: number;

  /**
   * Number of donations.
   */
  public readonly numberOfDonations: number;

  /**
   * Total donors.
   */
  public readonly totalDonors: number;

  /**
   * New donors.
   */
  public readonly newDonors: number;

  /**
   * Recurring donors.
   */
  public readonly reoccurringDonors: number;

  /**
   * Donation by type.
   */
  public readonly donationByType: Record<string, number>;

  constructor(data: Dashboard) {
    this.totalAmount = data.totalAmount;
    this.totalFee = data.totalFee;
    this.numberOfDonations = data.numberOfDonations;
    this.totalDonors = data.totalDonors;
    this.newDonors = data.newDonors;
    this.reoccurringDonors = data.reoccurringDonors;
    this.donationByType = data.donationByType;
  }
}
