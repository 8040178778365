import CharityStat from 'models/CharityStat';
import CharityStatDto from 'api/dtos/charityStatDto';
import PagedList from 'models/PagedList';
import ResponseDto from 'api/dtos/responseDto';
import { mapDtoToPagination } from 'api/mappers/paginationMapper';

/**
 * Map DTO to Charity stats model.
 */
export const mapDtoToCharityStat = (dto: CharityStatDto): CharityStat => new CharityStat({
  id: dto.id,
  name: dto.name,
  status: dto.status,
  donations: dto.donations,
  amount: dto.amount,
});

/**
 * Map DTO to Charity stats paged list.
 */
export function mapDtoToCharityStats(dtos: ResponseDto<CharityStatDto>): PagedList<CharityStat> {
  return new PagedList<CharityStat>({
    pagination: mapDtoToPagination(dtos.pagination),
    results: dtos.results?.map(mapDtoToCharityStat),
  });
}
