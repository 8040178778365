import React, { BaseSyntheticEvent, memo, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import {
  ChartDonut, ChartDonutProps, ChartLegend, ChartLabel, ChartThemeColor,
} from '@patternfly/react-charts';

import DashboardChart from 'models/DashboardChart';

import styles from './DonationsChart.module.css';

interface DonationsChartProps extends ChartDonutProps {
  /**
   * State of loading data.
   */
  loading?: boolean;
}

const DonationsChartComponent = ({ data, loading }: DonationsChartProps): JSX.Element => {
  const [title, setTitle] = useState('');
  const [subTitle, setSubTitle] = useState('');

  const legendData = data?.map((info) => (
    {
      name: `${info.x} (${info.y || 0} donations)`,
    }
  ));

  const total = data?.reduce((
    previousValue: number,
    currentValue: DashboardChart,
  ) => previousValue + currentValue.y, 0);

  const handleShowPercent = (event: BaseSyntheticEvent, { datum }: { datum: { x: number; y: number; } }) => {
    const percent = (datum.y * 100) / total;

    setTitle(`${percent.toFixed(2)}%`);
    setSubTitle(`${datum.x} Donations`);
  };

  const handleHidePercent = () => {
    setTitle('');
    setSubTitle('');
  };

  if (loading) {
    return (
      <div className={styles.host}>
        <CircularProgress size={120} className={styles.progressbar} />
      </div>
    );
  }

  return (
    <div className={styles.host}>
      <ChartDonut
        constrainToVisibleArea
        data={data}
        innerRadius={66}
        labels={[]}
        legendData={legendData}
        legendPosition="bottom"
        legendOrientation="vertical"
        padding={{
          bottom: 92,
        }}
        padAngle={0}
        subTitle={subTitle}
        title={title}
        height={240}
        themeColor={ChartThemeColor.cyan}
        legendComponent={(
          <ChartLegend style={{
            labels: {
              fontFamily: 'var(--font-semplicita-pro-semi-bold)', fontSize: 12, lineHeight: 0.5,
            },
          }}
          />
        )}
        titleComponent={
          <ChartLabel style={[{ fontWeight: 'bold', fontSize: 22 }, { fill: 'var(--main-color)', fontSize: 20 }]} />
        }
        subTitleComponent={
          <ChartLabel style={[{ fontSize: 12 }]} />
        }
        events={[{
          target: 'data',
          eventHandlers: {
            onMouseOver: handleShowPercent,
            onMouseLeave: handleHidePercent,
          },
        }]}
      />
    </div>
  );
};

DonationsChartComponent.defaultProps = {
  loading: false,
};

export const DonationsChart = memo(DonationsChartComponent);
