import { PaymentMethod } from 'models/enums';

/**
 * Get payment method text by payment type.
 */
export const getPaymentMethodText = (type: PaymentMethod | string): string => {
  switch (type) {
    case PaymentMethod.Card: {
      return 'Debit/Credit Card';
    }

    case PaymentMethod.Ach: {
      return 'ACH';
    }

    case PaymentMethod.Cash: {
      return 'Cash';
    }

    case PaymentMethod.Check: {
      return 'Check';
    }

    default: {
      return type;
    }
  }
};
