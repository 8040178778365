/**
 *  Donator total amount transaction model class.
 */
export default class DonatorTotalAmountTransaction {
  /**
   * Total amount.
   */
  public readonly totalAmount: number;

  constructor(data: DonatorTotalAmountTransaction) {
    this.totalAmount = data.totalAmount;
  }
}
