import React, { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import withSuspense from 'hoc/withSuspense';

const CreatePage = withSuspense(lazy(() => import('./pages/CreateMerchantPage/CreateMerchantPage')));
const AdminMerchantsPage = withSuspense(lazy(() => import('./pages/AdminMerchantsPage/AdminMerchantsPage')));
const AdminMerchantDetailsPage = withSuspense(
  lazy(() => import('./pages/AdminMerchantDetailsPage/AdminMerchantDetailsPage')),
);

export const merchantRoutes: RouteObject[] = [
  {
    path: 'merchant/create',
    element: <CreatePage />,
  },
];

export const merchantAdminRoutes: RouteObject[] = [
  {
    path: 'merchants',
    element: <AdminMerchantsPage />,
  },
  {
    path: 'merchants',
    children: [
      {
        path: ':id',
        element: <AdminMerchantDetailsPage />,
      },
    ],
  },
];
