import React, {
  memo, useState, useEffect, ChangeEvent,
} from 'react';
import { useField, useFormikContext, FormikValues } from 'formik';
import { Autocomplete, AutocompleteProps, AutocompleteRenderInputParams } from '@material-ui/lab';
import {
  LinearProgress, FormControl, FormHelperText, TextField,
} from '@material-ui/core';
import { InputProps as StandardInputProps } from '@material-ui/core/Input/Input';
import { get } from 'object-path';

import { debouncedSearch } from 'utils/debouncedSearch';

import styles from './BaseAutocomplete.module.css';

interface BaseAutocompleteProps extends Omit<AutocompleteProps<
    any,
    boolean | undefined,
    boolean | undefined,
    boolean | undefined
    >, 'renderInput'> {
  /**
   * Text label.
   */
  label: string;
  /**
   * Field name.
   */
  name: string;
  /**
   * Option key name where get value.
   */
  itemValue: string;
  /**
   * State of loading data.
   */
  loading?: boolean;
  /**
   * Props applied to the Input element.
   */
  InputProps?: Partial<StandardInputProps>;
  /**
   * Input placeholder.
   */
  placeholder?: string;
  /**
   * On search callback.
   */
  onSearch?: (text: string) => void;
}

type AutocompleteType = Record<string, string> | null;

const BaseAutocompleteComponent = ({
  label, name, options, itemValue, loading, InputProps, placeholder, onSearch, ...props
}: BaseAutocompleteProps): JSX.Element => {
  const { setFieldValue, values } = useFormikContext<FormikValues>();
  const [field, meta] = useField(name);

  const initialValueText = get(values, name);
  const findInitialValue: AutocompleteType = options.find((option) => option[itemValue] === initialValueText);
  const [value, setValue] = useState<AutocompleteType>(findInitialValue || null);

  useEffect(() => {
    if (findInitialValue) {
      setValue(findInitialValue);
    }
  }, [findInitialValue]);

  const handleChange = (event: ChangeEvent<{}>, value: AutocompleteType) => {
    setValue(value);
    if (!value) {
      setFieldValue(name, '');
    } else {
      setFieldValue(name, value[itemValue]);
    }
  };

  const configFormControl = {
    fullWidth: true,
    error: false,
  };

  let helperText: string | null = null;

  const configAutocomplete = {
    ...field,
    ...props,
    onChange: handleChange,
  };

  if (meta && meta.touched && meta.error) {
    configFormControl.error = true;
    helperText = meta.error;
  }

  const handleSearch = onSearch || (() => {});

  return (
    <FormControl {...configFormControl} className={styles.host}>
      <Autocomplete
        {...configAutocomplete}
        options={options}
        className={styles.input}
        value={value}
        onInput={(event: ChangeEvent<HTMLInputElement>) => debouncedSearch(event, handleSearch)}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <>
            <TextField
              {...params}
              placeholder={placeholder}
              InputProps={{ ...params.InputProps, ...InputProps }}
              label={label}
              className={styles.input}
              error={configFormControl.error}
            />
            {loading && <LinearProgress className={styles.progressbar} />}
          </>
        )}
      />
      <FormHelperText error>{helperText}</FormHelperText>
    </FormControl>
  );
};

BaseAutocompleteComponent.defaultProps = {
  loading: false,
  InputProps: null,
  placeholder: '',
  onSearch: () => {},
};

export const BaseAutocomplete = memo(BaseAutocompleteComponent);
