import axios, { AxiosResponse, Canceler } from 'axios';

import Kiosk from 'models/Kiosk';
import PagedList from 'models/PagedList';
import QueryParams from 'models/QueryParams';
import ResponseDto from 'api/dtos/responseDto';
import KioskDto from 'api/dtos/kioskDto';
import { mapQueryParamsToDto } from 'api/mappers/queryParamsMapper';
import { mapDtoToKiosk, mapDtoToKiosks, mapKioskToDto } from 'api/mappers/kioskMapper';

import { http } from '../index';

const { CancelToken } = axios;
let cancelRequest: Canceler;

/**
 * Fetch organization kiosks.
 */
export const fetchKiosks = async (params: QueryParams): Promise<PagedList<Kiosk>> => {
  if (cancelRequest) {
    cancelRequest();
  }

  const dtoParams = mapQueryParamsToDto(params);
  const { data } = await http.get<ResponseDto<KioskDto>>(
    '/organizations/kiosks',
    {
      params: dtoParams,
      cancelToken: new CancelToken((cancel) => {
        cancelRequest = cancel;
      }),
    },
  );

  return mapDtoToKiosks(data);
};

/**
 * Unregister a kiosk.
 */
export const unregister = async (id: number): Promise<Kiosk> => {
  const { data } = await http.put<KioskDto>(`/organizations/kiosks/${id}/unregister`);

  return mapDtoToKiosk(data);
};

/**
 * Create new kiosks.
 */
export const create = (count: number): Promise<AxiosResponse> => {
  const dto = { count };

  return http.post<AxiosResponse>('/organizations/kiosks', dto);
};

/**
 * Update kiosk.
 */
export const update = async (id: number, payload: Kiosk): Promise<Kiosk> => {
  const dto = mapKioskToDto(payload);

  const { data } = await http.put<KioskDto>(`/organizations/kiosks/${id}`, dto);

  return mapDtoToKiosk(data);
};
