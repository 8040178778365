import React, { memo } from 'react';
import { useField } from 'formik';
import clsx from 'clsx';
import { TextField, StandardTextFieldProps } from '@material-ui/core';

import { preventNonNumericValues } from 'utils/preventNonNumericValues';
import styles from './BaseInput.module.css';

interface BaseInputProps extends StandardTextFieldProps {
  /**
   * Field name.
   */
  name: string;
  /**
   * Field name.
   */
  onlyPositiveNumbers?: boolean;
  /**
   * Show input arrows.
   */
  showArrows?: boolean;
}

const BaseInputComponent = ({
  name, onlyPositiveNumbers, showArrows, ...props
}: BaseInputProps): JSX.Element => {
  const [field, meta] = useField(name);

  const configTextField = {
    ...field,
    ...props,
    fullWidth: true,
    onKeyDown: onlyPositiveNumbers ? preventNonNumericValues : () => {},
  };

  if (meta && meta.touched && meta.error) {
    configTextField.error = true;
    configTextField.helperText = meta.error;
  }

  return (
    <TextField
      {...configTextField}
      className={clsx(styles.input, { [styles.arrows]: !showArrows })}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

BaseInputComponent.defaultProps = {
  onlyPositiveNumbers: false,
  showArrows: false,
};

export const BaseInput = memo(BaseInputComponent);
