import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import User from 'models/User';
import ApiError from 'models/ApiError';
import { update } from 'store/User/dispatchers';

import { state as initialState } from './state';

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // TODO: Need to move reducers in extraReducers.
    resetUser() {
      return initialState;
    },
    setUserStart(state) {
      state.loading = true;
      state.error = null;
    },
    setUserSuccess(state, { payload }: PayloadAction<User>) {
      state.user = payload;
      state.loading = false;
    },
    setUserFailed(state, { payload }: PayloadAction<ApiError>) {
      state.error = payload.message;
      state.loading = false;
    },
    setRegisterUserStart(state) {
      state.processing = true;
      state.error = null;
    },
    setRegisterUserSuccess(state) {
      state.processing = false;
    },
    setRegisterUserFailed(state, { payload }: PayloadAction<ApiError>) {
      state.error = payload.message;
      state.processing = false;
    },
  },
  extraReducers(builder) {
    builder.addCase(update.pending, (state) => {
      state.processing = true;
      state.error = null;
    });
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<User>) => {
      state.user = payload;
      state.processing = false;
    });
    builder.addCase(update.rejected, (state, { error }) => {
      state.error = error.message ?? '';
      state.processing = false;
    });
  },
});

export const {
  resetUser,
  setUserStart,
  setUserSuccess,
  setUserFailed,
  setRegisterUserStart,
  setRegisterUserSuccess,
  setRegisterUserFailed,
} = userSlice.actions;

export const user = userSlice.reducer;
