import { createAsyncThunk, Dispatch } from '@reduxjs/toolkit';

import {
  fetchDashboard,
  fetchActiveMerchants,
  fetchInactiveMerchants,
  fetchMerchant,
  fetchAllMerchantTransaction,
  updateMerchant as updateMerchantOrganization,
  updateMerchantContacts as updateMerchantContactDetails,
} from 'api/services/Merchant';
import Step1 from 'models/Step1';
import Step2 from 'models/Step2';
import Step3 from 'models/Step3';
import Step4 from 'models/Step4';
import Step5 from 'models/Step5';
import QueryParams from 'models/QueryParams';
import Organization from 'models/Organization';

import User from 'models/User';
import {
  setStep1, setStep2, setStep3, setStep4, setStep5,
} from './merchantSlice';
import { AppThunk } from '../index';

/**
 * Get active merchants(available for Admin)
 */
export const getActiveMerchants = createAsyncThunk(
  'merchant/getActiveMerchants',
  ({ params = {} }: { params?: QueryParams }) => fetchActiveMerchants(params),
);

/**
 * Get inactive merchants(available for Admin)
 */
export const getInactiveMerchants = createAsyncThunk(
  'merchant/getInactiveMerchants',
  ({ params = {} }: { params?: QueryParams }) => fetchInactiveMerchants(params),
);

/**
 * Get merchant by id.
 */
export const getMerchant = createAsyncThunk(
  'merchant/getMerchantById',
  ({ params = {} }: { params?: Record<string, unknown> }) => fetchMerchant(params),
);

/**
 * Set merchant info for step 1.
 */
export const fillStep1 = (data: Step1): AppThunk => async (dispatch: Dispatch) => {
  try {
    dispatch(setStep1(data));
  } catch (error) {
    console.error(error);
  }
};

/**
 * Set merchant info for step 2.
 */
export const fillStep2 = (data: Step2): AppThunk => async (dispatch: Dispatch) => {
  try {
    dispatch(setStep2(data));
  } catch (error) {
    console.error(error);
  }
};

/**
 * Set merchant info for step 3.
 */
export const fillStep3 = (data: Step3): AppThunk => async (dispatch: Dispatch) => {
  try {
    dispatch(setStep3(data));
  } catch (error) {
    console.error(error);
  }
};

/**
 * Set merchant info for step 4.
 */
export const fillStep4 = (data: Step4): AppThunk => async (dispatch: Dispatch) => {
  try {
    dispatch(setStep4(data));
  } catch (error) {
    console.error(error);
  }
};

/**
 * Set merchant info for step 5.
 */
export const fillStep5 = (data: Step5): AppThunk => async (dispatch: Dispatch) => {
  try {
    dispatch(setStep5(data));
  } catch (error) {
    console.error(error);
  }
};

/**
 * Get dashboard statistic.
 */
export const getDashboardStatistic = createAsyncThunk(
  'merchant/getDashboardStatistic',
  ({ params = {} }: { params?: QueryParams | Record<string, unknown> }) => fetchDashboard(params),
);

/**
 * Update merchant.
 */
export const updateMerchant = createAsyncThunk(
  'merchant/updateMerchant',
  ({
    merchantId,
    data,
  }: {
    merchantId: number,
    data: Partial<Organization>,
  }) => updateMerchantOrganization(merchantId, data),
);

/**
 * Update merchant's contacts.
 */
export const updateMerchantContacts = createAsyncThunk(
  'merchant/updateMerchantContacts',
  ({
    merchantId,
    data,
  }: {
      merchantId: number,
      data: User,
    }) => updateMerchantContactDetails(merchantId, data),
);

/**
 * Get all merchant transactions(available for Admin).
 */
export const getAllMerchantTransaction = createAsyncThunk(
  'merchant/getAllMerchantTransaction',
  ({ params = {} }: { params?: QueryParams }) => fetchAllMerchantTransaction(params),
);
