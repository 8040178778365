import React, { memo } from 'react';
import clsx from 'clsx';
import { Chip, ChipProps } from '@material-ui/core';

import styles from './BaseChip.module.css';

interface BaseChipProps extends ChipProps {
  /**
   * Custom dark chip.
   */
  dark?: boolean;
}

const BaseChipComponent = ({ className, dark, ...props }: BaseChipProps): JSX.Element => (
  <Chip {...props} className={clsx(styles.host, className, { [styles.dark]: dark })} />
);

BaseChipComponent.defaultProps = {
  dark: false,
};

export const BaseChip = memo(BaseChipComponent);
