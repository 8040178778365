import React, { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import withSuspense from 'hoc/withSuspense';

const SiteEditorPage = withSuspense(lazy(() => import('./pages/SiteEditorPage/SiteEditorPage')));

export const siteRoutes: RouteObject[] = [
  {
    path: 'site-editor',
    element: <SiteEditorPage />,
  },
];
