import Payment from 'models/Payment';
import PaymentDto from 'api/dtos/paymentDto';
import { PaymentMethod } from 'models/enums';

/**
 * Map DTO to Payment model.
 */
export const mapDtoToPayment = (dto: PaymentDto): Payment => new Payment({
  id: dto.id,
  cardName: dto.card_name,
  zip: dto.zip,
  paymentMethod: dto.payment_method as PaymentMethod,
  last4: dto.last_4,
});

/**
 * Map Payment info model to DTO.
 */
export const mapPaymentToDto = (payload: Payment): PaymentDto => ({
  id: payload.id,
  token: payload.token,
  card_name: payload.cardName,
  zip: payload.zip,
  payment_method: payload.paymentMethod,
  last_4: payload.last4,
});
