import React, { lazy } from 'react';
import { RouteObject, Outlet } from 'react-router-dom';

import withSuspense from 'hoc/withSuspense';

const ManageKiosksPage = withSuspense(lazy(() => import('./pages/ManageKiosksPage/ManageKiosksPage')));
const ManageKiosksAddPage = withSuspense(lazy(() => import('./pages/ManageKiosksAddPage/ManageKiosksAddPage')));

export const kiosksRoutes: RouteObject[] = [
  {
    path: 'manage-kiosks',
    element: <Outlet />,
    children: [
      {
        path: '',
        element: <ManageKiosksPage />,
      },
      {
        path: 'request',
        element: <ManageKiosksAddPage />,
      },
    ],
  },
];
