import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import BrandingColor from 'models/BrandingColor';
import State from 'models/State';
import DonationType from 'models/DonationType';
import { getBrandingColors, getStates, getDonationTypes } from 'store/Site/dispatchers';
import PagedList from 'models/PagedList';

import { state } from './state';

const siteSlice = createSlice({
  name: 'site',
  initialState: state,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getBrandingColors.pending, (state) => {
      state.brandingColors.loading = true;
      state.brandingColors.error = null;
    });
    builder.addCase(getBrandingColors.fulfilled, (state, { payload }: PayloadAction<PagedList<BrandingColor>>) => {
      state.brandingColors.data = payload;
      state.brandingColors.loading = false;
    });
    builder.addCase(getBrandingColors.rejected, (state, { error }) => {
      state.brandingColors.error = error.message ?? '';
      state.brandingColors.loading = false;
    });
    builder.addCase(getStates.pending, (state) => {
      state.states.loading = true;
      state.states.error = null;
    });
    builder.addCase(getStates.fulfilled, (state, { payload }: PayloadAction<PagedList<State>>) => {
      state.states.data = payload;
      state.states.loading = false;
    });
    builder.addCase(getStates.rejected, (state, { error }) => {
      state.states.error = error.message ?? '';
      state.states.loading = false;
    });
    builder.addCase(getDonationTypes.pending, (state) => {
      state.donationTypes.loading = true;
      state.donationTypes.error = null;
    });
    builder.addCase(getDonationTypes.fulfilled, (state, { payload }: PayloadAction<PagedList<DonationType>>) => {
      state.donationTypes.data = payload;
      state.donationTypes.loading = false;
    });
    builder.addCase(getDonationTypes.rejected, (state, { error }) => {
      state.donationTypes.error = error.message ?? '';
      state.donationTypes.loading = false;
    });
  },
});

export const site = siteSlice.reducer;
