import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { uploadFile } from 'store/Upload/dispatchers';
import S3File from 'models/S3File';
import { state } from './state';

const uploadSlice = createSlice({
  name: 'upload',
  initialState: state,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(uploadFile.pending, (state) => {
      state.processing = true;
      state.error = null;
    });
    builder.addCase(uploadFile.fulfilled, (state, { payload }: PayloadAction<S3File>) => {
      state.data = payload;
      state.processing = false;
    });
    builder.addCase(uploadFile.rejected, (state, { error }) => {
      state.error = error.message ?? '';
      state.processing = false;
    });
  },
});

export const upload = uploadSlice.reducer;
