import DonatorDto from 'api/dtos/donatorDto';
import ResponseDto from 'api/dtos/responseDto';
import PagedList from 'models/PagedList';
import Donator from 'models/Donator';
import { mapDtoToPagination } from 'api/mappers/paginationMapper';

/**
 * Map DTO to Donator model.
 */
export const mapDtoToDonator = (dto: DonatorDto): Donator => new Donator({
  id: dto.id,
  amount: dto.amount,
  firstName: dto.first_name,
  lastName: dto.last_name,
  phone: dto.phone,
  email: dto.email,
});

/**
 * Map DTO to Donator paged list.
 */
export function mapDtoToDonators(dtos: ResponseDto<DonatorDto>): PagedList<Donator> {
  return new PagedList<Donator>({
    pagination: mapDtoToPagination(dtos.pagination),
    results: dtos.results?.map(mapDtoToDonator),
  });
}
