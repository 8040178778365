import axios, { AxiosError, AxiosRequestHeaders, InternalAxiosRequestConfig } from 'axios';
import { getSessionToken } from 'api/services/Session';
import getCustomError from 'utils/getCustomError';
import { NetworkError } from 'utils/exceptions';

/**
 * Interceptor to append token to requests.
 */
export const addTokenBeforeRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const token = getSessionToken();

  if (!token) return config;

  const { headers } = config;

  if (!headers) {
    throw new Error(
      'Axios did not pass any header. Please check your request.',
    );
  }

  const castedHeaders = {
    ...headers,
    Authorization: `Bearer ${token}`,
  };

  return {
    ...config,
    headers: castedHeaders as AxiosRequestHeaders,
  };
};

/**
 * Interceptors to check if request was failed.
 */
export const afterFailedRequest = async (error: AxiosError): Promise<never> => {
  const { response } = error;

  if (axios.isCancel(error)) {
    return Promise.reject();
  }

  if (!response) {
    return Promise.reject(
      new NetworkError('API connection error.\nPlease check your internet connection or try again later.'),
    );
  }

  const customError = await getCustomError(response);

  return Promise.reject(customError);
};
