import { createAsyncThunk } from '@reduxjs/toolkit';

import { exportToFile as exportReportToFile, testPlanningCenterConnection as testConnection } from 'api/services/Utils';
import QueryParams from 'models/QueryParams';
import { PlanningCenter } from 'models/Organization';

type Export = {
  readonly queryParams: QueryParams;
  readonly endpointPath: string;
};

/**
 * Export into file.
 */
export const exportToFile = createAsyncThunk(
  'utils/exportToFile',
  ({ queryParams, endpointPath }: Export): Promise<null> => exportReportToFile(queryParams, endpointPath),
);

/**
 * Test planning center connection.
 */
export const testPlanningCenterConnection = createAsyncThunk(
  'utils/testPlanningCenterConnection',
  (data: PlanningCenter): Promise<unknown> => testConnection(data),
);
