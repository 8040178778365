/**
 *  Donator model class.
 */
export default class Donator {
  /**
   * Unique Id.
   */
  public readonly id: number;

  /**
   * Amount.
   */
  public readonly amount: number;

  /**
   * First name.
   */
  public readonly firstName: string;

  /**
   * Last name.
   */
  public readonly lastName: string;

  /**
   * Email.
   */
  public readonly email: string;

  /**
   * Phone.
   */
  public readonly phone: string;

  constructor(data: DonatorConstructorArgs) {
    this.id = data.id;
    this.amount = data.amount;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.email = data.email;
    this.phone = data.phone;
  }

  /**
   * User full name.
   */
  public get fullName(): string {
    return `${this.firstName || ''} ${this.lastName || ''}`.trim();
  }
}

interface DonatorConstructorArgs {
  id: number;
  amount: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}
