import React, { memo, useState } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';

import { AddKioskDialog, AppConfirmationModal, BaseButton } from 'components';
import { ERROR } from 'components/AppSnackbar';
import { KioskStatus, UserRole } from 'models/enums';
import { useAppDispatch, useAppSelector } from 'store/rootReducer';
import { unregisterKiosk } from 'store/Kiosk';
import transformAPIErrors from 'utils/transformAPIErrors';

export const ActionCellComponent = ({ row }: GridRenderCellParams) => {
  const dispatch = useAppDispatch();
  const snackbar = useSnackbar();

  const [isOpenConfirmation, setOpenConfirmation] = useState(false);
  const [isOpenAddKiosk, setOpenAddKiosk] = useState(false);

  const { user } = useAppSelector((state) => state.user);
  const { processing } = useAppSelector((state) => state.kiosk.kiosks);

  const updateConfirmUnregisterModalState = (isOpen: boolean) => () => {
    setOpenConfirmation(isOpen);
  };

  const handleUnregister = async () => {
    try {
      await dispatch(unregisterKiosk(row.id)).unwrap();
    } catch (error) {
      const serverErrors = transformAPIErrors(error as Error);

      snackbar.enqueueSnackbar(serverErrors.message, ERROR);
    }
  };

  const updateAddKioskModalState = (isOpen: boolean) => () => {
    setOpenAddKiosk(isOpen);
  };

  if (
    (row.status === KioskStatus.Active && user?.role === UserRole.Merchant)
      || (row.status === KioskStatus.UnregisteredPending && user?.role === UserRole.Admin)
  ) {
    return (
      <>
        <BaseButton
          variant="outlined"
          onClick={updateConfirmUnregisterModalState(true)}
          danger
        >
          Unregister Kiosk
        </BaseButton>
        <AppConfirmationModal
          open={isOpenConfirmation}
          title="Are you sure?"
          message={`Are you sure you want to unregister this kiosk?
Generosity service will stop running on this kiosk.`}
          processing={processing}
          onClose={updateConfirmUnregisterModalState(false)}
          onConfirm={handleUnregister}
        />
      </>
    );
  }

  if (row.status === KioskStatus.RequestPending && user?.role === UserRole.Admin) {
    return (
      <>
        <BaseButton
          variant="contained"
          color="primary"
          onClick={updateAddKioskModalState(true)}
        >
          Add Kiosk
        </BaseButton>
        <AddKioskDialog open={isOpenAddKiosk} kioskId={row.id} onClose={updateAddKioskModalState(false)} />
      </>
    );
  }

  return null;
};

export const ActionCell = memo(ActionCellComponent);
