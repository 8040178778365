import React, { memo, MouseEvent } from 'react';
import {
  DialogProps, Dialog, DialogContent, DialogTitle, DialogActions,
} from '@material-ui/core';

import { BaseButton } from 'components';

interface AppConfirmationModalProps extends DialogProps {
  /**
   * Confirmation title.
   */
  title: string;
  /**
   * Confirmation message.
   */
  message: string;
  /**
   * State of confirmation process.
   */
  processing?: boolean;
  /**
   * Call a function on confirm.
   */
  onConfirm: (params: any) => void | Promise<unknown>;
}

const AppConfirmationModalComponent = ({
  title, message, open, processing, onClose, onConfirm, ...props
}: AppConfirmationModalProps): JSX.Element => {
  const handleClosePrevent = (event: object, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (onClose) {
      if (reason !== 'backdropClick') {
        onClose(event, reason);
      }
    }
  };

  const handleClose = (event: MouseEvent<HTMLButtonElement>) => {
    // onClose is optional props. Need to pass event and reason 'escapeKeyDown' for closing by Esc.
    if (onClose) {
      onClose(event, 'escapeKeyDown');
    }
  };

  return (
    <Dialog
      {...props}
      open={open}
      aria-labelledby="confirmation-modal-title"
      onClose={(event, reason) => handleClosePrevent(event, reason)}
    >
      <DialogTitle id="confirmation-modal-title">{title}</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <BaseButton onClick={handleClose}>Cancel</BaseButton>
        <BaseButton onClick={onConfirm} color="primary" loading={processing} autoFocus>
          Confirm
        </BaseButton>
      </DialogActions>
    </Dialog>
  );
};

AppConfirmationModalComponent.defaultProps = {
  processing: false,
};

export const AppConfirmationModal = memo(AppConfirmationModalComponent);
