import React from 'react';
import { GridRenderCellParams, GridColumns } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { Tooltip, Box } from '@material-ui/core';

import { RefreshIcon } from 'components';
import { getFormattedDate } from 'utils/date';
import { getFormattedCurrency } from 'utils/currency';
import { PaymentMethod } from 'models/enums';
import { getPaymentMethodText } from 'utils/getPaymentMethodText';
import { getLast4 } from 'utils/getLast4';

const columns: GridColumns = [
  {
    field: 'transactionId',
    headerName: 'Transaction Id',
    minWidth: 200,
    renderCell: ({ id, value }: GridRenderCellParams) => (
      <Link className="noUnderlineLink text-uppercase" to={`/donations/${id}`}>{value}</Link>
    ),
  },
  {
    field: 'createdAt',
    headerName: 'Date',
    renderCell: ({ value }: GridRenderCellParams) => {
      if (value) {
        return getFormattedDate(value as Date);
      }

      return value?.toString();
    },
  },
  {
    field: 'donationType',
    headerName: 'Type',
    minWidth: 80,
    renderCell: (params: GridRenderCellParams) => params.row.donationType.name,
  },
  {
    field: 'donator',
    headerName: 'Donator',
    flex: 1,
    minWidth: 180,
    renderCell: ({ value }: GridRenderCellParams) => value || <>&ndash;</>,
  },
  {
    field: 'name',
    headerName: 'Category',
    flex: 1,
    minWidth: 120,
    renderCell: ({ value }: GridRenderCellParams) => <strong>{value}</strong>,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    minWidth: 140,
    align: 'right',
    headerAlign: 'right',
    renderCell: ({ value }: GridRenderCellParams) => {
      if (value) {
        return (
          <strong>{getFormattedCurrency(Number(value))}</strong>
        );
      }

      return value;
    },
  },
  {
    field: 'comment',
    headerName: 'Note',
    flex: 1,
    minWidth: 120,
  },
  {
    field: 'reoccurringPaymentId',
    headerName: ' ',
    width: 20,
    align: 'center',
    renderCell: ({ value }: GridRenderCellParams) => {
      if (value) {
        return (
          <Tooltip title="Recurring Payment">
            <Box display="flex" alignItems="center">
              <RefreshIcon />
            </Box>
          </Tooltip>
        );
      }

      return null;
    },
  },
  {
    field: 'fee',
    headerName: 'Paid Fee',
    align: 'right',
    headerAlign: 'right',
    renderCell: ({ value }: GridRenderCellParams) => {
      if (value && Number(value) > 0) {
        return getFormattedCurrency(Number(value));
      }

      return <>&ndash;</>;
    },
  },
  {
    field: 'paymentMethod',
    headerName: 'Payment Type',
    minWidth: 170,
    renderCell: ({ value }: GridRenderCellParams) => getPaymentMethodText(value as string),
  },
  {
    field: 'last4',
    headerName: 'Ends In',
    renderCell: ({ value, row }: GridRenderCellParams) => {
      if (row.paymentMethod === PaymentMethod.Card) {
        return getLast4(value as string);
      }

      return <>&ndash;</>;
    },
  },
];

export { columns };
