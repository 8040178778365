import React, { memo, ReactNode } from 'react';

interface BaseTabPanelProps {
  /**
   * Tab value.
   */
  value: number;

  /**
   * Index.
   */
  index: number;

  /**
   * Tab name.
   */
  name: string;

  /**
   * Children.
   */
  children: ReactNode;
}

const BaseTabPanelComponent = ({
  value, index, name, children,
}: BaseTabPanelProps): JSX.Element => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`${name}-tabpanel-${index}`}
    aria-labelledby={`${name}-tab-${index}`}
  >
    {value === index && children}
  </div>
);

export const BaseTabPanel = memo(BaseTabPanelComponent);
