/**
 *  Donation type model class.
 */
export default class DonationType {
  /**
   * Unique Id.
   */
  public readonly id: number | string;

  /**
   * State name.
   */
  public readonly name: string;

  constructor(data: DonationType) {
    this.id = data.id;
    this.name = data.name;
  }
}
