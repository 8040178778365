import PagedList from 'models/PagedList';
import Kiosk from 'models/Kiosk';

interface KioskState {
  kiosks: {
    loading: boolean;
    processing: boolean;
    data: PagedList<Kiosk> | null;
    error: string | null;
    currentRequestId: string | null;
  };
}

export const state: KioskState = {
  kiosks: {
    loading: false,
    processing: false,
    data: null,
    error: null,
    currentRequestId: null,
  },
};
