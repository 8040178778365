import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetchPaymentSettings, fetchPaymentToken, updatePaymentSettings as updatePayment } from 'api/services/Settings';
import Payment from 'models/Payment';

/**
 * Get payment settings.
 */
export const getPaymentSettings = createAsyncThunk(
  'settings/getPaymentSettings',
  () => fetchPaymentSettings(),
);

/**
 * Update payment method.
 */
export const updatePaymentMethod = createAsyncThunk(
  'settings/updatePaymentMethod',
  async (payment: Payment) => {
    const { token } = await fetchPaymentToken(payment);

    const paymentSettings = {
      ...payment,
      token,
    };

    return updatePayment(paymentSettings as Payment);
  },
);
