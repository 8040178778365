import Pagination from 'models/Pagination';
import ResponsePaginationDto from 'api/dtos/responsePaginationDto';

/**
 * Map DTO to Pagination model.
 */
export const mapDtoToPagination = (dto?: ResponsePaginationDto): Pagination => new Pagination({
  count: dto?.count ?? 0,
  currentPage: dto?.current_page ?? 0,
  total: dto?.total ?? 0,
  totalPages: dto?.total_pages ?? 0,
});
