import React, { memo } from 'react';
import { GridOverlay } from '@mui/x-data-grid';
import { LinearProgress } from '@material-ui/core';

import styles from './BaseDataTable.module.css';

const BaseLoadingOverlayComponent = (): JSX.Element => (
  <GridOverlay>
    <LinearProgress className={styles.progressbar} />
  </GridOverlay>
);

export const BaseLoadingOverlay = memo(BaseLoadingOverlayComponent);
