import React, { memo } from 'react';
import { Toolbar, Typography } from '@material-ui/core';
import { GridRowsProp } from '@mui/x-data-grid';

import { BaseButtonLink, ExportButton } from 'components';
import QueryParams from 'models/QueryParams';

import styles from './ReoccurringDonationsToolbar.module.css';

interface ReoccurringDonationsToolbarProps {
  /**
   * Label.
   */
  label: string;

  /**
   * Endpoint path.
   */
  endpointPath: string;

  /**
   * Query params.
   */
  queryParams: QueryParams;

  /**
   * Data rows.
   */
  rows: GridRowsProp;

  /**
   * Show button 'View Donators'.
   */
  showViewDonators?: boolean;
}

const ReoccurringDonationsToolbarComponent = ({
  label, endpointPath, rows, queryParams, showViewDonators,
}: ReoccurringDonationsToolbarProps): JSX.Element => (
  <Toolbar className={styles.host}>
    <div className={styles.leftSide}>
      <Typography variant="h6" className={styles.label}>{label}</Typography>
      {showViewDonators && (
      <BaseButtonLink
        variant="outlined"
        className={styles.button}
        to="/donators"
      >
        View Donators
      </BaseButtonLink>
      )}
    </div>
    <ExportButton
      className={styles.button}
      endpointPath={endpointPath}
      queryParams={queryParams}
      disabled={rows.length === 0}
    />
  </Toolbar>
);

ReoccurringDonationsToolbarComponent.defaultProps = {
  showViewDonators: false,
};

export const ReoccurringDonationsToolbar = memo(ReoccurringDonationsToolbarComponent);
