/**
 *  Charity stats model class.
 */
export default class CharityStat {
  /**
   * Unique Id.
   */
  public readonly id: number;

  /**
   * Name.
   */
  public readonly name: string;

  /**
   * Status.
   */
  public readonly status: string;

  /**
   * Donations.
   */
  public readonly donations: number;

  /**
   * Amount.
   */
  public readonly amount: number;

  constructor(data: CharityStat) {
    this.id = data.id;
    this.name = data.name;
    this.status = data.status;
    this.donations = data.donations;
    this.amount = data.amount;
  }
}
