/**
 *  S3 file model class.
 */
export default class S3File {
  /**
   * File url.
   */
  public readonly fileUrl: string;

  /**
   * Upload Url.
   */
  public readonly uploadUrl: string;

  /**
   * Date until valid.
   */
  public readonly validUntil: Date;

  constructor(data: S3File) {
    this.fileUrl = data.fileUrl;
    this.uploadUrl = data.uploadUrl;
    this.validUntil = data.validUntil;
  }
}
