/**
 *  State model class.
 */
export default class State {
  /**
   * Unique Id.
   */
  public readonly id: number;

  /**
   * State code.
   */
  public readonly code: string;

  /**
   * State name.
   */
  public readonly name: string;

  constructor(data: State) {
    this.id = data.id;
    this.code = data.code;
    this.name = data.name;
  }
}
