import Merchant from 'models/Merchant';
import Step1 from 'models/Step1';
import Step2 from 'models/Step2';
import Step3 from 'models/Step3';
import Step4 from 'models/Step4';
import Step5 from 'models/Step5';
import Dashboard from 'models/Dashboard';
import PagedList from 'models/PagedList';
import User from 'models/User';
import AllTransaction from 'models/AllTransaction';

interface MerchantState {
  active: {
    loading: boolean;
    data: PagedList<Merchant> | null;
    error: string | null;
    currentRequestId: string | null;
  };
  inactive: {
    loading: boolean;
    data: PagedList<Merchant> | null;
    error: string | null;
    currentRequestId: string | null;
  };
  allTransactions: {
    loading: boolean;
    data: PagedList<AllTransaction> | null;
    error: string | null;
    currentRequestId: string | null;
  };
  details: {
    loading: boolean;
    data: User | null;
    error: string | null;
    processing: boolean;
  };
  create: {
    step1: Step1 | null;
    step2: Step2 | null;
    step3: Step3 | null;
    step4: Step4 | null;
    step5: Step5 | null;
  };
  dashboard: {
    loading: boolean;
    data: Dashboard | null;
    error: string | null;
    currentRequestId: string | null;
  };
}

export const state: MerchantState = {
  active: {
    loading: false,
    data: null,
    error: null,
    currentRequestId: null,
  },
  inactive: {
    loading: false,
    data: null,
    error: null,
    currentRequestId: null,
  },
  allTransactions: {
    loading: false,
    data: null,
    error: null,
    currentRequestId: null,
  },
  details: {
    loading: false,
    processing: false,
    data: null,
    error: null,
  },
  create: {
    step1: null,
    step2: null,
    step3: null,
    step4: null,
    step5: null,
  },
  dashboard: {
    loading: false,
    data: null,
    error: null,
    currentRequestId: null,
  },
};
