import React, { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import withSuspense from 'hoc/withSuspense';

const CharitiesPage = withSuspense(lazy(() => import('./pages/CharitiesPage/CharitiesPage')));
const CharityAddPage = withSuspense(lazy(() => import('./pages/CharityAddPage/CharityAddPage')));
const CharityEditPage = withSuspense(lazy(() => import('./pages/CharityEditPage/CharityEditPage')));
const CharityDetailsPage = withSuspense(lazy(() => import('./pages/CharityDetailsPage/CharityDetailsPage')));

export const charitiesRoutes: RouteObject[] = [
  {
    path: 'charities',
    element: <CharitiesPage />,
  },
  {
    path: 'charities',
    children: [
      {
        path: ':id',
        element: <CharityDetailsPage />,
      },
      {
        path: ':id/edit',
        element: <CharityEditPage />,
      },
      {
        path: 'add',
        element: <CharityAddPage />,
      },
    ],
  },
];
