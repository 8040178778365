import React, { memo, useMemo, ReactNode } from 'react';
import { CircularProgress, Typography } from '@material-ui/core';

import styles from './InfoWidget.module.css';

interface InfoWidgetProps {
  /**
   * Svg icon.
   */
  icon: ReactNode;
  /**
   * The required value.
   */
  value?: number | string | null;
  /**
   * The required title.
   */
  title: string;
  /**
   * State of loading data.
   */
  loading?: boolean;
}

const InfoWidgetComponent = ({
  icon, value, title, loading,
}: InfoWidgetProps): JSX.Element => {
  const widgetIcon = useMemo(() => <span className={styles.icon}>{icon}</span>, [icon]);
  const getValue = () => {
    if (value && !loading) {
      return value;
    }
    return <>&ndash;</>;
  };

  return (
    <div className={styles.host}>
      {widgetIcon}
      { loading && <CircularProgress size={64} className={styles.progressbar} />}
      <Typography variant="body2" className={styles.title}>{title}</Typography>
      <Typography variant="h6" className={styles.value}>{getValue()}</Typography>
    </div>
  );
};

InfoWidgetComponent.defaultProps = {
  loading: false,
  value: null,
};

export const InfoWidget = memo(InfoWidgetComponent);
