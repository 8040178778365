import React, { memo } from 'react';
import {
  SnackbarOrigin, SnackbarProvider, SnackbarProviderProps, OptionsObject,
} from 'notistack';
import { withStyles } from '@material-ui/core/styles';

const ANCHOR_ORIGIN: SnackbarOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
};

const styles = {
  variantError: { backgroundColor: 'var(--error-color)' },
  variantSuccess: { backgroundColor: 'var(--secondary-color)' },
};

const AppSnackbarComponent = ({ children, classes }: SnackbarProviderProps): JSX.Element => (
  <SnackbarProvider
    preventDuplicate
    hideIconVariant
    autoHideDuration={7000}
    anchorOrigin={ANCHOR_ORIGIN}
    classes={{
      variantError: classes?.variantError,
      variantSuccess: classes?.variantSuccess,
    }}
  >
    {children}
  </SnackbarProvider>
);

export const AppSnackbar = withStyles(styles)(memo(AppSnackbarComponent));

const ERROR: OptionsObject = { variant: 'error' };
const SUCCESS: OptionsObject = { variant: 'success' };

export { ERROR, SUCCESS };
