/**
 * Get a table label.
 */
export const getTableLabel = (label: string, loading: boolean, total?: number): string => {
  if (loading) {
    return `${label} (–)`;
  }

  return `${label} (${total || '–'})`;
};
