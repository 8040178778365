import React, { Suspense } from 'react';
import { LinearProgress } from '@material-ui/core';

/**
 * HOC wrap a component with progress bar.
 */
const withSuspense = <T extends Record<string, unknown>>(WrappedComponent: React.FunctionComponent<T>) => {
  const WithSuspenseComponent = (props: T) => (
    <Suspense fallback={<LinearProgress />}>
      <WrappedComponent {...props} />
    </Suspense>
  );

  WithSuspenseComponent.displayName = 'SuspenseWithProgress';

  return WithSuspenseComponent;
};

export default withSuspense;
