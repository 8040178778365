import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetchTransactions } from 'api/services/Charity';
import QueryParams from 'models/QueryParams';

/**
 * Get or search paginated list of charity(category) transactions.
 */
export const getTransactions = createAsyncThunk(
  'charity/getTransactions',
  ({ id, params = {} }: { id: number, params?: QueryParams }) => fetchTransactions(id, params),
);
