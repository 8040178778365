import React, { memo } from 'react';
import { Toolbar, Typography } from '@material-ui/core';

import { BaseButtonLink } from 'components';

import styles from './KiosksToolbar.module.css';

interface KiosksToolbarProps {
  /**
   * Label.
   */
  label: string;

  /**
   * Merchant Id.
   */
  merchantId?: number | null;
}

const KiosksToolbarComponent = ({ label, merchantId }: KiosksToolbarProps): JSX.Element => (
  <Toolbar className={styles.host}>
    <Typography variant="h6" className={styles.label}>{label}</Typography>
    { !merchantId && (
      <BaseButtonLink
        variant="outlined"
        className={styles.button}
        to="/manage-kiosks/request"
      >
        Request Kiosk(s)
      </BaseButtonLink>
    ) }
  </Toolbar>
);

KiosksToolbarComponent.defaultProps = {
  merchantId: null,
};

export const KiosksToolbar = memo(KiosksToolbarComponent);
