import DonatorTransaction from 'models/DonatorTransaction';
import DonatorTransactionDto from 'api/dtos/donatorTransactionDto';
import PagedList from 'models/PagedList';
import ResponseDto from 'api/dtos/responseDto';
import { mapDtoToPagination } from 'api/mappers/paginationMapper';

/**
 * Map DTO to Donator Transaction model.
 */
export const mapDtoToDonatorTransaction = (dto: DonatorTransactionDto): DonatorTransaction => new DonatorTransaction({
  id: dto.id,
  amount: dto.amount,
  fee: dto.fee,
  firstName: dto.first_name,
  lastName: dto.last_name,
  name: dto.name,
  receipt: dto.receipt,
  paymentMethod: dto.payment_method,
  donationType: dto.donation_type,
  createdAt: new Date(dto.created_at),
  last4: dto.last_4,
  reoccurringPaymentId: dto.reoccurring_payment_id,
  transactionId: dto.transaction_id,
});

/**
 * Map DTO to Donator Transactions paged list.
 */
export function mapDtoToDonatorTransactions(dtos: ResponseDto<DonatorTransactionDto>): PagedList<DonatorTransaction> {
  return new PagedList<DonatorTransaction>({
    pagination: mapDtoToPagination(dtos.pagination),
    results: dtos.results?.map(mapDtoToDonatorTransaction),
  });
}
