import React, { memo, ReactNode, ChangeEvent } from 'react';
import { useField, useFormikContext } from 'formik';
import {
  SwitchProps, Switch, FormControlLabel, FormControl, FormHelperText,
} from '@material-ui/core';

import styles from './BaseSwitch.module.css';

interface BaseSwitchProps extends SwitchProps {
  /**
   * Unique id.
   */
  id: string;
  /**
   * Field name.
   */
  name: string;
  /**
   * Text label or custom component.
   */
  label: ReactNode;
}

const BaseSwitchComponent = ({
  id, label, name, ...props
}: BaseSwitchProps): JSX.Element => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (setFieldValue) {
      setFieldValue(name, event.target.checked);
    }

    if (props.onChange) {
      props.onChange(event, event.target.checked);
    }
  };

  const configSwitch = {
    ...field,
    ...props,
    onChange: handleChange,
  };

  const configFormControl = {
    error: false,
  };

  let helperText: string | null = null;

  if (meta && meta.touched && meta.error) {
    configFormControl.error = true;
    helperText = meta.error;
  }

  return (
    <FormControl {...configFormControl}>
      <FormControlLabel
        id={id}
        className={styles.label}
        control={(<Switch {...configSwitch} />)}
        label={label}
      />
      <FormHelperText error className={styles.error}>{helperText}</FormHelperText>
    </FormControl>
  );
};

export const BaseSwitch = memo(BaseSwitchComponent);
