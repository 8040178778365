import { KioskStatus } from 'models/enums';

/**
 *  Kiosk model class.
 */
export default class Kiosk {
  /**
   * Unique Id.
   */
  public readonly id: number;

  /**
   * Serial number.
   */
  public readonly serialNumber: string | null;

  /**
   * Status.
   */
  public readonly status: KioskStatus;

  constructor(data: Kiosk) {
    this.id = data.id;
    this.serialNumber = data.serialNumber;
    this.status = data.status;
  }
}
