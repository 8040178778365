import { combineReducers } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux';

import { auth } from './Auth/authSlice';
import { charity } from './Charity/charitySlice';
import { donator } from './Donator/donatorSlice';
import { kiosk } from './Kiosk/kioskSlice';
import { merchant } from './Merchant/merchantSlice';
import { organization } from './Organization/organizationSlice';
import { settings } from './Settings/settingsSlice';
import { site } from './Site/siteSlice';
import { user } from './User/userSlice';
import { upload } from './Upload/uploadSlice';
import { utils } from './Utils/utilsSlice';
import { AppDispatch } from './index';

export type RootState = ReturnType<typeof rootReducer>;

export const rootReducer = combineReducers({
  auth,
  charity,
  donator,
  kiosk,
  merchant,
  organization,
  settings,
  site,
  user,
  upload,
  utils,
});

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();
