import React, { memo } from 'react';
import { Toolbar, Typography } from '@material-ui/core';
import { GridRowsProp } from '@mui/x-data-grid';

import { BaseButtonLink, ExportButton } from 'components';
import QueryParams from 'models/QueryParams';

import styles from './CharityStatsToolbar.module.css';

interface CharityStatsToolbarProps {
  /**
   * Show button 'View Categories'.
   */
  showViewCharities?: boolean;

  /**
   * Label.
   */
  label: string;

  /**
   * Endpoint path.
   */
  endpointPath: string;

  /**
   * Query params.
   */
  queryParams: QueryParams;

  /**
   * Data rows.
   */
  rows: GridRowsProp;
}

const CharityStatsToolbarComponent = ({
  showViewCharities, label, endpointPath, rows, queryParams,
}: CharityStatsToolbarProps): JSX.Element => (
  <Toolbar className={styles.host}>
    <div className={styles.leftSide}>
      <Typography variant="h6" className={styles.label}>{label}</Typography>
      {showViewCharities && (
        <BaseButtonLink
          variant="outlined"
          className={styles.button}
          to="/charities"
        >
          View Categories
        </BaseButtonLink>
      )}
    </div>
    <ExportButton
      className={styles.button}
      endpointPath={endpointPath}
      queryParams={queryParams}
      disabled={rows.length === 0}
    />
  </Toolbar>
);

CharityStatsToolbarComponent.defaultProps = {
  showViewCharities: false,
};

export const CharityStatsToolbar = memo(CharityStatsToolbarComponent);
