import Charity from 'models/Charity';
import CharityDto from 'api/dtos/charityDto';
import PagedList from 'models/PagedList';
import ResponseDto from 'api/dtos/responseDto';
import { mapDtoToPagination } from 'api/mappers/paginationMapper';

/**
 * Map DTO to Charity model.
 */
export const mapDtoToCharity = (dto: CharityDto): Charity => new Charity({
  id: dto.id,
  name: dto.name,
  deletedAt: dto.deleted_at ? new Date(dto.deleted_at) : null,
  status: dto.status,
  sequence: dto.sequence,
  defaultForTackon: dto.default_for_tackon,
  commentTitle: dto.comment_title,
  fixPriceValue: dto.fix_price_value ? dto.fix_price_value?.toString() : null,
  isFixPrice: dto.is_fix_price,
  isCommented: dto.is_commented,
});

/**
 * Map DTO to Charities paged list.
 */
export function mapDtoToCharities(dtos: ResponseDto<CharityDto>): PagedList<Charity> {
  return new PagedList<Charity>({
    pagination: mapDtoToPagination(dtos.pagination),
    results: dtos.results?.map(mapDtoToCharity),
  });
}

/**
 * Map Charity model to DTO.
 */
export const mapCharityToDto = (payload: Charity): CharityDto => ({
  id: payload.id as number,
  name: payload.name,
  deleted_at: payload.deletedAt && payload.deletedAt.toISOString(),
  status: payload.status,
  sequence: payload.sequence,
  comment_title: payload.commentTitle,
  default_for_tackon: payload.defaultForTackon,
  fix_price_value: Number(payload.fixPriceValue),
  is_fix_price: payload.isFixPrice,
  is_commented: payload.isCommented,
});
