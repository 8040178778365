import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  getDonators, getDonator, getTransactions, getTotalAmountTransactions,
} from 'store/Donator';
import Filter from 'models/Filter';
import Donator from 'models/Donator';
import DonatorTotalAmountTransaction from 'models/DonatorTotalAmountTransaction';

import { state } from './state';

const donatorSlice = createSlice({
  name: 'donator',
  initialState: state,
  reducers: {
    setFilter(state, { payload }: PayloadAction<Filter<any> | null>) {
      state.filter = payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getDonators.pending, (state, { meta }) => {
      state.loading = true;
      state.error = null;
      state.currentRequestId = meta.requestId;
    });
    builder.addCase(getDonators.fulfilled, (state, { meta, payload }) => {
      if (state.loading && state.currentRequestId === meta.requestId) {
        state.data = payload;
        state.loading = false;
        state.currentRequestId = null;
      }
    });
    builder.addCase(getDonators.rejected, (state, { error, meta }) => {
      if (state.loading && state.currentRequestId === meta.requestId) {
        state.error = error.message ?? '';
        state.loading = false;
        state.currentRequestId = null;
      }
    });
    builder.addCase(getDonator.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getDonator.fulfilled, (state, { payload }: PayloadAction<Donator>) => {
      state.details = payload;
      state.loading = false;
    });
    builder.addCase(getDonator.rejected, (state, { error }) => {
      state.error = error.message ?? '';
      state.loading = false;
    });
    builder.addCase(getTransactions.pending, (state, { meta }) => {
      state.transactions.loading = true;
      state.transactions.error = null;
      state.transactions.currentRequestId = meta.requestId;
    });
    builder.addCase(getTransactions.fulfilled, (state, { meta, payload }) => {
      if (state.transactions.loading && state.transactions.currentRequestId === meta.requestId) {
        state.transactions.data = payload;
        state.transactions.loading = false;
        state.transactions.currentRequestId = null;
      }
    });
    builder.addCase(getTransactions.rejected, (state, { error, meta }) => {
      if (state.transactions.loading && state.transactions.currentRequestId === meta.requestId) {
        state.transactions.error = error.message ?? '';
        state.transactions.loading = false;
        state.transactions.currentRequestId = null;
      }
    });
    builder.addCase(getTotalAmountTransactions.pending, (state) => {
      state.totalAmount.loading = true;
      state.totalAmount.error = null;
    });
    builder.addCase(
      getTotalAmountTransactions.fulfilled,
      (state, { payload }: PayloadAction<DonatorTotalAmountTransaction>) => {
        state.totalAmount.data = payload.totalAmount;
        state.totalAmount.loading = false;
      },
    );
    builder.addCase(getTotalAmountTransactions.rejected, (state, { error }) => {
      state.totalAmount.error = error.message ?? '';
      state.totalAmount.loading = false;
    });
  },
});

export const { setFilter } = donatorSlice.actions;

export const donator = donatorSlice.reducer;
