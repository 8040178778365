import axios, { Canceler } from 'axios';

import ResponseDto from 'api/dtos/responseDto';
import PagedList from 'models/PagedList';
import QueryParams from 'models/QueryParams';
import Donator from 'models/Donator';
import DonatorTransaction from 'models/DonatorTransaction';
import DonatorTotalAmountTransaction from 'models/DonatorTotalAmountTransaction';
import { mapQueryParamsToDto } from 'api/mappers/queryParamsMapper';
import { mapDtoToDonatorTransactions } from 'api/mappers/donatorTransactionMapper';
import { mapDtoToDonators, mapDtoToDonator } from 'api/mappers/donatorMapper';
import { mapDtoToDonatorTotalAmountTransaction } from 'api/mappers/donatorTotalAmountTransactionMapper';
import DonatorDto from 'api/dtos/donatorDto';
import DonatorTotalAmountTransactionDto from 'api/dtos/donatorTotalAmountTransactionDto';
import DonatorTransactionDto from 'api/dtos/donatorTransactionDto';

import { http } from '../index';

const { CancelToken } = axios;
let cancelRequest: Canceler;
let cancelTotalAmountRequest: Canceler;

/**
 * Fetch donators.
 */
export const fetchDonators = async (params: QueryParams): Promise<PagedList<Donator>> => {
  if (cancelRequest) {
    cancelRequest();
  }

  const dtoParams = mapQueryParamsToDto(params);
  const { data } = await http.get<ResponseDto<DonatorDto>>(
    '/donators',
    {
      params: dtoParams,
      cancelToken: new CancelToken((cancel) => {
        cancelRequest = cancel;
      }),
    },
  );

  return mapDtoToDonators(data);
};

/**
 * Fetch donator by id.
 */
export const fetchDonator = async (id: number): Promise<Donator> => {
  const { data } = await http.get<DonatorDto>(`/donators/${id}`);

  return mapDtoToDonator(data);
};

/**
 * Fetch donator transactions.
 */
export const fetchTransactions = async (id: number, params: QueryParams): Promise<PagedList<DonatorTransaction>> => {
  if (cancelRequest) {
    cancelRequest();
  }

  const dtoParams = mapQueryParamsToDto(params);
  const { data } = await http.get<ResponseDto<DonatorTransactionDto>>(
    `/donators/${id}/transactions`,
    {
      params: dtoParams,
      cancelToken: new CancelToken((cancel) => {
        cancelRequest = cancel;
      }),
    },
  );

  return mapDtoToDonatorTransactions(data);
};

/**
 * Fetch total amount of donator transactions.
 */
export const fetchTotalAmountTransactions = async (
  id: number,
  params: QueryParams,
): Promise<DonatorTotalAmountTransaction> => {
  if (cancelTotalAmountRequest) {
    cancelTotalAmountRequest();
  }

  const dtoParams = mapQueryParamsToDto(params);
  const { data } = await http.get<DonatorTotalAmountTransactionDto>(
    `/donators/${id}/total-amount-transactions`,
    {
      params: dtoParams,
      cancelToken: new CancelToken((cancel) => {
        cancelTotalAmountRequest = cancel;
      }),
    },
  );

  return mapDtoToDonatorTotalAmountTransaction(data);
};
