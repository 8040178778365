import { UserRole } from 'models/enums';

import Organization from './Organization';

/**
 *  User model class.
 */
export default class User {
  /**
   * Unique Id.
   */
  public readonly id: number;

  /**
   * First name.
   */
  public readonly firstName: string;

  /**
   * Last name.
   */
  public readonly lastName: string;

  /**
   * Email.
   */
  public readonly email: string;

  /**
   * Phone number.
   */
  public readonly phone: string | null;

  /**
   * Avatar url.
   */
  public readonly avatarUrl: string | null;

  /**
   * Date of creation(iso).
   */
  public readonly createdAt: Date;

  /**
   * Organization info.
   */
  public readonly organization?: Organization;

  /**
   * User Role.
   */
  public readonly role: UserRole;

  constructor(data: UserConstructorArgs) {
    this.id = data.id;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.email = data.email;
    this.phone = data.phone;
    this.avatarUrl = data.avatarUrl;
    this.createdAt = data.createdAt;
    this.organization = data.organization;
    this.role = data.role;
  }

  /**
   * User full name.
   */
  public get fullName(): string {
    return `${this.firstName || ''} ${this.lastName || ''}`.trim();
  }
}

interface UserConstructorArgs {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string | null;
  avatarUrl: string | null;
  createdAt: Date;
  organization?: Organization;
  role: UserRole;
}
