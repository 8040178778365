/**
 *  Branding color model class.
 */
export default class BrandingColor {
  /**
   * Unique Id.
   */
  public readonly id: number;

  /**
   * Name of color.
   */
  public readonly name: string;

  /**
   * Value of color.
   */
  public readonly color: string;

  constructor(data: BrandingColor) {
    this.id = data.id;
    this.name = data.name;
    this.color = data.color;
  }
}
