import BrandingColorDto from 'api/dtos/brandingColorDto';
import StateDto from 'api/dtos/stateDto';
import DonationTypeDto from 'api/dtos/donationTypeDto';
import BrandingColor from 'models/BrandingColor';
import { mapDtoToBrandingColors } from 'api/mappers/brandingColorMapper';
import { mapDtoToStates } from 'api/mappers/stateMapper';
import { mapDtoToDonationTypes } from 'api/mappers/donationTypeMapper';
import State from 'models/State';
import DonationType from 'models/DonationType';
import ResponseDto from 'api/dtos/responseDto';
import PagedList from 'models/PagedList';

import { http } from '../index';

/**
 * Fetch branding colors.
 */
export const fetchBrandingColors = async (): Promise<PagedList<BrandingColor>> => {
  const { data } = await http.get<ResponseDto<BrandingColorDto>>('/branding-colors');

  return mapDtoToBrandingColors(data);
};

/**
 * Fetch USA states.
 */
export const fetchStates = async (): Promise<PagedList<State>> => {
  const { data } = await http.get<ResponseDto<StateDto>>('/states');

  return mapDtoToStates(data);
};

/**
 * Fetch donation types.
 */
export const fetchDonationTypes = async (): Promise<PagedList<DonationType>> => {
  const { data } = await http.get<ResponseDto<DonationTypeDto>>('/donation-types');

  return mapDtoToDonationTypes(data);
};
