import BrandingColor from 'models/BrandingColor';
import State from 'models/State';
import DonationType from 'models/DonationType';
import PagedList from 'models/PagedList';

interface SiteState {
  brandingColors: {
    loading: boolean;
    data: PagedList<BrandingColor> | null;
    error: string | null;
  };
  states: {
    loading: boolean;
    data: PagedList<State> | null;
    error: string | null;
  };
  donationTypes: {
    loading: boolean;
    data: PagedList<DonationType> | null;
    error: string | null;
  };
}

export const state: SiteState = {
  brandingColors: {
    loading: false,
    data: null,
    error: null,
  },
  states: {
    loading: false,
    data: null,
    error: null,
  },
  donationTypes: {
    loading: false,
    data: null,
    error: null,
  },
};
