import React, { memo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  Container, Divider, List, ListItem, ListItemText,
} from '@material-ui/core';

import {
  AppSidebar, AppStatusBar, AppMerchantMenu, AppAdminMenu, Loading,
} from 'components';
import { UserRole } from 'models/enums';
import { logout } from 'store/Auth';
import { useAppDispatch, useAppSelector } from 'store/rootReducer';

import styles from './MainLayout.module.css';

// eslint-disable-next-line max-len
const SUPPORT_LINK = 'https://salesiq.zoho.com/signaturesupport.ls?widgetcode=b4946139b16193aa4a19d833cde2bba5d971ba19b0ed67955b3c76d1a1faaf7d0ae69b3727a5bf3d7cfc2091397ff840';

const MainLayoutComponent = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { loading: logouting } = useAppSelector((state) => state.auth);
  const { loading, user } = useAppSelector((state) => state.user);

  const handleLogout = async () => {
    await dispatch(logout());

    navigate('/', { replace: true });
  };

  if (loading) {
    return <Loading />;
  }

  const welcomeName = user?.role === UserRole.Merchant ? user?.organization?.name : user?.fullName;
  const mainMenu = user?.role === UserRole.Merchant ? <AppMerchantMenu /> : <AppAdminMenu />;
  const statusBar = user?.role === UserRole.Merchant && <AppStatusBar />;
  const support = user?.role === UserRole.Merchant && (
    <ListItem
      button
      component="a"
      className={styles.navItem}
      href={SUPPORT_LINK}
      target="_blank"
      rel="noreferrer noopener"
    >
      <ListItemText primary="Support" className={styles.link} />
    </ListItem>
  );

  return (
    <main className={styles.host}>
      <AppSidebar name={welcomeName}>
        {statusBar}
        {mainMenu}
        <Divider className={styles.divider} />
        <List disablePadding>
          {support}
          <ListItem button className={styles.navItem} disabled={logouting} onClick={handleLogout}>
            <ListItemText primary="Log Out" className={styles.link} />
          </ListItem>
        </List>
      </AppSidebar>
      <Container className={styles.container} maxWidth="xl">
        <section className={styles.pageContainer}>
          <Outlet />
        </section>
      </Container>
    </main>
  );
};

export default memo(MainLayoutComponent);
