import { StatusCodes } from 'http-status-codes';
import { AxiosResponse } from 'axios';

import { getSessionToken, setSessionToken } from 'api/services/Session';
import { store } from 'store';
import { refreshToken } from 'store/Auth';
import {
  AccessDenied,
  FailedDependency,
  GatewayTimeout,
  InternalServerError,
  ServerError,
  ServerUnavailable,
  ServerValidationError,
} from 'utils/exceptions';
import ApiError from 'models/ApiError';

/**
 * Handle unauthorized errors. Tries to refresh token, otherwise, redirect to the login page.
 */
const handleUnauthorizedError = async (response: AxiosResponse): Promise<ApiError> => {
  const { url, method } = response.config;

  if (url === '/auth' && method === 'delete') {
    window.location.replace('/');
    setSessionToken();

    return Promise.reject();
  }

  const token = getSessionToken();

  if (token) {
    try {
      await store.dispatch(refreshToken(token));

      window.location.reload();
    } catch (error) {
      window.location.replace('/');
      setSessionToken();
    }
  }

  return Promise.reject();
};

/**
 * Get a custom error class from API status code.
 */
export default async (response: AxiosResponse): Promise<ApiError> => {
  switch (response.status) {
    case StatusCodes.UNAUTHORIZED: {
      await handleUnauthorizedError(response);

      return Promise.reject();
    }

    case StatusCodes.BAD_REQUEST: {
      return new ServerValidationError(response.data);
    }

    case StatusCodes.FORBIDDEN: {
      const { url, method } = response.config;

      // Previous re-authentication attempt failed - old token cannot be used to refresh JWT token.
      if (url === '/auth' && method === 'put') {
        window.location.replace('/');
        setSessionToken();

        return Promise.reject(new Error(undefined));
      }

      return new AccessDenied(response);
    }

    case StatusCodes.INTERNAL_SERVER_ERROR: {
      return new InternalServerError(response);
    }

    case StatusCodes.BAD_GATEWAY:

      // no-fallthrough
    case StatusCodes.SERVICE_UNAVAILABLE: {
      return new ServerUnavailable();
    }

    case StatusCodes.GATEWAY_TIMEOUT: {
      return new GatewayTimeout();
    }

    case StatusCodes.FAILED_DEPENDENCY: {
      return new FailedDependency(response);
    }

    default: {
      return new ServerError(response);
    }
  }
};
