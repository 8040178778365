import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  fetchDonators, fetchDonator, fetchTransactions, fetchTotalAmountTransactions,
} from 'api/services/Donator';
import QueryParams from 'models/QueryParams';

/**
 * Get or search paginated list of donators.
 */
export const getDonators = createAsyncThunk(
  'donator/getDonators',
  ({ params = {} }: { params?: QueryParams }) => fetchDonators(params),
);

/**
 * Get transaction by id.
 */
export const getDonator = createAsyncThunk(
  'donator/getDonator',
  (id: number) => fetchDonator(id),
);

/**
 * Get or search paginated list of donator transactions.
 */
export const getTransactions = createAsyncThunk(
  'donator/getTransactions',
  ({ id, params = {} }: { id: number, params?: QueryParams }) => fetchTransactions(id, params),
);

/**
 * Get total amount of donator transactions.
 */
export const getTotalAmountTransactions = createAsyncThunk(
  'donator/getTotalAmountTransactions',
  ({ id, params = {} }: { id: number, params?: QueryParams }) => fetchTotalAmountTransactions(id, params),
);
