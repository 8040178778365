import CharityTransaction from 'models/CharityTransaction';
import CharityTransactionDto from 'api/dtos/charityTransactionDto';
import PagedList from 'models/PagedList';
import ResponseDto from 'api/dtos/responseDto';
import { mapDtoToPagination } from 'api/mappers/paginationMapper';

/**
 * Map DTO to Charity Transaction model.
 */
export const mapDtoToCharityTransaction = (dto: CharityTransactionDto): CharityTransaction => new CharityTransaction({
  id: dto.id,
  amount: dto.amount,
  fee: dto.fee,
  firstName: dto.first_name,
  lastName: dto.last_name,
  donator: dto.donator,
  receipt: dto.receipt,
  paymentMethod: dto.payment_method,
  donationType: dto.donation_type,
  createdAt: new Date(dto.created_at),
  last4: dto.last_4,
  reoccurringPaymentId: dto.reoccurring_payment_id,
  transactionId: dto.transaction_id,
});

/**
 * Map DTO to Charity Transactions paged list.
 */
export function mapDtoToCharityTransactions(dtos: ResponseDto<CharityTransactionDto>): PagedList<CharityTransaction> {
  return new PagedList<CharityTransaction>({
    pagination: mapDtoToPagination(dtos.pagination),
    results: dtos.results?.map(mapDtoToCharityTransaction),
  });
}
