import Merchant from 'models/Merchant';
import MerchantDto from 'api/dtos/merchantDto';
import PagedList from 'models/PagedList';
import ResponseDto from 'api/dtos/responseDto';
import { mapDtoToOrganization } from 'api/mappers/organizationMapper';
import { mapDtoToPagination } from 'api/mappers/paginationMapper';

/**
 * Map DTO to Merchant model.
 */
export const mapDtoToMerchant = (dto: MerchantDto): Merchant => new Merchant({
  id: dto.id,
  lastName: dto.last_name,
  firstName: dto.first_name,
  email: dto.email,
  phone: dto.phone,
  merchantId: dto.merchant_id,
  organization: dto.organization && mapDtoToOrganization(dto.organization),
  organizationId: dto.organization_id,
  approvedOn: new Date(dto.approved_on),
  nextBillingDate: new Date(dto.next_billing_date),
  lastBillingDate: new Date(dto.last_billing_date),
  billingPeriodStart: new Date(dto.billing_period_start),
  billingPeriodEnd: new Date(dto.billing_period_end),
});

/**
 * Map DTO to Merchants paged list.
 */
export function mapDtoToMerchants(dtos: ResponseDto<MerchantDto>): PagedList<Merchant> {
  return new PagedList<Merchant>({
    pagination: mapDtoToPagination(dtos.pagination),
    results: dtos.results?.map(mapDtoToMerchant),
  });
}
