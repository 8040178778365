import React, { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import withSuspense from 'hoc/withSuspense';

const AccountSettingsPage = withSuspense(lazy(() => import('./pages/AccountSettingsPage/AccountSettingsPage')));
const EditOrganizationDetailsPage = withSuspense(lazy(
  () => import('./pages/EditOrganizationDetailsPage/EditOrganizationDetailsPage'),
));
const EditContactDetailsPage = withSuspense(lazy(
  () => import('./pages/EditContactDetailsPage/EditContactDetailsPage'),
));

export const userRoutes: RouteObject[] = [
  {
    path: 'account-settings',
    element: <AccountSettingsPage />,
  },
  {
    path: 'account-settings',
    children: [
      {
        path: 'organization-details',
        element: <EditOrganizationDetailsPage />,
      },
      {
        path: 'contact-details',
        element: <EditContactDetailsPage />,
      },
    ],
  },
];
