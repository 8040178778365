import Organization from './Organization';

/**
 *  Merchant model class.
 */
export default class Merchant {
  /**
   * Unique Id.
   */
  public readonly id: number;

  /**
   * First name.
   */
  public readonly firstName: string;

  /**
   * Last name.
   */
  public readonly lastName: string;

  /**
   * Email.
   */
  public readonly email: string;

  /**
   * Phone number.
   */
  public readonly phone: string;

  /**
   * Merchant id.
   */
  public readonly merchantId: number | null;

  /**
   * Organization info.
   */
  public readonly organization: Organization;

  /**
   * Organization id.
   */
  public readonly organizationId: number;

  /**
   * Date of approved.
   */
  public readonly approvedOn: Date;

  /**
   * Next billing date.
   */
  public readonly nextBillingDate: Date;

  /**
   * Last billing date.
   */
  public readonly lastBillingDate: Date;

  /**
   * Billing period start date.
   */
  public readonly billingPeriodStart: Date;

  /**
   * Billing period end date.
   */
  public readonly billingPeriodEnd: Date;

  constructor(data: Merchant) {
    this.id = data.id;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.email = data.email;
    this.phone = data.phone;
    this.merchantId = data.merchantId;
    this.organization = data.organization;
    this.organizationId = data.organizationId;
    this.approvedOn = data.approvedOn;
    this.nextBillingDate = data.nextBillingDate;
    this.lastBillingDate = data.lastBillingDate;
    this.billingPeriodStart = data.billingPeriodStart;
    this.billingPeriodEnd = data.billingPeriodEnd;
  }
}
