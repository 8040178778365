import { GridSortModel } from '@mui/x-data-grid';

import QueryParams from 'models/QueryParams';

/**
 * Check if changed sort model for data tables.
 */
export const isChangedOrder = (sortModelParams: GridSortModel, queryParams: QueryParams): boolean => {
  if (sortModelParams.length === 0) {
    return false;
  }

  const sortParams = { field: queryParams.orderBy, sort: queryParams.sortOrder };
  // Update sort model only when param was changed.
  return JSON.stringify(sortModelParams) !== JSON.stringify([sortParams]);
};
