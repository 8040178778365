import React, { memo, forwardRef } from 'react';
import {
  NavLink, NavLinkProps, useLocation, useResolvedPath,
} from 'react-router-dom';
import { Location, Path, To } from 'history';

import styles from './AppMenu.module.css';

type TMenuLinkProps = NavLinkProps & {
  /**
   * Active menu class name.
   */
  isActive?: (location: Location, path: Path) => boolean;
  /**
   * URL path.
   */
  to: To;
  /**
   * Element class.
   */
  className: string;
}

const MenuLinkComponent = forwardRef<HTMLAnchorElement, TMenuLinkProps>(({
  to, isActive, className: classNameProp = '', ...rest
}: TMenuLinkProps, ref) => {
  const location = useLocation();
  const path = useResolvedPath(to);

  const locationPathname = location.pathname;
  const toPathname = path.pathname;

  const isLinkActive = isActive ? isActive(location, path) : locationPathname.startsWith(toPathname);

  const className = [classNameProp, isLinkActive ? styles.menuActive : null].filter(Boolean).join(' ');
  return <NavLink {...rest} ref={ref} className={className} to={to} />;
});

MenuLinkComponent.defaultProps = {
  isActive: undefined,
};

export const MenuLink = memo(MenuLinkComponent);
