import React, { memo, useEffect, useState } from 'react';
import { GridSortModel } from '@mui/x-data-grid';

import { BaseDataTable, DonationsToolbar } from 'components';
import { useAppDispatch, useAppSelector } from 'store/rootReducer';
import { getTransactions } from 'store/Organization';
import QueryParams from 'models/QueryParams';
import { SortOrder } from 'models/enums';
import { FilterForm } from 'models/FilterForm';
import { getTableLabel } from 'utils/getTableLabel';
import { isChangedOrder } from 'utils/isChangedOrder';

import { columns } from './tableSettings';

interface DonationsTableProps {
  /**
   * Show button 'View Donations'.
   */
  showViewDonations?: boolean;
  /**
   * Additional query params.
   */
  additionalQueryParams?: Record<string, unknown>;
}

const DonationsTableComponent = ({ showViewDonations, additionalQueryParams }: DonationsTableProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const { data, loading } = useAppSelector((state) => state.organization.transactions);

  const [queryParams, setQueryParams] = useState<QueryParams>({
    ...additionalQueryParams,
    search: '',
    perPage: 10,
    orderBy: 'createdAt',
    sortOrder: SortOrder.Desc,
    page: 1,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'createdAt', sort: SortOrder.Desc },
  ]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    dispatch(getTransactions({ params: queryParams }));
  }, [dispatch, queryParams]);

  const handleSortModelChange = (param: GridSortModel) => {
    setSortModel(param);

    if (!isChangedOrder(param, queryParams)) {
      return;
    }

    const { sort, field } = param[0];

    setQueryParams({
      ...queryParams,
      orderBy: field,
      sortOrder: sort as SortOrder,
    });
  };

  const handlePageChange = (page: number) => {
    setPage(page);
    setQueryParams({
      ...queryParams,
      page: page + 1,
    });
  };

  const handlePageSizeChange = (perPage: number) => {
    setPageSize(perPage);
    setQueryParams({
      ...queryParams,
      perPage,
    });
  };

  const handleSearch = (search: string) => {
    setPage(0);
    setQueryParams({
      ...queryParams,
      search,
      page: 1,
    });
  };

  const handleFilter = (values: FilterForm) => {
    setQueryParams({
      ...queryParams,
      ...values,
    });
  };

  return (
    <BaseDataTable
      rows={data?.results || []}
      loading={loading}
      columns={columns}
      rowCount={data?.pagination.total}
      onSortModelChange={handleSortModelChange}
      onPageChange={handlePageChange}
      onPageSizeChange={handlePageSizeChange}
      components={{
        Toolbar: DonationsToolbar,
      }}
      componentsProps={{
        toolbar: {
          label: getTableLabel('Donations', loading, data?.pagination.total),
          onSearch: handleSearch,
          onFilter: handleFilter,
          endpointPath: 'organizations/transactions',
          queryParams,
          filterParams: additionalQueryParams,
          showViewDonations,
          rows: data?.results || [],
        },
      }}
      pageSize={pageSize}
      page={page}
      sortModel={sortModel}
      autoHeight
      pagination
      zebra
    />
  );
};

DonationsTableComponent.defaultProps = {
  showViewDonations: false,
  additionalQueryParams: {},
};

export const DonationsTable = memo(DonationsTableComponent);
