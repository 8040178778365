import { CONFIG } from 'config';
import BrandingColor from 'models/BrandingColor';
import OrganizationImage from 'models/OrganizationImage';

/**
 *  Organization model class.
 */
export default class Organization {
  /**
   * Unique Id.
   */
  public readonly id?: number;

  /**
   * Organization name.
   */
  public readonly name: string;

  /**
   * Organization address.
   */
  public readonly address: string;

  /**
   * City.
   */
  public readonly city: string;

  /**
   * State.
   */
  public readonly state: string;

  /**
   * ZIP code.
   */
  public readonly zip: string;

  /**
   * Fee.
   */
  public readonly fee: string;

  /**
   * Banner url.
   */
  public readonly banner: OrganizationImage | string | number | null;

  /**
   * Branding color.
   */
  public readonly brandingColor: BrandingColor | number | null;

  /**
   * Logo url.
   */
  public readonly logoUrl: string | null;

  /**
   * Slug.
   */
  public readonly slug: string;

  /**
   * Show logo.
   */
  public readonly showLogo: boolean;

  /**
   * Show donation.
   */
  public readonly showDonation: boolean;

  /**
   * Show fee.
   */
  public readonly showFee: boolean;

  /**
   * Show banner.
   */
  public readonly showBanner: boolean;

  /**
   * Show site.
   */
  public readonly showSite: boolean;

  /**
   * Is disabled.
   */
  public readonly isDisabled: boolean;

  /**
   * Cardpoint merchant id.
   */
  public readonly cardpointeMerchantId: string | null;

  /**
   * Clover merchant id.
   */
  public readonly cloverMerchantId: string | null;

  /**
   * Donation email signature.
   */
  public readonly donationEmailSignature: string | null;

  /**
   * ACH merchant id.
   */
  public readonly achMerchantId: string | null;

  /**
   * Tackon phone.
   */
  public readonly tackonPhone: string | null;

  /**
   * Tackon bearer token.
   */
  public readonly tackonBearerToken: string | null;

  /**
   * Kiosk fee.
   */
  public readonly kioskFee: string;

  /**
   * Planning application id.
   */
  public readonly planningApplicationId: string | null;

  /**
   * Planning secret.
   */
  public readonly planningSecret: string | null;

  /**
   * Planning on.
   */
  public readonly planningOn: boolean;

  constructor(data: OrganizationConstructorArgs) {
    this.id = data.id;
    this.name = data.name;
    this.address = data.address;
    this.city = data.city;
    this.state = data.state;
    this.zip = data.zip;
    this.fee = data.fee;
    this.banner = data.banner;
    this.brandingColor = data.brandingColor;
    this.logoUrl = data.logoUrl;
    this.slug = data.slug;
    this.showLogo = data.showLogo;
    this.showDonation = data.showDonation;
    this.showFee = data.showFee;
    this.showBanner = data.showBanner;
    this.showSite = data.showSite;
    this.isDisabled = data.isDisabled;
    this.cardpointeMerchantId = data.cardpointeMerchantId;
    this.cloverMerchantId = data.cloverMerchantId;
    this.donationEmailSignature = data.donationEmailSignature;
    this.achMerchantId = data.achMerchantId;
    this.kioskFee = data.kioskFee;
    this.tackonPhone = data.tackonPhone;
    this.tackonBearerToken = data.tackonBearerToken;
    this.planningApplicationId = data.planningApplicationId;
    this.planningSecret = data.planningSecret;
    this.planningOn = data.planningOn;
  }

  /**
   * Get charity external url.
   */
  public get charityExternalUrl(): string {
    return `${CONFIG.charityUrl}/${this.slug}`;
  }

  /**
   * Get charity external url text.
   */
  public get charityExternalUrlText(): string {
    const charityUrl = new URL(this.charityExternalUrl);

    return `${charityUrl.host}${charityUrl.pathname}`;
  }
}

interface OrganizationConstructorArgs {
  id?: number;
  name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  fee: string;
  banner: OrganizationImage | string | number | null;
  brandingColor: BrandingColor | number | null;
  logoUrl: string | null;
  slug: string;
  showLogo: boolean;
  showDonation: boolean;
  showFee: boolean;
  showBanner: boolean;
  showSite: boolean;
  isDisabled: boolean;
  cardpointeMerchantId: string | null;
  donationEmailSignature: string | null;
  cloverMerchantId: string | null;
  achMerchantId: string | null;
  kioskFee: string;
  tackonPhone: string | null;
  tackonBearerToken: string | null;
  planningApplicationId: string | null;
  planningSecret: string | null;
  planningOn: boolean;
}

export interface PlanningCenter {
  planningApplicationId: string | null;
  planningSecret: string | null;
}
