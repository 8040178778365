import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getPaymentSettings, updatePaymentMethod } from 'store/Settings/dispatchers';
import Payment from 'models/Payment';
import { state } from './state';

const settingsSlice = createSlice({
  name: 'settings',
  initialState: state,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getPaymentSettings.pending, (state) => {
      state.payments.loading = true;
      state.payments.error = null;
    });
    builder.addCase(getPaymentSettings.fulfilled, (state, { payload }: PayloadAction<Payment>) => {
      state.payments.data = payload;
      state.payments.loading = false;
    });
    builder.addCase(getPaymentSettings.rejected, (state, { error }) => {
      state.payments.error = error.message ?? '';
      state.payments.loading = false;
    });
    builder.addCase(updatePaymentMethod.pending, (state) => {
      state.payments.processing = true;
      state.payments.error = null;
    });
    builder.addCase(updatePaymentMethod.fulfilled, (state, { payload }: PayloadAction<Payment>) => {
      state.payments.data = payload;
      state.payments.processing = false;
    });
    builder.addCase(updatePaymentMethod.rejected, (state, { error }) => {
      state.payments.error = error.message ?? '';
      state.payments.processing = false;
    });
  },
});

export const settings = settingsSlice.reducer;
