import React, { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import withSuspense from 'hoc/withSuspense';

const DonationsPage = withSuspense(lazy(() => import('./pages/DonationsPage/DonationsPage')));
const AddCashTransaction = withSuspense(lazy(() => import('./pages/AddCashTransaction/AddCashTransaction')));
const AddCheckTransaction = withSuspense(lazy(() => import('./pages/AddCheckTransaction/AddCheckTransaction')));
const DonationsDetailsPage = withSuspense(lazy(() => import('./pages/DonationsDetailsPage/DonationsDetailsPage')));
const AdminDonationsPage = withSuspense(lazy(() => import('./pages/AdminDonationsPage/AdminDonationsPage')));

export const donationsMerchantRoutes: RouteObject[] = [
  {
    path: 'donations',
    element: <DonationsPage />,
  },
  {
    path: 'donations',
    children: [
      {
        path: ':id',
        element: <DonationsDetailsPage />,
      },
      {
        path: 'add-check-transaction',
        element: <AddCheckTransaction />,
      },
      {
        path: 'add-cash-transaction',
        element: <AddCashTransaction />,
      },
    ],
  },
];

export const donationsAdminRoutes: RouteObject[] = [
  {
    path: 'donations',
    element: <AdminDonationsPage />,
  },
  {
    path: 'donations',
    children: [
      {
        path: ':id',
        element: <DonationsDetailsPage />,
      },
    ],
  },
];
