import { mapDtoToS3 } from 'api/mappers/s3Mapper';
import S3File from 'models/S3File';
import S3FileDto from 'api/dtos/s3FileDto';

import { http } from '../index';

/**
 * Upload a file to S3.
 */
export const uploadToS3 = async (file: File): Promise<S3File> => {
  const formData = new FormData();

  formData.append('fileName', file.name.toLowerCase());

  const { data } = await http.post<S3FileDto>('/uploads/tmp', formData);

  await fetch(data.uploadUrl, {
    method: 'PUT',
    body: file,
  });

  return mapDtoToS3(data);
};
