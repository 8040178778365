import React, { memo, MouseEvent } from 'react';
import { Formik, Form } from 'formik';
import { useSnackbar } from 'notistack';
import {
  DialogProps, Dialog, DialogContent, Grid, DialogTitle,
} from '@material-ui/core';

import { BaseButton, BaseInput } from 'components';
import { ERROR, SUCCESS } from 'components/AppSnackbar';
import { useAppSelector, useAppDispatch } from 'store/rootReducer';
import { updateKiosk } from 'store/Kiosk';
import Kiosk from 'models/Kiosk';
import transformAPIErrors from 'utils/transformAPIErrors';

import { formValidation, AddKioskFormState } from './formSettings';
import styles from './AddKioskDialog.module.css';

interface AddKioskDialogProps extends DialogProps {
  /**
   * Kiosk id.
   */
  kioskId: number;
}

const INITIAL_FORM_STATE: AddKioskFormState = {
  serialNumber: '',
};

const AddKioskDialogComponent = ({
  kioskId, open, onClose, ...props
}: AddKioskDialogProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const snackbar = useSnackbar();

  const { processing } = useAppSelector((state) => state.kiosk.kiosks);

  const handleClosePrevent = (event: object, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (onClose) {
      if (reason !== 'backdropClick') {
        onClose(event, reason);
      }
    }
  };

  const handleClose = (event: MouseEvent<HTMLButtonElement> | Event) => {
    // onClose is optional props. Need to pass event and reason 'escapeKeyDown' for closing by Esc.
    if (onClose) {
      onClose(event, 'escapeKeyDown');
    }
  };

  const handleSubmit = async (values: AddKioskFormState) => {
    try {
      await dispatch(updateKiosk({
        id: kioskId,
        data: values as Kiosk,
      })).unwrap();

      handleClose(new Event('click'));
      snackbar.enqueueSnackbar('Serial number has been added successfully', SUCCESS);
    } catch (error) {
      const serverErrors = transformAPIErrors(error as Error);

      snackbar.enqueueSnackbar(serverErrors.message, ERROR);
    }
  };

  return (
    <Dialog
      {...props}
      open={open}
      aria-labelledby="alert-dialog-title"
      onClose={(event, reason) => handleClosePrevent(event, reason)}
      disableEnforceFocus
    >
      <DialogTitle id="alert-dialog-title">
        Add Kiosk
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{ ...INITIAL_FORM_STATE }}
          onSubmit={handleSubmit}
          validationSchema={formValidation}
          validateOnBlur={false}
        >
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <BaseInput
                  label="Kiosk Serial Number"
                  id="serialNumber"
                  name="serialNumber"
                  placeholder="Type in Kiosk Serial Number"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <div className={styles.buttons}>
                  <BaseButton className={styles.button} onClick={handleClose}>Cancel</BaseButton>
                  <BaseButton
                    className={styles.button}
                    color="primary"
                    type="submit"
                    loading={processing}
                    variant="contained"
                  >
                    Add
                  </BaseButton>
                </div>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export const AddKioskDialog = memo(AddKioskDialogComponent);
