/**
 * Payment status types.
 */
export enum PaymentStatus {
  Pending = 'Pending',
  Processed = 'Processed',
  Refunded = 'Refunded',
  Deleted = 'Deleted',
  Voided = 'Voided',
}
