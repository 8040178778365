import OrganizationImage from 'models/OrganizationImage';
import OrganizationImagesDto from 'api/dtos/organizationImagesDto';
import PagedList from 'models/PagedList';
import ResponseDto from 'api/dtos/responseDto';
import { mapDtoToPagination } from 'api/mappers/paginationMapper';

/**
 * Map DTO to OrganizationImages model.
 */
export const mapDtoToOrganizationImage = (dto: OrganizationImagesDto): OrganizationImage => new OrganizationImage({
  id: dto.id,
  filePath: dto.file_path,
  userId: dto.user_id,
  organizationId: dto.organization_id,
});

/**
 * Map DTO to OrganizationImages paged list.
 */
export function mapDtoToOrganizationImages(dtos: ResponseDto<OrganizationImagesDto>): PagedList<OrganizationImage> {
  return new PagedList<OrganizationImage>({
    pagination: mapDtoToPagination(dtos.pagination),
    results: dtos.results?.map(mapDtoToOrganizationImage),
  });
}
