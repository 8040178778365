import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

/* eslint-disable max-len */
export const CopyIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props} viewBox="0 0 12 14">
    <g id="Element-Sheet" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-85.000000, -390.000000)" fill="#F7F7F7" id="Icons/Material/copy/fill">
        <g transform="translate(85.166667, 390.583392)">
          <path d="M8.16666667,0 L1.16666667,0 C0.522083333,0 0,0.522083333 0,1.16666667 L0,9.33333333 L1.16666667,9.33333333 L1.16666667,1.16666667 L8.16666667,1.16666667 L8.16666667,0 L8.16666667,0 Z M9.91666667,2.33333333 L3.5,2.33333333 C2.85541667,2.33333333 2.33333333,2.856 2.33333333,3.5 L2.33333333,11.6666667 C2.33333333,12.3106667 2.85541667,12.8333333 3.5,12.8333333 L9.91666667,12.8333333 C10.5606667,12.8333333 11.0833333,12.3106667 11.0833333,11.6666667 L11.0833333,3.5 C11.0833333,2.856 10.5606667,2.33333333 9.91666667,2.33333333 L9.91666667,2.33333333 Z M3.5,3.5 L9.91666667,3.5 L9.91666667,11.6666667 L3.5,11.6666667 L3.5,3.5 Z" id="Shape" />
        </g>
      </g>
    </g>
  </SvgIcon>
);
