/**
 *  Pagination model class.
 */
export default class Pagination {
  /**
   * Count.
   */
  public readonly count: number;

  /**
   * Current page.
   */
  public readonly currentPage: number;

  /**
   * Total.
   */
  public readonly total: number;

  /**
   * Total pages.
   */
  public readonly totalPages: number;

  constructor(data: Pagination) {
    this.count = data.count;
    this.currentPage = data.currentPage;
    this.total = data.total;
    this.totalPages = data.totalPages;
  }
}
