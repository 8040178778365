import axios, { Canceler } from 'axios';

import ResponseDto from 'api/dtos/responseDto';
import MerchantDto from 'api/dtos/merchantDto';
import DashboardDto from 'api/dtos/dashboardDto';
import UserDto from 'api/dtos/userDto';
import AllTransactionDto from 'api/dtos/allTransactionDto';
import { OrganizationDto } from 'api/dtos/organizationDto';
import { mapDtoToMerchants } from 'api/mappers/merchantMapper';
import { mapDtoToDashboard } from 'api/mappers/dashboardMapper';
import { mapQueryParamsToDto } from 'api/mappers/queryParamsMapper';
import { mapDtoToUser, mapUserToDto } from 'api/mappers/userMapper';
import { mapDtoToAllTransactions } from 'api/mappers/allTransactionMapper';
import { mapDtoToOrganization, mapOrganizationToDto } from 'api/mappers/organizationMapper';
import Merchant from 'models/Merchant';
import PagedList from 'models/PagedList';
import Dashboard from 'models/Dashboard';
import QueryParams from 'models/QueryParams';
import { MerchantStatus } from 'models/enums';
import User from 'models/User';
import Organization from 'models/Organization';
import AllTransaction from 'models/AllTransaction';

import { http } from '../index';

const { CancelToken } = axios;
let cancelDashboardRequest: Canceler;
let cancelActiveMerchantsRequest: Canceler;
let cancelInactiveMerchantsRequest: Canceler;
let cancelAllMerchantTransactionRequest: Canceler;

/**
 * Fetch active merchants(available for Admin).
 */
export const fetchActiveMerchants = async (params: QueryParams): Promise<PagedList<Merchant>> => {
  if (cancelActiveMerchantsRequest) {
    cancelActiveMerchantsRequest();
  }

  const dtoParams = mapQueryParamsToDto(params);
  const { data } = await http.get<ResponseDto<MerchantDto>>('/merchants', {
    params: { ...dtoParams, status: MerchantStatus.Active },
    cancelToken: new CancelToken((cancel) => {
      cancelActiveMerchantsRequest = cancel;
    }),
  });

  return mapDtoToMerchants(data);
};

/**
 * Fetch inactive merchants(available for Admin).
 */
export const fetchInactiveMerchants = async (params: QueryParams): Promise<PagedList<Merchant>> => {
  if (cancelInactiveMerchantsRequest) {
    cancelInactiveMerchantsRequest();
  }

  const dtoParams = mapQueryParamsToDto(params);
  const { data } = await http.get<ResponseDto<MerchantDto>>('/merchants', {
    params: { ...dtoParams, status: MerchantStatus.Inactive },
    cancelToken: new CancelToken((cancel) => {
      cancelInactiveMerchantsRequest = cancel;
    }),
  });

  return mapDtoToMerchants(data);
};

/**
 * Fetch dashboard statistic.
 */
export const fetchDashboard = async (params: QueryParams): Promise<Dashboard> => {
  if (cancelDashboardRequest) {
    cancelDashboardRequest();
  }

  const dtoParams = mapQueryParamsToDto(params);
  const { data } = await http.get<DashboardDto>(
    '/merchants/dashboard',
    {
      params: dtoParams,
      cancelToken: new CancelToken((cancel) => {
        cancelDashboardRequest = cancel;
      }),
    },
  );

  return mapDtoToDashboard(data);
};

/**
 * Fetch merchant by id.
 */
export const fetchMerchant = async (params: Record<string, unknown>): Promise<User> => {
  const dtoParams = mapQueryParamsToDto(params);
  const { data } = await http.get<UserDto>('/users/me', { params: dtoParams });

  return mapDtoToUser(data);
};

/**
 * Update merchant's info.
 */
export const updateMerchant = async (merchantId: number, payload: Partial<Organization>): Promise<Organization> => {
  const dto = mapOrganizationToDto(payload as Organization);
  const dtoParams = mapQueryParamsToDto({ merchantId });
  const { data } = await http.put<OrganizationDto>('/organizations', { ...dto, ...dtoParams });

  return mapDtoToOrganization(data);
};

/**
 * Update merchant's contact info.
 */
export const updateMerchantContacts = async (merchantId: number, payload: User): Promise<User> => {
  const dto = mapUserToDto(payload);
  const params = {
    merchant_id: merchantId,
  };
  const { data } = await http.put<UserDto>('/users/me', dto, { params });

  return mapDtoToUser(data);
};

/**
 * Fetch all merchant transactions(available for Admin).
 */
export const fetchAllMerchantTransaction = async (params: QueryParams): Promise<PagedList<AllTransaction>> => {
  if (cancelAllMerchantTransactionRequest) {
    cancelAllMerchantTransactionRequest();
  }

  const dtoParams = mapQueryParamsToDto(params);
  const { data } = await http.get<ResponseDto<AllTransactionDto>>('/get-all-transactions', {
    params: dtoParams,
    cancelToken: new CancelToken((cancel) => {
      cancelAllMerchantTransactionRequest = cancel;
    }),
  });

  return mapDtoToAllTransactions(data);
};
