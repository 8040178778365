import axios, { AxiosInstance } from 'axios';

import { CONFIG } from 'config';

export const http: AxiosInstance = axios.create({
  baseURL: CONFIG.apiUrl,
});

type AddRequestInterceptor = typeof http.interceptors.request.use;

/**
 * Adds interceptors for request. For more info check Axios doc.
 * @link https://axios-http.com/docs/interceptors
 */
export const addRequestInterceptor: AddRequestInterceptor = (...rest) => http.interceptors.request.use(...rest);

type AddResponseInterceptor = typeof http.interceptors.response.use;

/**
 * Adds interceptors for response. For more info check Axios doc.
 */
export const addResponseInterceptor: AddResponseInterceptor = (...rest) => http.interceptors.response.use(...rest);
