import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

interface TotalDonationsIconProps extends SvgIconProps {
  /**
   * Svg icon.
   */
  fill?: string;
}

/* eslint-disable max-len */
export const TotalDonationsIcon = ({ fill, ...props }: TotalDonationsIconProps): JSX.Element => (
  <SvgIcon {...props} viewBox="0 0 56 56">
    <g id="Element-Sheet" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-40.000000, -528.000000)" id="Icons/Stats/Total-Donation">
        <g transform="translate(40.000000, 528.000000)">
          <circle id="Oval" fill={fill} cx="28" cy="28" r="28" />
          <path d="M28,12 C36.836556,12 44,19.163444 44,28 C44,36.836556 36.836556,44 28,44 C19.163444,44 12,36.836556 12,28 C12,19.163444 19.163444,12 28,12 Z M29.2500258,19 L26.7500155,19 L26.7500155,21.4615323 C24.6828069,21.4615323 23,23.1184389 23,25.1538308 C23,27.1892226 24.6828069,28.8461292 26.7500155,28.8461292 L29.2500258,28.8461292 C29.9375486,28.8461292 30.5000309,29.3999543 30.5000309,30.0768954 C30.5000309,30.7538365 29.9375486,31.3076616 29.2500258,31.3076616 L24.2500052,31.3076616 L24.2500052,33.7691939 L26.7500155,33.7691939 L26.7500155,36.2307262 L29.2500258,36.2307262 L29.2500258,33.7691939 C31.3172343,33.7691939 33.0000412,32.1122872 33.0000412,30.0768954 C33.0000412,28.0415036 31.3172343,26.3845969 29.2500258,26.3845969 L26.7500155,26.3845969 C26.0624926,26.3845969 25.5000103,25.8307719 25.5000103,25.1538308 C25.5000103,24.4768897 26.0624926,23.9230646 26.7500155,23.9230646 L31.7500361,23.9230646 L31.7500361,21.4615323 L29.2500258,21.4615323 L29.2500258,19 Z" id="Icons/Stats/Total-Donation-Shape" fill="#FFFFFF" />
        </g>
      </g>
    </g>
  </SvgIcon>
);

TotalDonationsIcon.defaultProps = {
  fill: '#2083A5',
};
