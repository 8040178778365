import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import Step1 from 'models/Step1';
import Step2 from 'models/Step2';
import Step3 from 'models/Step3';
import Step4 from 'models/Step4';
import Step5 from 'models/Step5';
import {
  getDashboardStatistic,
  getActiveMerchants,
  getInactiveMerchants,
  getMerchant,
  getAllMerchantTransaction,
  updateMerchant,
  updateMerchantContacts,
} from 'store/Merchant/dispatchers';
import Merchant from 'models/Merchant';
import PagedList from 'models/PagedList';
import User from 'models/User';
import Organization from 'models/Organization';
import AllTransaction from 'models/AllTransaction';

import { state } from './state';

const merchantSlice = createSlice({
  name: 'merchant',
  initialState: state,
  reducers: {
    setStep1(state, { payload }: PayloadAction<Step1>) {
      state.create.step1 = payload;
    },
    setStep2(state, { payload }: PayloadAction<Step2>) {
      state.create.step2 = payload;
    },
    setStep3(state, { payload }: PayloadAction<Step3>) {
      state.create.step3 = payload;
    },
    setStep4(state, { payload }: PayloadAction<Step4>) {
      state.create.step4 = payload;
    },
    setStep5(state, { payload }: PayloadAction<Step5>) {
      state.create.step5 = payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getDashboardStatistic.pending, (state, { meta }) => {
      state.dashboard.loading = true;
      state.dashboard.error = null;
      state.dashboard.currentRequestId = meta.requestId;
    });
    builder.addCase(getDashboardStatistic.fulfilled, (state, { payload, meta }) => {
      if (state.dashboard.loading && state.dashboard.currentRequestId === meta.requestId) {
        state.dashboard.data = payload;
        state.dashboard.loading = false;
        state.dashboard.currentRequestId = null;
      }
    });
    builder.addCase(getDashboardStatistic.rejected, (state, { error, meta }) => {
      if (state.dashboard.loading && state.dashboard.currentRequestId === meta.requestId) {
        state.dashboard.error = error.message ?? '';
        state.dashboard.loading = false;
        state.dashboard.currentRequestId = null;
      }
    });
    builder.addCase(getActiveMerchants.pending, (state, { meta }) => {
      state.active.loading = true;
      state.active.error = null;
      state.active.currentRequestId = meta.requestId;
    });
    builder.addCase(getActiveMerchants.fulfilled, (state, { payload, meta }) => {
      if (state.active.loading && state.active.currentRequestId === meta.requestId) {
        state.active.data = payload;
        state.active.loading = false;
        state.active.currentRequestId = null;
      }
    });
    builder.addCase(getActiveMerchants.rejected, (state, { error, meta }) => {
      if (state.active.loading && state.active.currentRequestId === meta.requestId) {
        state.active.error = error.message ?? '';
        state.active.loading = false;
        state.active.currentRequestId = null;
      }
    });
    builder.addCase(getInactiveMerchants.pending, (state, { meta }) => {
      state.inactive.loading = true;
      state.inactive.error = null;
      state.inactive.currentRequestId = meta.requestId;
    });
    builder.addCase(getInactiveMerchants.fulfilled, (state, { payload, meta }) => {
      if (state.inactive.loading && state.inactive.currentRequestId === meta.requestId) {
        state.inactive.data = payload as PagedList<Merchant>;
        state.inactive.loading = false;
        state.inactive.currentRequestId = null;
      }
    });
    builder.addCase(getInactiveMerchants.rejected, (state, { error, meta }) => {
      if (state.inactive.loading && state.inactive.currentRequestId === meta.requestId) {
        state.inactive.error = error.message ?? '';
        state.inactive.loading = false;
        state.inactive.currentRequestId = null;
      }
    });
    builder.addCase(getAllMerchantTransaction.pending, (state, { meta }) => {
      state.allTransactions.loading = true;
      state.allTransactions.error = null;
      state.allTransactions.currentRequestId = meta.requestId;
    });
    builder.addCase(getAllMerchantTransaction.fulfilled, (state, { payload, meta }) => {
      if (state.allTransactions.loading && state.allTransactions.currentRequestId === meta.requestId) {
        state.allTransactions.data = payload as PagedList<AllTransaction>;
        state.allTransactions.loading = false;
        state.allTransactions.currentRequestId = null;
      }
    });
    builder.addCase(getAllMerchantTransaction.rejected, (state, { error, meta }) => {
      if (state.allTransactions.loading && state.allTransactions.currentRequestId === meta.requestId) {
        state.allTransactions.error = error.message ?? '';
        state.allTransactions.loading = false;
        state.allTransactions.currentRequestId = null;
      }
    });
    builder.addCase(getMerchant.pending, (state) => {
      state.details.loading = true;
      state.details.error = null;
    });
    builder.addCase(getMerchant.fulfilled, (state, { payload }: PayloadAction<User>) => {
      state.details.data = payload;
      state.details.loading = false;
    });
    builder.addCase(getMerchant.rejected, (state, { error }) => {
      state.details.error = error.message ?? '';
      state.details.loading = false;
    });
    builder.addCase(updateMerchant.pending, (state) => {
      state.details.processing = true;
      state.details.error = null;
    });
    builder.addCase(updateMerchant.fulfilled, (state, { payload }: PayloadAction<Organization>) => {
      state.details.data!.organization = payload;
      state.details.processing = false;
    });
    builder.addCase(updateMerchant.rejected, (state, { error }) => {
      state.details.error = error.message ?? '';
      state.details.processing = false;
    });
    builder.addCase(updateMerchantContacts.pending, (state) => {
      state.details.processing = true;
      state.details.error = null;
    });
    builder.addCase(updateMerchantContacts.fulfilled, (state, { payload }: PayloadAction<User>) => {
      state.details.data = payload;
      state.details.processing = false;
    });
    builder.addCase(updateMerchantContacts.rejected, (state, { error }) => {
      state.details.error = error.message ?? '';
      state.details.processing = false;
    });
  },
});

export const {
  setStep1,
  setStep2,
  setStep3,
  setStep4,
  setStep5,
} = merchantSlice.actions;

export const merchant = merchantSlice.reducer;
