import S3File from 'models/S3File';
import S3FileDto from 'api/dtos/s3FileDto';

/**
 * Map DTO to S3 model.
 */
export const mapDtoToS3 = (dto: S3FileDto): S3File => new S3File({
  fileUrl: dto.fileUrl,
  uploadUrl: dto.uploadUrl,
  validUntil: dto.validUntil,
});
