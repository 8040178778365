import { Dispatch } from '@reduxjs/toolkit';

import {
  createSession, deleteSession, updateSession, resetPassword as reset, confirmResetPassword as confirmReset,
} from 'api/services/Auth';
import { AuthWithRememberMe, ConfirmResetPassword } from 'models/Auth';
import { resetUser } from 'store/User/userSlice';
import { ResponseResetPasswordDto } from 'api/dtos/authDto';

import { AppThunk } from '../index';
import {
  setAuthStart,
  setAuthSuccess,
  setAuthFailed,
  setLogoutStart,
  setLogoutSuccess,
  setLogoutFailed,
  setResetPasswordStart,
  setResetPasswordSuccess,
  setResetPasswordFailed,
} from './authSlice';

/**
 * Login.
 */
export const login = (data: AuthWithRememberMe): AppThunk => async (dispatch: Dispatch) => {
  try {
    dispatch(setAuthStart());

    const response = await createSession(data, data.rememberMe);

    dispatch(setAuthSuccess(response));
  } catch (error) {
    dispatch(setAuthFailed(error as Error));

    throw error;
  }
};

/**
 * Refresh token.
 */
export const refreshToken = (token: string): AppThunk => async (dispatch: Dispatch) => {
  try {
    dispatch(setAuthStart());

    const newToken = await updateSession(token);

    dispatch(setAuthSuccess(newToken));
  } catch (error) {
    dispatch(setAuthFailed(error as Error));

    throw error;
  }
};

/**
 * Logout.
 */
export const logout = (): AppThunk => async (dispatch: Dispatch) => {
  try {
    dispatch(setLogoutStart());
    dispatch(resetUser());

    await deleteSession();

    dispatch(setLogoutSuccess());
  } catch (error) {
    dispatch(setLogoutFailed(error as Error));

    throw error;
  }
};

/**
 * Reset password.
 */
export const resetPassword = (email: string): AppThunk<string> => async (dispatch: Dispatch) => {
  try {
    dispatch(setResetPasswordStart());

    const { data } = await reset(email);

    dispatch(setResetPasswordSuccess());

    return data.message;
  } catch (error) {
    dispatch(setResetPasswordFailed(error as Error));

    throw error;
  }
};

/**
 * Confirm reset password.
 */
export const confirmResetPassword = (data: ConfirmResetPassword): AppThunk<string> => async (dispatch: Dispatch) => {
  try {
    dispatch(setResetPasswordStart());

    const response = await confirmReset(data);

    dispatch(setResetPasswordSuccess());

    return (response.data as ResponseResetPasswordDto).message;
  } catch (error) {
    dispatch(setResetPasswordFailed(error as Error));

    throw error;
  }
};
