import React, { memo, useEffect, useState } from 'react';
import { GridSortModel } from '@mui/x-data-grid';

import { BaseDataTable, CharityStatsToolbar } from 'components';
import { useAppDispatch, useAppSelector } from 'store/rootReducer';
import { getCharityStats } from 'store/Organization';
import QueryParams from 'models/QueryParams';
import { SortOrder } from 'models/enums';
import { getTableLabel } from 'utils/getTableLabel';
import { isChangedOrder } from 'utils/isChangedOrder';

import { columns } from './tableSettings';

interface CharityStatsQueryParams extends QueryParams {
  readonly showArchived: boolean;
}

interface CharityStatsTableProps {
  /**
   * Show button 'View Categories'.
   */
  showViewCharities?: boolean;
  /**
   * Additional query params.
   */
  additionalQueryParams?: Record<string, unknown>;
}

const CharityStatsTableComponent = ({
  showViewCharities,
  additionalQueryParams,
}: CharityStatsTableProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const { data, loading } = useAppSelector((state) => state.organization.charityStats);

  const [queryParams, setQueryParams] = useState<CharityStatsQueryParams>({
    ...additionalQueryParams,
    search: '',
    perPage: 10,
    orderBy: 'name',
    sortOrder: SortOrder.Asc,
    page: 1,
    showArchived: true,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'name', sort: SortOrder.Asc },
  ]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    dispatch(getCharityStats({ params: queryParams }));
  }, [dispatch, queryParams]);

  const handleSortModelChange = (param: GridSortModel) => {
    setSortModel(param);

    if (!isChangedOrder(param, queryParams)) {
      return;
    }

    const { sort, field } = param[0];

    setQueryParams({
      ...queryParams,
      orderBy: field,
      sortOrder: sort as SortOrder,
    });
  };

  const handlePageChange = (page: number) => {
    setPage(page);
    setQueryParams({
      ...queryParams,
      page: page + 1,
    });
  };

  const handlePageSizeChange = (perPage: number) => {
    setPageSize(perPage);
    setQueryParams({
      ...queryParams,
      perPage,
    });
  };

  return (
    <BaseDataTable
      rows={data?.results || []}
      loading={loading}
      columns={columns}
      rowCount={data?.pagination.total}
      rowsPerPageOptions={[10]}
      onSortModelChange={handleSortModelChange}
      onPageChange={handlePageChange}
      onPageSizeChange={handlePageSizeChange}
      components={{
        Toolbar: CharityStatsToolbar,
      }}
      componentsProps={{
        toolbar: {
          label: getTableLabel('Giving Category Stats', loading, data?.pagination.total),
          endpointPath: 'organizations/charity/stats',
          queryParams,
          showViewCharities,
          rows: data?.results || [],
        },
      }}
      pageSize={pageSize}
      page={page}
      sortModel={sortModel}
      autoHeight
      pagination
      zebra
    />
  );
};

CharityStatsTableComponent.defaultProps = {
  showViewCharities: false,
  additionalQueryParams: {},
};

export const CharityStatsTable = memo(CharityStatsTableComponent);
