import { AxiosResponse } from 'axios';

import { AuthDto, LoginDto } from 'api/dtos/authDto';
import {
  setUserEmail, setSessionToken, removeSession, removeEmail,
} from 'api/services/Session';
import {
  mapDtoToAuth,
  mapAuthToDto,
  mapConfirmResetPasswordToDto,
} from 'api/mappers/authMapper';
import { ConfirmResetPassword, Token } from 'models/Auth';
import { http } from '../index';

/**
 * Login.
 */
export const createSession = async (payload: AuthDto, rememberMe: boolean): Promise<LoginDto> => {
  const dto = mapAuthToDto(payload);
  const { data } = await http.post<LoginDto>('/auth/admin', dto);

  setSessionToken(data.token);
  setUserEmail(payload.email, rememberMe);

  return mapDtoToAuth(data);
};

/**
 * Refresh token.
 */
export const updateSession = async (token: string): Promise<Token> => {
  const { data } = await http.put<LoginDto>('/auth', token);

  setSessionToken(data.token);

  return data;
};

/**
 * Logout.
 */
export const deleteSession = async (): Promise<unknown> => {
  const result = await http.delete('/auth');

  removeSession();
  removeEmail();

  return result;
};

/**
 * Reset password.
 */
export const resetPassword = (email: string): Promise<AxiosResponse> => http.post('/auth/password/reset', { email });

/**
 * Confirm reset password.
 */
export const confirmResetPassword = (payload: ConfirmResetPassword): Promise<AxiosResponse> => {
  const dto = mapConfirmResetPasswordToDto(payload);

  return http.put('/auth/password/reset', dto);
};
