/**
 *  Organization image model class.
 */
export default class OrganizationImage {
  /**
   * Unique Id.
   */
  public readonly id: number;

  /**
   * File Url.
   */
  public readonly filePath: string;

  /**
   * User's id.
   */
  public readonly userId: number;

  /**
   * organization's id.
   */
  public readonly organizationId: number;

  constructor(data: OrganizationImage) {
    this.id = data.id;
    this.filePath = data.filePath;
    this.userId = data.userId;
    this.organizationId = data.organizationId;
  }
}
